import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IFeedstock } from "../../interfaces/IFeedstock";
import { IFuelGas } from "../../interfaces/IFuelGas";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { RootState } from "../../reducers/rootReducer";
import { addFeedstock, loadAllFeedStockData } from "../../slices/feedstockSlice";
import { addFuelGas, loadAllFuelGasData } from "../../slices/fuelGasSlice";
import { addScenarioRunlength, loadAllScenarioRunlengthData, setCurrentRunLengthId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";
import { getDefaultConvectionSectionData } from "../../utilities/loadConvectionSection";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { addConvectionSection, loadAllConvectionSectionData } from "../../slices/convectionSectionSlice";
import LoadAutocomplete from "../common/LoadAutocomplete";
import { getFeedstockById } from "../../services/feedstockServices";
import { getFuelGasById } from "../../services/fuelgasServices";
import { getConvectionSectionById } from "../../services/convectionsectionServices";
import { MODE, SCENARIO_STATUS } from "../../interfaces/IScenario";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface ILoadScenarioRunlengthProps {
}
interface OIDCAuth {
    auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        scenarioRunlengths: state.scenarioSimulator.loadedScenarioRunlengths,
        scenarioRunlengthsData: state.scenarioSimulator.scenarioRunlengths,
        feedstocks: state.feedstock.loadedFeedstocks,
        feedstocksData: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.loadedFuelGases,
        fuelGasesData: state.fuelGas.fuelGases,
        convectionSections: state.convectionsection.loadedConvectionSections,
        convectionSectionsData: state.convectionsection.convectionsections,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addFeedstock: (feedstock: IFeedstock) => dispatch(addFeedstock(feedstock)),
        addFuelGas: (fuelGas: IFuelGas) => dispatch(addFuelGas(fuelGas)),
        addConvectionSection: (ConvectionSection: IConvectionSection) => dispatch(addConvectionSection(ConvectionSection)),
        addScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(addScenarioRunlength(scenarioRunlength)),
        setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
        setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId)),
        loadAllFeedStockData: (data: any) => dispatch(loadAllFeedStockData(data)),
        loadAllScenarioRunlengthData: (data: any) => dispatch(loadAllScenarioRunlengthData(data)),
        loadAllFuelGasData: (data: any) => dispatch(loadAllFuelGasData(data)),
        loadAllConvectionSectionData: (data: any) => dispatch(loadAllConvectionSectionData(data))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ILoadScenarioRunlengthReduxProps = PropsFromRedux & ILoadScenarioRunlengthProps & OIDCAuth;

type ILoadScenarioRunlengthState = {
    showDialog: boolean;
    runlength: any;
    selected: any[];
    allScenarioRunlengthData: any[];
    isAllSelected: boolean;
    showLoading: boolean;
}
class LoadScenarioRunlength extends Component<ILoadScenarioRunlengthReduxProps, ILoadScenarioRunlengthState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            runlength: this.props.scenarioRunlengths !== undefined && this.props.scenarioRunlengths.length > 0 ? this.props.scenarioRunlengths[0].id : "",
            selected: [],
            allScenarioRunlengthData: [],
            isAllSelected: false,
            showLoading: false
        }
    }

    async componentDidMount() {
        await this.loadScenarioRunlengthDataInit();
    }

    async loadScenarioRunlengthDataInit() {
        this.setState({
            showLoading: true
        })
        let scenarioRunlengthsCopy = JSON.parse(JSON.stringify(this.props.scenarioRunlengths))
        if (!!scenarioRunlengthsCopy && scenarioRunlengthsCopy.length > 0) {
            scenarioRunlengthsCopy.forEach((scenarioRunlengthValue: IScenarioRunlength) => {
                scenarioRunlengthValue['is_saved'] = true;
                scenarioRunlengthValue['is_display_leave_popup'] = false;
            })
        }
        if (this.props.scenarioRunlengths.length > 0) {
            // TO BE USED IN FUTURE
            // let setAllFeedStockDataNew = [...setAll, ...setAllFeedStockData.data]
            let setAllscenarioRunlengthDataNew = [...scenarioRunlengthsCopy]
            this.setState({
                showLoading: false,
                allScenarioRunlengthData: setAllscenarioRunlengthDataNew
            })
        } else {
            this.setState({
                showLoading: false
            })
        }
    }

    toggleShowDialog = async () => {
        this.setState({
            showDialog: !this.state.showDialog,
            runlength: this.props.scenarioRunlengths !== undefined && this.props.scenarioRunlengths.length > 0 ? this.props.scenarioRunlengths[0].id : ""
        })

        if (this.state.showDialog === false) {
            await this.loadScenarioRunlengthDataInit();
        }
    }

    handleSelection(event) {
        this.setState({
            runlength: event.target.value
        })
    }

    loadAllConnectedScenarioData = async (selectedArray) => {
        const accessToken = getToken(this.props.auth);
        selectedArray.forEach(async (selectedValue) => {
            // get feedstock 
            let new_feedstock = selectedValue.scenarios[0].mode ? (selectedValue.scenarios[0].mode === MODE.HYBRID ?
                await this.props.feedstocks.find(feedstock => feedstock.id === selectedValue.scenarios[0].firebox[0].feedstock_id) as IFeedstock
                : await this.props.feedstocks.find(feedstock => feedstock.id === selectedValue.scenarios[0].firebox[0].feedstock_id) as IFeedstock)
                : await this.props.feedstocks.find(feedstock => feedstock.id === selectedValue.scenarios[0].feedstock_id) as IFeedstock;
            new_feedstock = JSON.parse(JSON.stringify(new_feedstock));
            if (new_feedstock === undefined) {
                return;
            }

            let checkIsFeedstockAvailable = await this.props.feedstocksData.some(feedstock => feedstock.id === new_feedstock.id);
            if (!checkIsFeedstockAvailable) {
                let getFeedStockFromDb = await getFeedstockById(new_feedstock.id, accessToken)
                if (getFeedStockFromDb.status) {
                    getFeedStockFromDb.data.forEach((feedStockValue: IFeedstock) => {
                        feedStockValue['is_saved'] = true;
                        feedStockValue['is_display_leave_popup'] = false;
                        if (feedStockValue['signature'] !== new_feedstock.signature) {
                            // load scenario and anything changed in feedStock
                            selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_CHANGED_IN_FEEDSTOCK }] }
                        }

                        if (feedStockValue['is_delete'] === true) {
                            // when Feed stock deleted and load scenario
                            selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG }] }
                            feedStockValue['is_delete'] = false;
                        }
                    })

                    let checkIsFeedstockAvailableAgain = await this.props.feedstocksData.some(feedstock => feedstock.id === new_feedstock.id);
                    if (!checkIsFeedstockAvailableAgain) {
                        await this.props.loadAllFeedStockData(getFeedStockFromDb.data)
                    }
                } else {
                    let feedstockdata = [new_feedstock];
                    await this.props.loadAllFeedStockData(feedstockdata)
                }
            } else {
                let getFeedStockFromDb = await getFeedstockById(new_feedstock.id, accessToken)
                if (getFeedStockFromDb.status) {
                    if (getFeedStockFromDb.data[0]['signature'] !== new_feedstock.signature) {
                        // load scenario and anything changed in feedStock
                        selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_CHANGED_IN_FEEDSTOCK }] }
                    }
                    // if (getFeedStockFromDb.data[0]['is_delete'] === true) {   // TO BE USED IN FUTURE
                    //     selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG }] }
                    // }
                }
            }

            // get fuelgas
            let new_fuelGas = await this.props.fuelGases.find(fuelGas => fuelGas.id === selectedValue.scenarios[0].fuelGas_id) as IFuelGas;
            new_fuelGas = JSON.parse(JSON.stringify(new_fuelGas));
            if (new_fuelGas === undefined) {
                return;
            }

            if (!new_fuelGas.id.startsWith("_DEFAULT")) {
                let checkIsfuelGasAvailable = await this.props.fuelGasesData.some(fuelGas => fuelGas.id === new_fuelGas.id);
                if (!checkIsfuelGasAvailable) {
                    let getFuelgasFromDb = await getFuelGasById(new_fuelGas.id, accessToken)
                    if (getFuelgasFromDb.status) {
                        getFuelgasFromDb.data.forEach((fuelGasValue: IFuelGas) => {
                            fuelGasValue['is_saved'] = true;
                            fuelGasValue['is_display_leave_popup'] = false;
                            if (fuelGasValue['signature'] !== new_fuelGas.signature) {
                                // load scenario and anything changed in fuelgas
                                selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_CHANGED_IN_FUELGAS }] }
                            }
                            if (fuelGasValue['is_delete'] === true) {
                                // when fuelGas deleted and load scenario
                                selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG }] }
                                fuelGasValue['is_delete'] = false;
                            }
                        })

                        let checkIsfuelGasAvailableAgain = await this.props.fuelGasesData.some(fuelGas => fuelGas.id === new_fuelGas.id);
                        if (!checkIsfuelGasAvailableAgain) {
                            await this.props.loadAllFuelGasData(getFuelgasFromDb.data)
                        }
                    } else {
                        let fuelgasdata = [new_fuelGas];
                        await this.props.loadAllFuelGasData(fuelgasdata)
                    }
                } else {
                    let getFuelgasFromDb = await getFuelGasById(new_fuelGas.id, accessToken)
                    if (getFuelgasFromDb.status) {
                        if (getFuelgasFromDb.data[0]['signature'] !== new_fuelGas.signature) {
                            // load scenario and anything changed in fuelgas
                            selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_CHANGED_IN_FUELGAS }] }
                        }
                        // if (getFuelgasFromDb.data[0]['is_delete'] === true) { // TO BE USED IN FUTURE
                        //     selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG }] }
                        // }
                    }
                }

            }

            if (new_fuelGas.id.startsWith("_DEFAULT")) {
                let checkIsfuelGasAvailable = await this.props.fuelGasesData.some(fuelGas => fuelGas.id === new_fuelGas.id);
                if (!checkIsfuelGasAvailable) {
                    let fuelgasdata = [new_fuelGas];
                    await this.props.loadAllFuelGasData(fuelgasdata)
                }
            }

            // get convection section
            if (selectedValue.scenarios[0].convectionsection_id) {
                let new_convectionSection = await this.props.convectionSections.find(cs => cs.id === selectedValue.scenarios[0].convectionsection_id) as IConvectionSection;
                if (new_convectionSection === undefined) {
                    return;
                }

                new_convectionSection = JSON.parse(JSON.stringify(new_convectionSection));
                if (new_convectionSection !== undefined) {
                    // Load default convection section data on demand
                    getDefaultConvectionSectionData(new_convectionSection.asset_name, accessToken);


                    let checkIsConvectionSectionAvailable = await this.props.convectionSectionsData.some(convectionsections => convectionsections.id === new_convectionSection.id);
                    if (!checkIsConvectionSectionAvailable) {
                        let getConvectionSectionFromDb = await getConvectionSectionById(new_convectionSection.id, accessToken)
                        if (getConvectionSectionFromDb.status) {
                            getConvectionSectionFromDb.data.forEach((convectionSectionValue: IConvectionSection) => {
                                convectionSectionValue['is_saved'] = true;
                                convectionSectionValue['is_display_leave_popup'] = false;
                                if (convectionSectionValue['signature'] !== new_fuelGas.signature) {
                                    // load scenario and anything changed in convectionSection
                                    selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_CHANGED_IN_CONVECTION_SECTION }] }
                                }
                                if (convectionSectionValue['is_delete'] === true) {
                                    // when convectionSection deleted and load scenario
                                    selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION }] }
                                    convectionSectionValue['is_delete'] = false;
                                }
                            })

                            let checkIsConvectionSectionAvailableAgain = await this.props.convectionSectionsData.some(convectionsections => convectionsections.id === new_convectionSection.id);
                            if (!checkIsConvectionSectionAvailableAgain) {
                                await this.props.loadAllConvectionSectionData(getConvectionSectionFromDb.data)
                            }

                        } else {
                            let convectionSectiondata = [new_convectionSection];
                            await this.props.loadAllConvectionSectionData(convectionSectiondata)
                        }
                    } else {
                        let getConvectionSectionFromDb = await getConvectionSectionById(new_convectionSection.id, accessToken)
                        if (getConvectionSectionFromDb.status) {
                            if (getConvectionSectionFromDb.data[0]['signature'] !== new_convectionSection.signature) {
                                // load scenario and anything changed in convectionSection
                                selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_CHANGED_IN_CONVECTION_SECTION }] }
                            }
                            // if (getConvectionSectionFromDb.data[0]['is_delete'] === true) { // TO BE USED IN FUTURE
                            //     selectedValue = { ...selectedValue, scenarios: [{ ...selectedValue.scenarios[0], status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION }] }
                            // }
                        }
                    }

                }
            }

            await this.props.setCurrentScenarioRunLengthId(selectedValue.id);
            await this.props.setCurrentRunLengthId(0);

            await this.props.loadAllScenarioRunlengthData([selectedValue]);

        });
        // await this.props.loadAllScenarioRunlengthData(selectedArray);  // TO BE USED IN FUTURE
    }

    loadScenarioRunlength = async () => {
        let isAllSelected = this.state.selected.some(el => el.name.toLowerCase() === "all");
        if (isAllSelected) {
            let removeAllFromArray = this.state.allScenarioRunlengthData.filter(scenarioRunlength => scenarioRunlength.name.toLowerCase() !== "all");
            let filterData = removeAllFromArray.filter(scenarioRunlength => {
                return this.props.scenarioRunlengthsData.filter(el => {
                    return el.id === scenarioRunlength.id;
                }).length === 0
            });
            this.loadAllConnectedScenarioData(filterData)
            this.setState({
                selected: []
            });
        } else {
            this.loadAllConnectedScenarioData(this.state.selected);
            this.setState({
                selected: []
            });
        }


        this.toggleShowDialog();
    }

    selectedDataSet = async (valueData) => {
        await this.setState({
            selected: valueData
        })
    }

    render() {

        return (
            <>
                <LoadAutocomplete
                    showDialog={this.state.showDialog}
                    selected={this.state.selected}
                    allData={this.state.allScenarioRunlengthData}
                    isAllSelected={this.state.isAllSelected}
                    showLoading={this.state.showLoading}
                    componentName={'scenario runlength'}
                    disableData={this.props.scenarioRunlengthsData}
                    toggleShowDialog={this.toggleShowDialog}
                    loadFeedstock={this.loadScenarioRunlength}
                    selectedDataSet={this.selectedDataSet}

                ></LoadAutocomplete>
            </>
        );
    }

};

export default withAuth(connector(LoadScenarioRunlength));