import { v4 as uuidv4 } from 'uuid';
import store from "..";
import { cosy_preperties, IConvectionSection } from "../interfaces/IConvectionSection";
import { addDefaultConvectionSectionData, updateDefaultConvectionSectionData } from "../slices/convectionSectionSlice";
import { REACT_APP_APIM_URL_METADATA } from "./GlobalConstants";

/**
 * Preapre convection section data
 * @param cs_name  convection section name
 * @param furnace_id furnace id
 * @param asset_name asset name
 * @returns Convection section
 */
export const prepareConvectionSection = async (cs_name, furnace_id, asset_name, token) => {

    // Get default convection section data based on asset name
    let defaultdata = await getDefaultConvectionSectionData(asset_name, token);

    // If default convection section data is already loaded then get it from redux store
    if (defaultdata === undefined) {
        defaultdata = store.getState().convectionsection.defaultConvectionSectionDatas.find(f => f.asset_name === asset_name);
    }

    // Get cgMode and dataDes values based on asset name
    // const cgmodedatades = getcgModedataDes(asset_name);

    // Prepare convection section object
    const newConvectionSection: IConvectionSection = {
        id: uuidv4(),
        name: cs_name,
        furnace_id: furnace_id,
        asset_name: asset_name,
        //cgMode: cgmodedatades[0],
        //dataDes: cgmodedatades[1],
        components: [defaultdata],
        signature: "",
        is_public: true,
        is_display_leave_popup: false
    };

    return newConvectionSection;
}

/**
 * Add default convection section data on demand
 * @param asset_name asset name
 * @returns Default convection section data
 */
export const getDefaultConvectionSectionData = async (asset_name, token: string) => {

    let defaultConvectionSectionDatas = store.getState().convectionsection.defaultConvectionSectionDatas;

    if (defaultConvectionSectionDatas.length === 0) {
        // Add first default convection section data
        let defaultdata = await fetchConvectionSectionData(asset_name, token);
        store.dispatch(addDefaultConvectionSectionData([defaultdata]));
        return defaultdata;
    }
    else {
        // Check if already fetched default convection section data or not
        // if not then add the same
        if (defaultConvectionSectionDatas.findIndex(f => f.asset_name === asset_name) === -1) {
            let defaultdata = await fetchConvectionSectionData(asset_name, token);
            store.dispatch(updateDefaultConvectionSectionData([defaultdata]));
            return defaultdata;
        }
    }
};

/**
 * Call api to get convection section data based on asset name
 * @param assetname asset name
 * @returns convection section data
 */
export const fetchConvectionSectionData = async (assetname, token: string) => {

    const cosy_dict = {};
    const furnace_type_id = store.getState().scenarioSimulator.current_furnace_type_id;
    const feed_type_id = store.getState().scenarioSimulator.current_feed_type_id;

    return await fetch(`${REACT_APP_APIM_URL_METADATA}/asset/lvfcosydata/${furnace_type_id}/${feed_type_id}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                cosy_dict["asset_name"] = assetname;

                cosy_preperties.forEach(item => {
                    cosy_dict[item] = data[item];
                    if (item === "COSY_FD_PRESSURE") {
                        cosy_dict[item]["design"] = Number(cosy_dict[item]["design"]).toFixed(3)
                    }
                });

                return cosy_dict;
            },
            (error) => console.log("error", error)
        );
}

/**
 * Get cgMode and dataDes for convection section based on asset name
 * @param asset_name asset name
 * @returns array contains cgMode and dataDes
 */
export const getcgModedataDes = (asset_name) => {
    let furnaces = store.getState().furnace.furnaces;
    let idx: number = furnaces.findIndex((f) => asset_name === f.id);
    let cgMode: string = "";
    let dataDes: string = "";
    if (furnaces[idx]["Cracking Mode"] === "C3C4") {
        cgMode = "c3p_data";
        dataDes = "c3p_des";
    } else {
        cgMode = "c3m_data";
        dataDes = "c3m_des";
    }
    return [cgMode, dataDes];
};