import { Component } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IFeedstock } from "../../interfaces/IFeedstock";
import { IFuelGas } from "../../interfaces/IFuelGas";
import { IScenario, MODE } from "../../interfaces/IScenario";
import { ROWCOLType } from '../../interfaces/IImportExport';
import { RootState } from "../../reducers/rootReducer";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { addScenarioRunlength, addScenarioToRunlength, setCurrentRunLengthId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { addConvectionSection, deleteConvectionSection } from "../../slices/convectionSectionSlice";
import { deleteScenarioRunlength } from "../../slices/scenarioSimulatorSlice";
import { addFeedstock, setCurrentFeedstockId, deleteFeedstock } from "../../slices/feedstockSlice";
import { addFuelGas, deleteFuelGas } from "../../slices/fuelGasSlice";
import { REACT_APP_APIM_URL_METADATA } from "../../utilities/GlobalConstants";
import { xlsExporter } from "./exportResult";

import { getFurnaceReferenceName } from "../../configuration";
import { compiledFlowHC, convertWTToMolFrac, getToken } from "../../utilities/helperFunctions";
import { AuthContextProps, withAuth } from "react-oidc-context";


interface IExportSessionProps {
    exportData: any,
}

interface OIDCAuth {
    auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
    return {
        currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId,
        currentRunLengthId: state.scenarioSimulator.currentRunLengthId,
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        currentFeedstockId: state.feedstock.current_feedstock_id,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
        //loadedScenarioRunlengths: state.scenarioSimulator.loadedScenarioRunlengths,
        //loadedFeedstocks: state.feedstock.loadedFeedstocks,
        //loadedFuelGases: state.fuelGas.loadedFuelGases,
        crackedGas: state.crackedGas,
        furnaces: state.furnace.furnaces,
        feedstocks: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.fuelGases,
        convectionSections: state.convectionsection.convectionsections,
        token: state.authState.token,

        yieldPredictions: state.productPredictor.productPredictorScenarios



    };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
    return {
        resetSession: () => {

            dispatch({ type: "RESET_COIL_STATE" });
            dispatch({ type: "RESET_ERRORS_STATE" });
            dispatch({ type: "RESET_SCENARIOS_STATE" });
            dispatch({ type: "RESET_TERMINATION_STATE" });
        }

        ,
        addScenarioToRunlength: (runlength_id: string, scenario: IScenario) => dispatch(addScenarioToRunlength({ runlength_id, scenario })),
        addFeedstock: (feedstock: IFeedstock) => dispatch(addFeedstock(feedstock)),
        addFuelGas: (fuelGas: IFuelGas) => dispatch(addFuelGas(fuelGas)),
        addConvectionSection: (ConvectionSection: IConvectionSection) => dispatch(addConvectionSection(ConvectionSection)),
        addScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(addScenarioRunlength(scenarioRunlength)),
        setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
        setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId)),
        setCurrentFeedstockId: (newFeedstockId: string) => dispatch(setCurrentFeedstockId(newFeedstockId)),
        deleteScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(deleteScenarioRunlength(scenarioRunlength)),
        deleteFeedstock: (feedstock_id: string) => dispatch(deleteFeedstock(feedstock_id)),
        deleteFuelGas: (fuelGas_id: string) => dispatch(deleteFuelGas(fuelGas_id)),
        deleteConvectionSection: (convectiosection_id: string) => dispatch(deleteConvectionSection(convectiosection_id)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IExportSessionReduxProps = PropsFromRedux & IExportSessionProps & OIDCAuth;

enum DropListEnum { Config = 0, Results };

type IExportSessionState = {
    showDialog: boolean
    allowExport: boolean;
    exportData: any;
    fileName: string;
    selection: DropListEnum;
    error: boolean;
    message: string;
}



// type CGType = (prevObj: any, x: any) => {
//     idx: number[];
//     name: string[];
//     mw: number[];
// };





class ExportSession extends Component<IExportSessionReduxProps, IExportSessionState>{
    exportDropList: string[] = ["Only Configuration (.json)", "Results (.xlsx)"];
    fInfo: any = {};

    enableScenarioExport: any;
    enableYieldPredExport: any;
    constructor(props: IExportSessionReduxProps | Readonly<IExportSessionReduxProps>) {
        super(props);

        this.enableScenarioExport = this.props.exportData.enableScenarioExport;
        this.enableYieldPredExport = this.props.exportData.enableYieldPredExport;
        this.state = {
            showDialog: false,
            allowExport: false,
            exportData: { ...this.props.exportData },
            fileName: "lvf-session",
            selection: DropListEnum.Results,
            error: false,
            message: ""
        }


        this.props.furnaces.forEach(f => this.fInfo[f.Furnace_type_id.toString()] = f);


    }

    componentDidUpdate(PrevProps) {
        if (PrevProps.scenarioRunlengths !== this.props.scenarioRunlengths) {
            this.setState({

                exportData: { ...this.props.exportData, ...{ isScenariosExportable: this.enableScenarioExport(this.props) } },
            })
        }
    }

    fetchCG_MW_Short = async () => {
        // setLoading(true);
        try {
            const accessToken = getToken(this.props.auth);
            const compFetch = await fetch(`${REACT_APP_APIM_URL_METADATA}/components/CGSHORT`, {
                method: "GET",
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
            });
            return await compFetch.json();
        } catch (error) {
            console.warn(error);
        }
    }

    fetchCG_MW_Long = async () => {
        // TO DO 
    };

    updateCGObj: any = (prevObj, newItems) => {
        let cg: any;
        let key = Object.keys(newItems)[0];
        let values: string[] = newItems[key];

        cg = prevObj.map((prevItm, i) => {

            return { ...prevItm, ...{ [key]: values[i] !== undefined && values[i] !== '-' && values[i] !== ' ' && values[i] !== '  ' ? parseFloat(values[i].toString()).toFixed(3).toString() : (values[i] === '-' ? '-' : '') } }

        });
        return cg;
    }

    extractCGFraction = async (runlength, CG_MW, short = true) => {
        let MW = await CG_MW;
        let MW_data = MW?.map((itm) => itm.MW).filter((mw) => mw !== undefined);
        let scenario = runlength.scenarios[0];
        let SPECIES: any;
        var ANA2 = new Array<{ [x: string]: string[] }>();
        let CG: Promise<any[]>;

        if (scenario.firebox[0]["CALCULATIONS"] && scenario.firebox[0]["CALCULATIONS"].length > 0) {


            scenario.firebox[0]["CALCULATIONS"].forEach((iter, i) => {
                if (!iter) return;
                const calc = iter["output_dict"];
                if ("ERROR_CODE" in calc) return;
                
                let ANA2_Frac = short 
                ? 
                    this.props.crackedGas.molPerc === "Flow rates (kg/h)" 
                    ?
                        (calc.SHORT_FRACTION[0].map((val) => 
                            (Number(val.toFixed(3)) * 0.01 * Number(scenario.firebox[0].FLOW_HC))?.toFixed(3)
                        )) 
                    : 
                        this.props.crackedGas.molPerc === "MOL %" 
                        ? 
                            convertWTToMolFrac(calc.SHORT_FRACTION[0], MW_data)
                        : 
                            calc.SHORT_FRACTION[0] 
                : 
                this.props.crackedGas.molPerc === "Flow rates (kg/h)" 
                ?
                    ((calc.ANA2_FRACTION[0].map((val) => 
                    (Number(val.toFixed(3)) * 0.01 * Number(scenario.firebox[0].FLOW_HC))?.toFixed(3)))) 
                :
                    this.props.crackedGas.molPerc === "MOL %" 
                    ? 
                        convertWTToMolFrac(calc.ANA2_FRACTION[0], MW_data)
                    : 
                        calc.ANA2_FRACTION[0];
                // calc.ANA2_FRACTION[0];
                ANA2.push({ [calc.RUNLENGTH[0] + ' [d]']: ANA2_Frac })


                SPECIES = short ? calc.SHORT_SPECIES[0] : calc.ANA2_FRACTION_COMP[0];
                if (scenario.mode !== MODE.HYBRID && i + 1 === scenario.firebox[0]["CALCULATIONS"].length) {
                    ANA2.push({ [' ']: [' '] })
                }

            })
            if (scenario.mode === MODE.HYBRID) {
                const calc1 = scenario?.firebox[0]["CALCULATIONS"][this.props.currentRunLengthId]["output_dict"]
                const calc2 = scenario?.firebox[1]["CALCULATIONS"][this.props.currentRunLengthId]["output_dict"]
                const f1: number = Number(scenario.firebox[0].FLOW_HC);
                const f2: number = Number(scenario.firebox[1].FLOW_HC);
                const massFrac = scenario.firebox[this.props.currentScenarioFireboxId]["CALCULATIONS"][this.props.currentRunLengthId]["output_dict"].SHORT_FRACTION[0] 
                const massANA2 = scenario.firebox[this.props.currentScenarioFireboxId]["CALCULATIONS"][this.props.currentRunLengthId]["output_dict"].ANA2_FRACTION[0]
                
                const ANACombined = calc1.SHORT_FRACTION[0].map((fraction, i) =>
                        Number(((f1 * fraction) + (f2 * calc2.SHORT_FRACTION[0][i])) / (f1 + f2)))
                    
          
                const ANA2_LONG_Combined = calc1.ANA2_FRACTION[0].map((fraction, i) =>
                        Number((f1 * fraction + f2 * calc2.ANA2_FRACTION[0][i])) / (f1 + f2))
                     
                scenario.firebox[1]["CALCULATIONS"].forEach((iter, i) => {
                    if (!iter) return;
                    const calc = iter["output_dict"];
                    if ("ERROR_CODE" in calc) return;

                    let ANA2_Frac = short 
                    ? 
                        this.props.crackedGas.molPerc === "Flow rates (kg/h)"
                        ?
                            ANACombined.map((val) =>
                            ((Number(val?.toFixed(3)) * 0.01 * Number(f1)
                            + (Number(val?.toFixed(3)) * 0.01 * Number(f2)
                            )?.toFixed(3))))
                        :
                        this.props.crackedGas.molPerc === "MOL %" 
                        ?  
                            convertWTToMolFrac(massFrac, MW_data)
                        :
                            ANACombined 
                    : 
                        this.props.crackedGas.molPerc === "Flow rates (kg/h)"
                        ?
                        ANA2_LONG_Combined.map((val) => 
                        (
                            (Number(val?.toFixed(3)) * 0.01 * f1)
                            + (Number(val?.toFixed(3)) * 0.01 * f2)
                          )?.toFixed(3))
                        :
                        this.props.crackedGas.molPerc === "MOL %" 
                        ?  
                        convertWTToMolFrac(massANA2, MW_data)
                        :
                        ANA2_LONG_Combined
                    // calc.ANA2_FRACTION[0]
                    ANA2.push({ [' ']: ['-'] })
                    ANA2.push({ [calc.RUNLENGTH[0] + ' [d] ']: ANA2_Frac })
                    if (i + 1 === scenario.firebox[1]["CALCULATIONS"].length) {
                        ANA2.push({ ['  ']: [' '] })
                    }

                    SPECIES = short ? calc.SHORT_SPECIES[0] : calc.ANA2_FRACTION_COMP[0];


                })
            }

            CG = CG_MW
                .then(x =>
                    x?.map((itm, i) => {
                        return { ...{ cg: SPECIES[i] }, ...{ mw: itm.MW } }
                    })


                )
                .then(CG_arr => {
                    for (let x = 0; x < ANA2.length; x++) {
                        CG_arr = this.updateCGObj(CG_arr, ANA2[x]);
                    }
                    return CG_arr;
                })

            return CG;
        }
        return null;

    }

    extractXY = (runlength, X = 'RUNLENGTH', Y = 'COT') => {

        let scenario = runlength.scenarios[0];
        // let status: string = "";
        let cot_list: any[] = [];
        if (scenario.firebox[0]["CALCULATIONS"] && scenario.firebox[0]["CALCULATIONS"].length > 0) {

            for (let iter of scenario.firebox[0]["CALCULATIONS"]) {
                if (!iter) {
                    // status = "Failed to iterate over COT-Runlength"
                    break;
                }
                const calc = iter["output_dict"];
                if ("ERROR_CODE" in calc) {
                    // status = "Found Error in output";
                    break;
                };

                cot_list.push([calc[X][0][0], calc[Y][0][0]])

            }

        }
        // else status = 'Failed to read COT of computed scenario '



        return cot_list;

    }

    extractTagsGroupByRL = (runlength, tags, rowcolType: ROWCOLType, mode = MODE.FULL) => {

        let scenario = runlength.scenarios[0];
        let status: string = "";
        let extractedArrays: any[] = [];

        if (scenario.firebox[0]["CALCULATIONS"] && scenario.firebox[0]["CALCULATIONS"].length > 0) {

            for (let iter of scenario.firebox[0]["CALCULATIONS"]) {
                if (!iter) {
                    status = "Failed to iterate over COT-Runlength"
                    break;
                }
                const calc = iter["output_dict"];
                if ("ERROR_CODE" in calc) {
                    status = "Found Error in output";
                    break;
                };
                let row: any[] = [];

                for (let tag of tags) {

                    if (rowcolType === ROWCOLType.VarCol || rowcolType === ROWCOLType.StaticCol)
                        row = row.concat(calc[tag][0][0]);
                    if (rowcolType === ROWCOLType.VarRow || rowcolType === ROWCOLType.StaticRow)
                        row = row.concat(calc[tag][0]);
                    if (rowcolType === ROWCOLType.VarValue || rowcolType === ROWCOLType.StaticValue)
                        row = row.concat(calc[tag][0]);


                }
                extractedArrays.push(row)

            }
            if (mode === MODE.HYBRID) {
                for (let iter of scenario.firebox[1]["CALCULATIONS"]) {
                    if (!iter) {
                        // status = "Failed to iterate over COT-Runlength"
                        break;
                    }
                    const calc = iter["output_dict"];
                    if ("ERROR_CODE" in calc) {
                        // status = "Found Error in output";
                        break;
                    };
                    let row: any[] = [];

                    for (let tag of tags) {

                        if (rowcolType === ROWCOLType.VarCol || rowcolType === ROWCOLType.StaticCol)
                            row = row.concat(calc[tag][0][0]);
                        if (rowcolType === ROWCOLType.VarRow || rowcolType === ROWCOLType.StaticRow)
                            row = row.concat(calc[tag][0]);
                        if (rowcolType === ROWCOLType.VarValue || rowcolType === ROWCOLType.StaticValue)
                            row = row.concat(calc[tag][0]);


                    }
                    extractedArrays.push(row)
                }
            }

        }
        // else status = 'Failed to read COT of computed scenario '

        return extractedArrays;

    }

    setMessage = (errorin, msg) => {
        this.setState({ error: true, message: msg })
    }



    map2Range = (in_min, in_max, out_min, out_max) => {
        const stepPercentage = 100 / (in_max - 1);
        let res: number[] = [];
        for (let v = 0; v < in_max; v++) {
            let num: any = stepPercentage * v;
            //let num: number = (v - in_min) * (out_max - out_min) / (in_max+1 - in_min) + out_min;
            let newNum: number = Math.round((num + Number.EPSILON) * 10) / 10;
            res.push(newNum)

        }
        res.push(out_max)
        return res;
    }


    getCG = async () => {
        let CG_List: any[] = [];
        let plants = new Set();
        let furnaces = new Set();

        let CG_MW = this.fetchCG_MW_Short();

        let filteredRL = this.props.scenarioRunlengths.filter(iter => iter.isExportable);
        for (const iter of filteredRL) {

            let newCg = await this.extractCGFraction(iter, CG_MW, true)

            if (newCg !== null) {
                if (!plants.has(iter.plantId))
                    plants.add(iter.plantId)

                let furnace = getFurnaceReferenceName(iter.plantId, iter.furnaceId) //this.props.furnaces[parseInt(iter.furnaceId) - 1].DisplayName
                if (!furnaces.has(furnace))
                    furnaces.add(furnace)

                CG_List.push({ plant: iter.plantId, furnace, scenario: iter.name, cg: newCg, mode: iter.scenarios[0].mode });
            }

        }

        return { plants, furnaces, scenarios: CG_List };



    }

    getXYItems = async (header: any) => {
        let scenarioList: any[] = [];
        let plants = new Set();
        let furnaces = new Set();
        let header_lc = header.map((i) => i.toString().toLocaleLowerCase())

        let filteredRL = this.props.scenarioRunlengths.filter(iter => iter.isExportable);
        for (const iter of filteredRL) {


            let newXYProps = this.extractXY(iter, header[0], header[1])

            if (newXYProps !== null) {
                if (!plants.has(iter.plantId))
                    plants.add(iter.plantId)

                let furnace = getFurnaceReferenceName(iter.plantId, iter.furnaceId)
                //let furnace = this.props.furnaces[parseInt(iter.furnaceId) - 1].DisplayName
                if (!furnaces.has(furnace))
                    furnaces.add(furnace)


                scenarioList.push({ ...{ plant: iter.plantId, furnace, scenario: iter.name }, ...{ header: header_lc, [header_lc[1]]: newXYProps } });

            }

        }

        return { plants, furnaces, scenarios: scenarioList };

    }

    getXYItemsbyType = async (tagList: any[], typedef: ROWCOLType[]) => {
        let scenarioList: any[] = [];
        let plants = new Set();
        let furnaces = new Set();
        // let tags = tagList.map((i) => i.toString().toLocaleUpperCase())
        let varColTags: any[] = [];
        let staticColTags: any[] = [];
        let varRowTags: any[] = [];
        let staticRowTags: any[] = [];
        let varSingleTags: any[] = [];
        let staticSingleTags: any[] = [];
        // let staticAvail = false;

        for (let i in tagList) {

            switch (typedef[i]) {

                case ROWCOLType.StaticCol: { staticColTags.push(tagList[i]); break; }
                case ROWCOLType.VarCol: { varColTags.push(tagList[i]); break; }
                case ROWCOLType.StaticRow: { staticRowTags.push(tagList[i]); break; }
                case ROWCOLType.VarRow: { varRowTags.push(tagList[i]); break; }
                case ROWCOLType.StaticValue: { staticSingleTags.push(tagList[i]); break; }
                case ROWCOLType.VarValue: { varSingleTags.push(tagList[i]); break; }
            }

        }

        let filteredRL = this.props.scenarioRunlengths.filter(iter => iter.isExportable);

        for (let i in filteredRL) {
            let scenario = filteredRL[i];
            let newXYProps: any = [];
            let tagorder: any = [];

            if (i === '0') {
                this.readTags(scenario, staticColTags, ROWCOLType.StaticCol, tagorder, newXYProps, scenario.scenarios[0].mode);
                this.readTags(scenario, staticRowTags, ROWCOLType.VarRow, tagorder, newXYProps, scenario.scenarios[0].mode);
                this.readTags(scenario, staticSingleTags, ROWCOLType.StaticValue, tagorder, newXYProps, scenario.scenarios[0].mode);
            }

            this.readTags(scenario, varColTags, ROWCOLType.VarCol, tagorder, newXYProps, scenario.scenarios[0].mode);
            this.readTags(scenario, varRowTags, ROWCOLType.VarRow, tagorder, newXYProps, scenario.scenarios[0].mode);
            this.readTags(scenario, varSingleTags, ROWCOLType.VarValue, tagorder, newXYProps, scenario.scenarios[0].mode);

            if (newXYProps !== null) {
                if (!plants.has(scenario.plantId))
                    plants.add(scenario.plantId)
                let furnace = getFurnaceReferenceName(scenario.plantId, scenario.furnaceId)
                //let furnace = this.props.furnaces[parseInt(scenario.furnaceId) - 1].DisplayName
                if (!furnaces.has(furnace))
                    furnaces.add(furnace)


                scenarioList.push({ ...{ plant: scenario.plantId, furnace, scenario: scenario.name, mode: scenario.scenarios[0].mode }, ...{ order: tagorder, values: newXYProps } });

            }

        }

        return { plants, furnaces, scenarios: scenarioList };

    }

    readTags(scenario, tags, rowcolType, tagSequence, xyProps, mode = MODE.FULL) {

        if (tags.length > 0) {
            let s = this.extractTagsGroupByRL(scenario, tags, rowcolType, mode);
            if (rowcolType === ROWCOLType.VarCol || rowcolType === ROWCOLType.StaticCol) {
                if (xyProps.length > 0 && xyProps.length === s.length)
                    for (let j = 0; j < s.length; j++)
                        xyProps[j].push(...s[j])
                else
                    xyProps.push(...s)
            }
            else if (rowcolType === ROWCOLType.VarRow || rowcolType === ROWCOLType.StaticRow) {
                if (xyProps.length > 0 && xyProps.length === s.length)
                    for (let j = 0; j < s.length; j++)
                        xyProps[j].push(s[j])
                else
                    xyProps.push(s)

            }
            //Read unique single tags appearing only ONCE per RL. Not fully implemented!!
            // currently it reads coloum vaules for each RL like ROWCOLType.VarCol!! 
            else if (rowcolType === ROWCOLType.VarValue || rowcolType === ROWCOLType.StaticValue) {
                if (xyProps.length > 0 && xyProps.length === s.length)
                    for (let j = 0; j < s.length; j++)
                        xyProps[j].push(...s[j])
                else
                    xyProps.push(...s)

            }

            tagSequence.push(...tags);

        }
    }


    handleExportSelection = async (event) => {
        let selected = this.exportDropList.indexOf(event.target.value);

        this.setState({
            selection: selected,
            exportData: { ...this.state.exportData, ...{ "errorState": this.state.error }, ...{ getCG: this.getCG, getXYItemsbyType: this.getXYItemsbyType, map2Range: this.map2Range, getSessionConfig: this.createObj } }
        })
    }

    handleNameInput(event) {

        let fileName = event.target.value.trim();
        let splitStr = fileName.split(".")
        let precond1 = false;
        let precond2 = false;
        var pattern = /^(?![0-9._-])(?!.*[._-]$)[a-zA-Z0-9_-]+$/


        if (splitStr.length === 2)
            precond1 = splitStr[1].toUpperCase() === "JSON";

        else if (splitStr.length === 1)
            fileName = splitStr[0] //+ '.json'

        precond2 = splitStr[0].length > 1 && splitStr[0].length < 200;
        if (precond1)
            console.warn("FileName is to long or not a Json", splitStr[0])
        this.setState({
            allowExport: precond2 && pattern.test(splitStr[0]),
            fileName: fileName,

        })


    }

    isObject = (obj: any) => {
        return Object.prototype.toString.call(obj) === '[object Object]';
    };

    isEmpty = (obj: { hasOwnProperty: (arg0: string) => any; }) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }



    readScenarios(scenariodata, props) {

        let scenario_lst: {}[] = [];
        //Read Runlengths-Array as scenariodata
        scenariodata.forEach((data: { scenarios: string | any[] | undefined; isExportable: boolean; name: any; plantId: any; furnaceId: string | number; }) => {
            if (data.scenarios !== undefined && data.scenarios.length > -1 && data.isExportable === true) {

                let scenarioHeader = this.isEmpty(data) ? {} : {
                    Scenario: data.name,
                    Plant_Id: data.plantId,
                    Furnace_Ref: getFurnaceReferenceName(data.plantId, data.furnaceId)
                };

                let scenarioConfig = this.isEmpty(data.scenarios[0]) ? {} : {
                    Feedstock_Ref: data.scenarios[0].firebox[0].feedstock_id,
                    FuelGas_Ref: data.scenarios[0].fuelGas_id,
                    ConvSection_Ref: data.scenarios[0].convectionsection_id,
                    ConvSection_id: data.scenarios[0].convectionsection_id,
                    Config_Ref: data.scenarios[0].name,
                    CIT: data.scenarios[0].firebox[0].CIT,
                    COP: data.scenarios[0].firebox[0].COP,
                    DILUT: data.scenarios[0].firebox[0].DILUT,
                    FLOW_HC: data.scenarios[0].firebox[0].FLOW_HC,
                    SPEC: data.scenarios[0].firebox[0].SPEC,
                    SPEC_TYPE: data.scenarios[0].firebox[0].SPEC_TYPE,
                    KEY_COMPONENT: data.scenarios[0].firebox[0].KEY_COMPONENT,
                    TMTMAX: data.scenarios[0].TMTMAX,
                    INTERVAL: data.scenarios[0].INTERVAL,
                    LAVAL_RATIO: data.scenarios[0].LAVAL_RATIO,
                    P_XOVER: data.scenarios[0].firebox[0].P_XOVER,
                    RUNLENGTH_MAX: data.scenarios[0].RUNLENGTH_MAX
                }
                let scenarioConfigFirebox1 = {}
                if (data.scenarios[0].mode === MODE.HYBRID) {
                    scenarioConfigFirebox1 = this.isEmpty(data.scenarios[0]) ? {} : {
                        Feedstock_Ref: data.scenarios[0].firebox[1]?.feedstock_id,
                        FuelGas_Ref: data.scenarios[0].fuelGas_id,
                        ConvSection_Ref: data.scenarios[0].convectionsection_id,
                        ConvSection_id: data.scenarios[0].convectionsection_id,
                        Config_Ref: data.scenarios[0].name,
                        CIT: data.scenarios[0].firebox[1].CIT,
                        COP: data.scenarios[0].firebox[1].COP,
                        DILUT: data.scenarios[0].firebox[1].DILUT,
                        FLOW_HC: data.scenarios[0].firebox[1].FLOW_HC,
                        SPEC: data.scenarios[0].firebox[1].SPEC,
                        SPEC_TYPE: data.scenarios[0].firebox[1].SPEC_TYPE,
                        KEY_COMPONENT: data.scenarios[0].firebox[1].KEY_COMPONENT,
                        TMTMAX: data.scenarios[0].TMTMAX,
                        INTERVAL: data.scenarios[0].INTERVAL,
                        LAVAL_RATIO: data.scenarios[0].LAVAL_RATIO,
                        P_XOVER: data.scenarios[0].firebox[1].P_XOVER,
                        RUNLENGTH_MAX: data.scenarios[0].RUNLENGTH_MAX
                    }
                }
                scenario_lst.push({ ...scenarioHeader, ...scenarioConfig, scenarioConfigFirebox1: scenarioConfigFirebox1 });

            }

        });

        return scenario_lst;


    }

    defineFeedstocks(ScData, props, Data, errorTrace) {
        let feedstock_lst: {}[] = [];
        ScData.forEach((data: { Feedstock_Ref: undefined, scenarioConfigFirebox1: any[] }, index) => {
            if (data && data.Feedstock_Ref !== undefined && data.Feedstock_Ref !== "") {

                let feedsList: any = [];
                feedsList.push(props.feedstocks.find((feedstock) => feedstock.id === data.Feedstock_Ref));
                if (props.scenarioRunlengths[index]?.scenarios[0].mode === MODE.HYBRID && data.scenarioConfigFirebox1['Feedstock_Ref']) {
                    feedsList.push(props.feedstocks.find((feedstock) => feedstock.id === data.scenarioConfigFirebox1['Feedstock_Ref']));
                }


                feedsList.forEach((feeds, idx) => {
                    let components: {}[] = [];
                    let method: any = null;
                    let feedConfig = {};
                    if (feeds !== undefined) {
                        if (feeds.type === "DETAILED_PIONA")
                            feeds.feedstock.components.forEach((element) =>
                                components.push({
                                    id: element.id,
                                    name: element.name,
                                    N_Paraffins: element.N_Paraffins,
                                    I_Paraffins: element.I_Paraffins,
                                    Olefins: element.Olefins,
                                    Naphthenes: element.Naphthenes,
                                    Aromatics: element.Aromatics
                                })
                            )
                        else if (feeds.type === "SHORT_PIONA") {
                            feeds.feedstock.components.forEach((element) =>
                                components.push({
                                    id: element.id,
                                    name: element.name,
                                    value: element.value,
                                })
                            )

                            method = feeds.feedstock.method;
                        }
                        else if (feeds.type === "DISCRETE_COMPOSITION")
                            feeds.feedstock.components.forEach((element: { molecular_weight: any; name: any; value: any; }) =>
                                components.push({
                                    molecular_Weight: element.molecular_weight,
                                    name: element.name,
                                    value: element.value
                                })
                            )
                        if (idx > 0) {
                            data.scenarioConfigFirebox1['Feedstock_Ref'] = feeds.name;
                        }
                        else {
                            data.Feedstock_Ref = feeds.name;
                        }
                        feedConfig = {
                            Name: feeds.name,
                            Type: feeds.type,
                            Unit: feeds.unit,
                            Components: components,
                            Method: method
                        }
                    }
                    else {
                        errorTrace.push({ error: true, msg: Data + " Feedstock ID " + data.Feedstock_Ref + " could not be found " })
                    }

                    if (!this.isEmpty(feedConfig['Components']))
                        feedstock_lst.push(feedConfig);
                    else
                        errorTrace.push({ error: true, msg: "In " + Data + " Feedstock ID " + data.Feedstock_Ref + " missing Components " })
                });

            }
        });

        return feedstock_lst;
    }

    defineFuelgases(ScData, props, Data, errorTrace) {
        let fuelgas_lst: any[] = [];
        ScData.forEach((data: { FuelGas_Ref: undefined; } | undefined) => {
            if (data !== undefined && data.FuelGas_Ref !== undefined && data.FuelGas_Ref !== "") {

                let fuelGas = props.fuelGases.find((fuelGas: { id: any; }) => fuelGas.id === data.FuelGas_Ref);

                let components: {}[] = [];
                let fuelGasConfig = {};
                if (fuelGas !== undefined) {
                    fuelGas.components.forEach((element: { molecular_weight: any; name: any; value: any; }) =>
                        components.push({
                            Molecular_Weight: element.molecular_weight,
                            Name: element.name,
                            Value: element.value
                        })
                    )

                    data.FuelGas_Ref = fuelGas.name;
                    fuelGasConfig = {
                        Name: fuelGas.name,
                        Unit: fuelGas.unit,
                        Components: components
                    }

                    fuelgas_lst.push(fuelGasConfig)
                }
                else {

                    errorTrace.push({ error: true, msg: Data + " Fuelgas ID " + data.FuelGas_Ref + " could not be found " })

                }

            }


        });


        return fuelgas_lst;
    }

    defineConvectionSections(ScData, props, SelectedConvSections, Data, errorTrace) {
        let convectionsection_lst: any[] = [];

        ScData.forEach((data: { ConvSection_id: undefined; ConvSection_Ref: undefined; } | undefined) => {

            if (data !== undefined && data.ConvSection_id !== undefined && data.ConvSection_id !== "") {

                let cs = props.convectionSections.find((cs: { id: any; }) => cs.id === data.ConvSection_id);

                let components: {}[] = [];
                let convectionConfig = {};

                if (cs !== undefined) {
                    if (!SelectedConvSections.has(cs.name)) {
                        data.ConvSection_Ref = cs.name
                        SelectedConvSections.add(cs.name)
                        let values: any = Object.values(cs.components[0])
                        values.forEach((val: { input_tag: any; design: any; tag_unit: any; }) => {
                            if (this.isObject(val)) {

                                components.push({
                                    Name: val.input_tag,
                                    Value: val.design,
                                    Unit: val.tag_unit
                                })
                            }

                        })
                        data.ConvSection_Ref = cs.name;
                        convectionConfig = {
                            Name: cs.name,
                            Components: components,
                            Furnace_Id: cs.furnace_id,
                            Furnace_Ref: props.furnaces[cs.furnace_id - 1]["DisplayName"],
                            Asset: cs.asset_name
                        };
                        convectionsection_lst.push(convectionConfig)
                    }
                }
                else
                    errorTrace.push({ error: true, msg: Data + "Convection Section ID " + data.ConvSection_id + " could not be found" })


            }
        });



        return convectionsection_lst;
    }

    readFeedstocks(scenariodata, prodPreddata, props, errorTrace) {

        let scfeedstock_lst: {}[] = [];
        let prodfeedstock_lst: {}[] = [];

        scfeedstock_lst = this.defineFeedstocks(scenariodata, props, "Scenario-Simulator", errorTrace);
        let scenarioIdx = 0
        if (!!prodPreddata[scenarioIdx]) {
            prodfeedstock_lst = this.defineFeedstocks(prodPreddata[scenarioIdx]['Feedstocks'], props, "Yield-Predictor", errorTrace);
        }
        return [...scfeedstock_lst, ...prodfeedstock_lst];

    }

    readFuelGases(scenariodata, prodPreddata, props, errorTrace) {

        let scenfuelgas_lst: any[] = [];
        let ppfuelgas_lst: any[] = [];

        scenfuelgas_lst = this.defineFuelgases(scenariodata, props, "Scenario-Simulator", errorTrace);
        ppfuelgas_lst = this.defineFuelgases(prodPreddata, props, "Yield-Predictor", errorTrace);

        return [...scenfuelgas_lst, ...ppfuelgas_lst];
    }


    readConvectionsections(scenariodata, prodPreddata, props, errorTrace) {

        let scen_cs_lst: {}[] = [];
        let pp_cs_lst: {}[] = [];

        let SelectedConvSections = new Set();
        scen_cs_lst = this.defineConvectionSections(scenariodata, props, SelectedConvSections, "Scenario-Simulator", errorTrace)

        let scenarioIdx = 0;
        if (!!prodPreddata[scenarioIdx]) {
            pp_cs_lst = this.defineConvectionSections(prodPreddata[scenarioIdx]['ConvSections'], props, SelectedConvSections, "Yield-Predictor", errorTrace)
        }

        return [...scen_cs_lst, ...pp_cs_lst];
    }

    readProdPredictor(props) {
        let prodPred_lst: {}[] = [];
        let FeedStockSet = new Set();
        let ConvSectionSet = new Set();
        let FeedStocks: any[] = [];
        let ConvSections: any[] = [];
        //Read Runlengths-Array as scenariodata
        let ppScenarios = props.yieldPredictions
        ppScenarios.forEach((data: { id: string, groups: any[] | undefined; name: any, pressure: string, prediction_time: string, fuelgas_id: string, isExportable: boolean, output: any }) => {
            if (data.groups !== undefined && data.groups.length > -1) {

                let scenarioHeader = this.isEmpty(data) ? {} : {
                    Scenario: data.name,
                    Pressure: data.pressure,
                    PredictionTime: data.prediction_time,
                    FuelGas_Ref: data.fuelgas_id,
                    isExportable: data.isExportable
                    //Plant_Id: data.plantId,
                    //Furnace_Ref: getFurnaceReferenceName(data.plantId, data.furnaceId)
                };



                let scenarioConfig: any = {}
                data.groups.forEach((grp, i) => {
                    let FGrpFeedFlows: any[] = []

                    grp.feedstock.forEach((fs, idx) => {
                        //FGrpFeedFlows.push({ "flowName": fs.name + '-FlowRate', 'flowrate': fs.flowrate, 'feedRefName': fs.name + '-FeedstockRef', 'feedRef': fs.feedstock_id })
                        let cId = idx + 1;
                        let feedstock = { ["COMPONENT-" + cId]: fs.name, ["COMPONENT-" + cId + "-FLOWRATE"]: fs.flowrate, ["COMPONENT-" + cId + "-FEEDSTOCK"]: fs.feedstock_id };
                        FGrpFeedFlows.push(feedstock)
                        if (!FeedStockSet.has(fs.feedstock_id)) {
                            FeedStockSet.add(fs.feedstock_id)
                            FeedStocks.push({ "Feedstock_id": fs.feedstock_id, "Feedstock_Ref": fs.feedstock_id })
                        }
                        //FeedStocks.add(fs.feedstock_id)
                        //FeedStocks.push(feedstock)
                    })

                    if (!ConvSectionSet.has(grp.convectionsection_id) && grp.convectionsection_id !== '') {
                        ConvSectionSet.add(grp.convectionsection_id)
                        ConvSections.push({ "ConvSection_id": grp.convectionsection_id, "ConvSection_Ref": grp.convectionsection_id })
                    }

                    let grpConfig = this.isEmpty(grp) ? {} : {

                        Conv_Ref: grp.convectionsection_id,
                        FGroupLabel: grp.grouplabel[0],
                        NUM_FURNACES: grp.furnace_number + '/' + grp.maxFurnace_number,
                        CIT: grp.CIT,
                        DILUT: grp.DILUT,

                        FEED_FLOWS: FGrpFeedFlows,
                        SPEC: grp.SPEC,
                        SPEC_TYPE: grp.SPEC_TYPE,
                        KEY_COMPONENT: grp.KEY_COMPONENT,
                        HydroCarbon_Flow_Per_Furnace: compiledFlowHC(data, data.groups, grp.name),

                        /* /* Feedstock_Ref: data.groups[0].feedstock_id,
                          FuelGas_Ref: data.groups[0].fuelGas_id,
                         Convection_Ref: data.groups[0].convectionsection_id,*/
                    }

                    scenarioConfig = { ...scenarioConfig, ...{ ['FurnaceGrp_' + i]: grpConfig } }

                })

                let products = this.isEmpty(data) ? {} : data.output;


                prodPred_lst.push(
                    {
                        ...scenarioHeader,
                        ...scenarioConfig,
                        ...products,
                        ...{ 'Feedstocks': FeedStocks },
                        ...{ 'ConvSections': ConvSections }
                    });

            }

        });

        return prodPred_lst;
    }


    createObj = async () => {


        let scenariodata = JSON.parse(JSON.stringify(this.props.scenarioRunlengths.filter(x => !x.name.includes("Realtime Advisor"))));
        var utcDate1 = new Date(Date.now());
        scenariodata.name = scenariodata.name + " " + utcDate1.toUTCString();

        let sessionToExport = {};
        let errorTrace: any[] = [];
        let scenario_lst = this.readScenarios(scenariodata, this.props);

        let prodPredictor_lst = this.readProdPredictor(this.props)
        let convectionSection_lst = this.readConvectionsections(scenario_lst, prodPredictor_lst, this.props, errorTrace);
        let feedstock_lst = this.readFeedstocks(scenario_lst, prodPredictor_lst, this.props, errorTrace);
        let fuelGas_lst = this.readFuelGases(scenario_lst, prodPredictor_lst, this.props, errorTrace);

        if (this.state.error) {
            errorTrace.push({ error: true, msg: "Unexpected Error occured during Export preparion" })
        }
        else if (feedstock_lst.length === 0 || fuelGas_lst.length === 0) {
            errorTrace.push({ error: true, msg: "Feedstock or Fuelgas are not defined or loaded" })
        }
        else if ((this.props.scenarioRunlengths.length === 0 && this.props.yieldPredictions.length === 0)) {
            errorTrace.push({ error: true, msg: "In order to export this session either Scenario-Simulator or Yield-predicor results needs to be available." })
        }


        sessionToExport = {
            Version: "291020210001ie",
            Session: {
                Scenarios: scenario_lst,
                Feedstocks: feedstock_lst,
                FuelGases: fuelGas_lst,
                ConvectionSections: convectionSection_lst,
                YieldPredictor: prodPredictor_lst,
                ErrorTrace: errorTrace
            }
        };

        return sessionToExport
    }


    jsonConfigExporter = async () => {

        var data = JSON.stringify(await this.createObj());
        var element = document.createElement('a');
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(data));
        element.setAttribute('download', this.state.fileName);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }


    toggleShowDialog() {
        let yieldAvaliable = this.state.exportData["isYieldPredExportable"]
        let runlengthAvaliable = this.props.scenarioRunlengths !== undefined && this.props.scenarioRunlengths.length > 0;
        let isRunlengthExportable = false
        for (const ScenarioData of this.props.scenarioRunlengths) {
            if (ScenarioData.isExportable === true) {
                isRunlengthExportable = true;
                //break;
            }
        }


        this.setState({
            showDialog: (runlengthAvaliable && isRunlengthExportable) || yieldAvaliable ? !this.state.showDialog : false,
            allowExport: ((runlengthAvaliable && isRunlengthExportable) || yieldAvaliable) && !this.state.error

        })
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                    disabled={!(this.state.exportData["isYieldPredExportable"] || this.state.exportData["isScenariosExportable"])}
                >
                    Export Session
                </button>
                <Dialog
                    open={this.state.showDialog}
                    //onClose={() => this.toggleShowDialog()}
                    onFocus={() => {
                        this.setState({

                            exportData: { ...this.state.exportData, ...{ "errorState": this.state.error }, ...{ getCG: this.getCG, getXYItemsbyType: this.getXYItemsbyType, map2Range: this.map2Range, getSessionConfig: this.createObj, setMessage: this.setMessage } }
                        })

                    }}
                    // onBlur={()=>{this.setState({error:false})}}
                    aria-labelledby="form-dialog-title">
                    {!this.state.error && (<>
                        <DialogTitle id="form-dialog-title">Export Session</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Rename Session-File:
                            </DialogContentText>
                            <div className={"load-scenario"}>
                                <TextField
                                    // id={"scenario-runlength"}
                                    margin="dense"
                                    style={{ width: "100%" }}
                                    SelectProps={{ autoWidth: true }}
                                    error={!this.state.allowExport}
                                    helperText={!this.state.allowExport ? "Error: Can't find all exportable fields!" : ""}
                                    label={"Name:"}
                                    defaultValue={this.state.fileName}
                                    onChange={(event) => this.handleNameInput(event)}
                                >
                                </TextField>
                                <TextField
                                    id={"scenario-runlength"}
                                    select
                                    margin="dense"
                                    style={{ width: "100%" }}
                                    SelectProps={{ autoWidth: true }}
                                    // error={this.props.scenarioRunlengths === []}
                                    // helperText={this.props.scenarioRunlengths === [] ? "Error: Can't find all exportable fields!" : ""}
                                    label={"Export Type:"}
                                    defaultValue={this.exportDropList[this.state.selection]}
                                    value={this.exportDropList[this.state.selection]}
                                    disabled={true}
                                    onChange={(event) => this.handleExportSelection(event)}
                                >
                                    {this.exportDropList.map((value: string, index: number) => (

                                        <MenuItem key={index} value={value}>
                                            {value}
                                        </MenuItem>

                                    ))}
                                </TextField>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button className="button" onClick={() => this.toggleShowDialog()} >
                                Cancel
                            </button>
                            <button className="button" onClick={

                                () => {
                                    if (this.state.error)
                                        this.toggleShowDialog();
                                    else if (this.state.selection === DropListEnum.Results) {
                                        xlsExporter(this.state, this.props.scenarioRunlengths);
                                        this.toggleShowDialog();
                                    }

                                    else if (this.state.selection === DropListEnum.Config) this.jsonConfigExporter();


                                }
                            }
                                disabled={!this.state.allowExport}>
                                Export
                            </button>
                        </DialogActions>
                    </>)}
                    {this.state.error && (
                        <>
                            <DialogTitle id="form-dialog-title1">Export of {this.state.fileName}.{this.state.selection === DropListEnum.Results ? "xlsx" : "json"} is not possible! </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {this.state.message}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className="button" onClick={() => {
                                    this.setState({ error: false, message: "" })
                                    this.toggleShowDialog();
                                }
                                }  >
                                    Ok
                                </button>
                            </DialogActions>

                        </>)}




                </Dialog>
            </div>
        );
    }


};

export default withAuth(connector(ExportSession));