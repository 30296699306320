import React, { Component } from "react";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControlLabel, TextField } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { RootState } from "../../reducers/rootReducer";
import { modifyScenarioRunlength, setCurrentRunLengthId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";
import { checkScenarioRunlengthName } from "../../services/scenarioSimulatorServices";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface IRenameScenarioRunlengthProps {
    scenarioRunlength: IScenarioRunlength;
}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths
    };
};

const mapDispatchToProps = dispatch => {
    return {
        modifyScenarioRunlength: (newScenarioRunlength: IScenarioRunlength) => dispatch(modifyScenarioRunlength(newScenarioRunlength)),
        setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
        setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IRenameScenarioRunlengthReduxProps = PropsFromRedux & IRenameScenarioRunlengthProps & OIDCAuth;

type IRenameScenarioRunlengthState = {
    showDialog: boolean;
    name: string;
    is_public: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
}
class RenameScenarioRunlength extends Component<IRenameScenarioRunlengthReduxProps, IRenameScenarioRunlengthState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: "",
            is_public: this.props.scenarioRunlength.is_public === true ? this.props.scenarioRunlength.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: "",
            showLoading: false,
        }
    }

    toggleShowDialog() {
        let getName = this.props.scenarioRunlength.is_public === true ? this.props.scenarioRunlength.name.replace('(PUBLIC)', '') : this.props.scenarioRunlength.name;
        this.setState({
            showDialog: !this.state.showDialog,
            name: getName,
            is_public: this.props.scenarioRunlength.is_public === true ? this.props.scenarioRunlength.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: ""
        })
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value.toUpperCase()
        })
    }

    renameNewScenarioRunlength = async () => {
        if (!!this.state.name?.trim()) {
            this.setState({
                showLoading: true,
            })
            let setName = this.state.is_public === true ? this.state.name.trim() + "(PUBLIC)" : this.state.name.trim();
            const newScenarioRunlength: IScenarioRunlength = {
                ...this.props.scenarioRunlength,
                name: setName,
                is_display_leave_popup: true,
                is_saved: false
            }

            try {
                let isNameInRedux = this.props.scenarioRunlengths.some(scenarioRunlength => scenarioRunlength.name === newScenarioRunlength.name);
                if (isNameInRedux) {
                    this.setState({
                        isNameAvailable: true,
                        nameErrorMessage: "Name already exist!",
                        showLoading: false,
                    })
                } else {
                    const accessToken = getToken(this.props.auth);
                    let isNameAvailable = await checkScenarioRunlengthName(newScenarioRunlength, accessToken);
                    if (isNameAvailable.status) {
                        this.setState({
                            isNameAvailable: true,
                            nameErrorMessage: !!isNameAvailable.message ? isNameAvailable.message : "Name already exist!",
                            showLoading: false,
                        })
                    } else {
                        this.props.modifyScenarioRunlength(newScenarioRunlength);
                        this.props.setCurrentScenarioRunLengthId(newScenarioRunlength.id);
                        this.props.setCurrentRunLengthId(0);
                        this.toggleShowDialog();
                        this.setState({
                            showLoading: false,
                        })
                    }
                }
            } catch (error) {
                // Api error hendaling 
                this.setState({
                    isNameAvailable: true,
                    showLoading: false,
                    nameErrorMessage: "Oops somthing went to wrong!"
                })
            }

        } else {
            this.setState({
                isNameAvailable: true,
                nameErrorMessage: "Please enter a name!",
                showLoading: false,
            })
        }

    }

    handleChangeIsPublic(event) {
        this.setState({
            is_public: !this.state.is_public
        })
    }

    render() {
        return (
            <div className="control-header">
                <button
                    className="button margin-right"
                    onClick={() => this.toggleShowDialog()}
                >
                    Rename
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Rename scenario runlength</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To rename a new scenario runlength, please provide the following information:
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            value={this.state.name}
                            onChange={(event) => this.handleNameInput(event)}
                            helperText={this.state.nameErrorMessage}
                            error={this.state.isNameAvailable}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.is_public}
                                    onChange={(event) => this.handleChangeIsPublic(event)}
                                    name="isPublic"
                                    value={this.state.is_public}
                                    disabled={true}
                                    color="primary"
                                />
                            }
                            label="Is Public?"
                        />
                    </DialogContent>

                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.renameNewScenarioRunlength()} disabled={this.state.showLoading}>
                            Modify {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

};

export default withAuth(connector(RenameScenarioRunlength));