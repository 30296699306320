import { IFeedstock } from "../interfaces/IFeedstock";
import { REACT_APP_APIM_URL_SCENARIO } from "../utilities/GlobalConstants";
import store from "..";

// export const REACT_APP_APIM_URL_SCENARIO = process.env.NODE_ENV === "production" ? "http://localhost:8000" : `${REACT_APP_APIM_URL}/scenarios/${RESULT_SERVICE_VERSION}`;

/**
 *
 * @param newFeedstock 
 * @returns 
 */
export async function checkFeedStockName(newFeedstock: IFeedstock, token: string) {
    // Refresh token before calling compute Initialization
    const repsonce = {
        status: false,
        message: "",
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/feedstocks/${newFeedstock.name}/${newFeedstock.is_public}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                if (data.status) {
                    data.message = "Name already exist!"
                }
                return data
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check;
}

/**
 * 
 * @param newFeedstock 
 * @returns 
 */
export async function saveNewFeedStock(newFeedstock: IFeedstock, token: string) {

    const repsonce = {
        status: false,
        message: "",
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/feedstocks`, {
        method: "POST",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(newFeedstock),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "This feedstock save sucessfully!"
                return repsonce
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check;
}

/**
 * 
 * @param feedstockId 
 * @returns 
 */
export async function deleteFeedStockByID(feedstockId: string, token: string) {

    const repsonce = {
        status: false,
        message: "",
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/feedstocks`, {
        method: "DELETE",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
            Id: feedstockId
        }),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "This feedstock deleted sucessfully!"
                return repsonce
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check
}

/**
 * 
 * @returns 
 */
export async function loadFeedstockData(token: string) {

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/feedstocks`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = !!data ? data : [];
                return repsonce;
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce;
        });

    return check
}

/**
 * 
 * @param feedstockId 
 * @returns 
 */
export async function getFeedstockById(feedstockId: string, token: string) {

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/feedstocks/${feedstockId}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = !!data ? data : [];
                return repsonce;
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce;
        });

    return check

}