import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { IProductPredictorScenario } from '../../interfaces/IProductPredictor';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from '@material-ui/core';
import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import { getProductPredictorById, saveNewProductPredictor } from '../../services/productPredictorServices';
import { AddProductPredictorScenario } from '../../slices/productPredictorSlice';
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface ISaveProductPredictorProps extends RouteComponentProps {
    currentProductPredictor?: IProductPredictorScenario
}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return { fuelGases: state.fuelGas.fuelGases }
}
const mapDispatchToProps = dispatch => {
    return {
        add: (obj: IProductPredictorScenario) => dispatch(AddProductPredictorScenario(obj)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;

type ISaveProductPredictorReduxProps = PropsFromRedux & ISaveProductPredictorProps & OIDCAuth;

type ISaveProductPredictorState = {
    showDialog: boolean;
    name: string;
    message: string;
    location: string;
    signature: string;
    showLoading: boolean;

}

class saveProductPredictor extends Component<ISaveProductPredictorReduxProps, ISaveProductPredictorState> {
    accessToken = getToken(this.props.auth);
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            name: "",
            message: "",
            location: "",
            signature: !!this.props.currentProductPredictor && this.props.currentProductPredictor.signature ? this.props.currentProductPredictor.signature : "",
            showLoading: false,
        };
    }

    hendalClickOnSavePopup = () => {
        this.toggleShowDialog();
    }

    handleTransition = location => {
        if (this.props.currentProductPredictor?.signature !== this.state.signature) {
            this.setState({
                location: location.pathname + location.search
            })
            this.toggleShowDialog();
            return false
        } else {
            return true
        }
    };

    toggleShowDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog,
            name: "",
            message: ""
        })
    }

    hendalClickOnCancelbButtonInSavePopup = () => {
        this.toggleShowDialog();
    }

    handleSaveProductPredictor = async () => {
        let currentProductPredictorData = this.props.currentProductPredictor as IProductPredictorScenario;
        if (currentProductPredictorData.signature !== "") {
            this.setState({
                showLoading: true,
            })
            try {
                let save = await saveNewProductPredictor(currentProductPredictorData, this.accessToken);
                if (save.status) {
                    let getNewSavedUpdatedById = await getProductPredictorById(currentProductPredictorData.id, this.accessToken);
                    if (getNewSavedUpdatedById.status) {
                        this.setState({
                            message: save.message,
                            // signature: this.props.currentProductPredictor?.signature,
                            showLoading: false,
                        })
                        let pp = getNewSavedUpdatedById.data[0] as IProductPredictorScenario;

                        const newProductPredictor: IProductPredictorScenario = {
                            ...pp,
                            is_display_leave_popup: false,
                            is_saved: true,
                        }
                        this.props.add(newProductPredictor)

                        setTimeout(() => {
                            this.toggleShowDialog();
                            if (!!this.state.location && this.state.location !== "") {
                                this.props.history.push(this.state.location)
                            }
                        }, 0);
                    } else {
                        this.setState({
                            message: "Oops... somthing went to wrong!",
                            showLoading: false,
                        })
                    }
                } else {
                    this.setState({
                        message: "Oops... somthing went to wrong!",
                        showLoading: false,
                    })
                }
            } catch (error) {
                this.setState({
                    message: "Oops... somthing went to wrong!",
                    showLoading: false,
                })
            }
        } else {
            this.setState({
                message: "Please Add component value in feedstock!",
                showLoading: false,
            })
        }

    }

    handleCancelFeedstockRedirection = () => {
        this.toggleShowDialog();
    }

    handleLeaveFeedstock = () => {
        this.toggleShowDialog();
    }



    render() {
        return (
            <div className="control-header element">
                <button
                    id="saveButton"
                    className={!!this.props.currentProductPredictor && this.props.currentProductPredictor?.output?.products.length > 0 && this.props.currentProductPredictor?.progress === 100 && this.props.currentProductPredictor.is_saved === false ? "button red" : "button"}
                    onClick={() => this.hendalClickOnSavePopup()}
                    disabled={this.props.currentProductPredictor?.output?.products.length === 0 || this.props.currentProductPredictor?.progress === 0}
                >
                    Save
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    {!!this.props.currentProductPredictor?.is_display_leave_popup === false && (
                        <>
                            <DialogTitle id="form-dialog-title">Save this product predictor</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to save this product predictor?
                                </DialogContentText>
                                {!!this.state.message && this.state.message !== "" && (
                                    <Typography variant="body1" component="p" color="secondary">
                                        {this.state.message}
                                    </Typography>
                                )}
                            </DialogContent>


                            <DialogActions>
                                <button className="button" onClick={() => this.hendalClickOnCancelbButtonInSavePopup()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveProductPredictor()} disabled={this.state.showLoading}>
                                    Save {this.state.showLoading === true && (
                                        <Fade
                                            in={this.state.showLoading}
                                            style={{
                                                transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress size={11} />
                                        </Fade>
                                    )}
                                </button>
                            </DialogActions>
                        </>
                    )}
                    {!!this.props.currentProductPredictor?.is_display_leave_popup === true && (
                        <>
                            <DialogTitle id="form-dialog-title1">Leave Page</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Do you want to save ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className="button" onClick={() => this.handleCancelFeedstockRedirection()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveProductPredictor()} >
                                    Yes
                                </button>
                                <button className="button" onClick={() => this.handleLeaveFeedstock()} >
                                    No
                                </button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
                <Prompt when={!!this.props.currentProductPredictor?.is_display_leave_popup} message={this.handleTransition} />
            </div >
        )
    }
}

export default withAuth(connector(withRouter(saveProductPredictor)))



