import store from ".";
import {
  IComponent,
  IDetailedPionaComponent,
  IDiscreteCompositionComponent,
  IMethodDistribution,
  ShortPionaMethod,
  HeavyFeedMethod,
} from "./interfaces/IComponent";
import { FEEDSTOCK_TYPES } from "./interfaces/IFeedstock";
import { REACT_APP_APIM_URL_RESULT } from "./utilities/GlobalConstants";
import { getHybridFields } from "./utilities/helperFunctions";
import { loadConfigVersion,loadConfigCreationdate,loadConfigClient,loadConfigPlants,loadConfigFurnaces, loadConfigFuelgas, loadConfigScenariosimulator, loadConfigCoilgroups, loadConfigCameras, loadConfigFeedstocks, loadConfigProductPredictor } from "./slices/configurationSlice";
import { setFurnaceId, setAssetName } from "./slices/furnaceSlice";
import { setCurrentFurnaceId, setCurrentPlantId, setCurrentFurnaceTypeId, setCurrentFeedTypeId } from "./slices/scenarioSimulatorSlice";

//export const config = require("./assets/customer_specific_data/plant_config.json");
let config : any

export function setConfig(accessToken){
  if(store.getState().configuration.plants.length === 0 || store.getState().configuration.furnaces.length === 0)
  {
    fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForPlantsAndFurnaces`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          store.dispatch(
            loadConfigVersion(data.config_version)
          );
          store.dispatch(
            loadConfigCreationdate(data.creation_date)
          );
          store.dispatch(
            loadConfigClient(data.client)
          );
          store.dispatch(
            loadConfigPlants(data.plants)
          );
          store.dispatch(
            loadConfigFurnaces(data.furnaces)
          );
          store.dispatch(
            setFurnaceId(getDefaultFurnaceReferenceId(getDefaultPlantReferenceId()))
          );
          store.dispatch(
            setAssetName(getAssetName(getDefaultFurnaceReferenceId(getDefaultPlantReferenceId())))
          );
          store.dispatch(
            setCurrentFurnaceId(getDefaultFurnaceReferenceId(getDefaultPlantReferenceId()))
          );
          store.dispatch(
            setCurrentPlantId(getDefaultPlantReferenceId())
          );
          store.dispatch(
            setCurrentFurnaceTypeId(getDefaultFurnaceTypeId())
          );
          return store.getState().configuration
        },
        (error) => console.log("error", error)
      );
  }

  if(store.getState().configuration.fuelgas.length === 0 || store.getState().configuration.scenario_simulator.length === 0)
  {
    fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForFuelgasAndScenarioSimulator`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          store.dispatch(
            loadConfigFuelgas(data.fuelgas)
          );
          store.dispatch(
            loadConfigScenariosimulator(data.scenario_simulator)
          );
          return store.getState().configuration
        },
        (error) => console.log("error", error)
      );
  }

  if(store.getState().configuration.coilgroups.length === 0 || store.getState().configuration.camera_configs.length === 0)
  {
    fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForCoilgroupsAndCamera`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          store.dispatch(
            loadConfigCoilgroups(data.coilgroups)
          );
          store.dispatch(
            loadConfigCameras(data.camera_configs)
          );
          return store.getState().configuration
        },
        (error) => console.log("error", error)
      );
  }

  if(store.getState().configuration.feedstocks.length === 0)
  {
    fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForFeedstock`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          store.dispatch(
            loadConfigFeedstocks(data.feedstocks)
          );
          // store.dispatch(
          //   setCurrentFeedTypeId(getDefaultFeedTypeId())
          // );
          return store.getState().configuration
        },
        (error) => console.log("error", error)
      );
  }

  if(store.getState().configuration?.product_predictor?.length === 0)
  {
    fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForProductPredictor`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          store.dispatch(
            loadConfigProductPredictor(data.product_predictor)
          );
          return store.getState().configuration
        },
        (error) => console.log("error", error)
      );  
  }else{
    console.log(store.getState().configuration.product_predictor,'product predictor is absent');
  }
}

function getConfig(){
    let config : any = store.getState().configuration
    return config
}

export function getConfigVersion(): string {
  console.log('in getConfigVersion()')
  return getConfig().config_version;
}

export function getCreationDate(): string {
  console.log('in getCreationDate()')
  return getConfig().creation_date;
}

export function getPlantReferences(): any[] {
  console.log('in getPlantReferences()')
  let current_plants: any[] = [];
  console.log('getConfig().plants',getConfig().plants)
  for (let plant of getConfig().plants) {
    current_plants.push(plant);
  }
  return current_plants;
}

export function getTemperatureUnit(): any {
  const current_plantID = store.getState().scenarioSimulator.currentPlantId;
  let temp_unit = getPlantReferenceById(current_plantID).temperature_unit;  
  if (temp_unit === "") 
    return "°C";
  else 
    return temp_unit; 
}

export function temperatureUnitConversion(celciusValue: number): any {
  /** 
  * If the temperature unit is Fahrenheit, convert the temperature value from Celsius to Fahrenheit
  * @param celciusValue: number - The temperature value in Celsius
  */
  if (getTemperatureUnit() === "°F") 
    return (celciusValue * 9/5) + 32;
  else 
    return celciusValue; 
}

export function getEnumByValue(enumObj:any, value: string) {
  const keys = Object.keys(enumObj).filter(key => enumObj[key] === value);
  return keys.length > 0 ? enumObj[keys[0]] : null;
}



export function getPlantReferenceById(plant_reference_id: string): any {
  console.log('in getPlantReferenceById()')
  return getPlantReferences().find((plant) => plant.id === plant_reference_id);
}

export function getDefaultPlantReferenceId(): string {
  console.log('in getDefaultPlantReferenceId()')
  return getPlantReferences().find((plant) => plant.default).id;
}

export function getFurnaceReferences(plant_id: string): any {
  console.log('in getFurnaceReferences()')
  const furnaces: any[] = [];
  getConfig().furnaces.forEach((furnace) => {
    if (furnace.plant_id === plant_id) {
      furnaces.push(furnace);
    }
  });
  return furnaces;
}

export function getDefaultFurnaceReferenceId(plant_id: string): string {
  console.log('in getDefaultFurnaceReferenceId()')
  const furnaces = getFurnaceReferences(plant_id);
  return furnaces.find((furnace) => furnace.default).id;
}
export function getFurnaceReferenceAssetNames(
  plant_reference_id: string
): string[] {
  console.log('in getFurnaceReferenceAssetNames()')
  return getFurnaceReferences(plant_reference_id).map(
    (furnace) => furnace.asset_name
  );
}

export function getFurnaceReferenceName(
  plant_reference_id: string,
  furnace_reference_id: string | number
): string {
  console.log('in getFurnaceReferenceName()')
  return getFurnaceReferences(plant_reference_id).find(
    (furnace) => furnace.id === furnace_reference_id
  )["name"];
}

export function getFurnaceCameras(furnace_id: any): any {
  console.log('in getFurnaceCameras()')
  const furnacecameras: any[] = [];
  const cameras: any[] = [];
  getConfig().furnaces.forEach((furnace) => {
    if (parseInt(furnace.id) === parseInt(furnace_id)) {
      furnacecameras.push(furnace);
    }
  });
  
  furnacecameras[0].camera_ids.forEach(x => {
    getConfig().camera_configs.forEach((camera) => {
      if (camera.id === x) {
        cameras.push(camera);
      }
    });
  });
  return cameras;
}

export function getCoilgroups(furnace_id: string): any {
  console.log('in getCoilgroups()')
  const furnacecameras: any[] = [];
  const cameras: any[] = [];
  getConfig().furnaces.forEach((furnace) => {
    if (furnace.id === furnace_id) {
      furnacecameras.push(furnace);
    }
  });
  
  furnacecameras[0].camera_ids.forEach(x => {
    getConfig().camera_configs.forEach((camera) => {
      if (camera.id === x) {
        cameras.push(camera);
      }
    });
  });

  const coilgroups: any[] = [];
  cameras.forEach(x => {
    getConfig().coilgroups.forEach((coilgroup) => {
      if (x.coilgroup_ids.includes(coilgroup.id)) {
        coilgroups.push(coilgroup);
      }
    });
  });
  return coilgroups;
}

export function getCameraByCoilgroup(coilgroupname: string): any {
  console.log('in getCameraByCoilgroup()')
  const coilgroups: any[] = [];
  getConfig().coilgroups.forEach((coilgroup) => {
    if (coilgroup.name === coilgroupname) {
      coilgroups.push(coilgroup);
    }
  });
  const cameras: any[] = [];
  coilgroups.forEach(x => {
    getConfig().camera_configs.forEach((camera) => {
      if (camera.coilgroup_ids.includes(x.id)) {
        cameras.push(camera);
      }
    });
  });
  return cameras;
}

export function getCoilgroupByCamera(cameraname: string): any {
  console.log('in getCoilgroupByCamera()')
    const cameras: any[] = [];
    getConfig().camera_configs.forEach((camera) => {
      if (camera.display_name === cameraname) {
        cameras.push(camera);
      }
    });
    const coilgroups: any[] = [];
    getConfig().coilgroups.forEach((coilgroup) => {
      if (coilgroup.id === cameras[0].coilgroup_ids[0]) {
        coilgroups.push(coilgroup);
      }
    });

  return coilgroups;
}

export function getCoilgroupName(coilgroup_id: string): any {
  console.log('in getCoilgroupName()')
  const coilgroups: any[] = [];
  getConfig().coilgroups.forEach((coilgroup) => {
    if (coilgroup.id === coilgroup_id) {
      coilgroups.push(coilgroup);
    }
  });

  return coilgroups[0].name;
}

export function getFurnaceByFurnaceId(furnace_id: number | string): any {
  console.log('in getFurnaceByFurnaceId()')
  const furnaces: any[] = [];
  getConfig().furnaces.find((furnace) => {
    if (furnace.id === furnace_id) {
      furnaces.push(furnace);
    }
  });
  
  return furnaces[0]?.name;
}

export function getFurnaceById(furnace_id: number | string): any {
  console.log('in getFurnaceById()')
    return getConfig().furnaces.filter(furnace => furnace_id == furnace.id);
}

export function getCameraByCameraId(camera_id: number | string): any {
  console.log('in getCameraByCameraId()')
  const cameras: any[] = [];
  getConfig().camera_configs.find((camera) => {
    if (camera.name === camera_id) {
      cameras.push(camera);
    }
  });
  
  return cameras[0].display_name;
}

export function getCameraIdByCameraName(camera_name: string | string): any {
  console.log('in getCameraIdByCameraName()')
  const cameras: any[] = [];
  getConfig().camera_configs.find((camera) => {
    if (camera.name === camera_name) {
      cameras.push(camera);
    }
  });
  
  return cameras[0].id;
}

export function getFurnaceByCameraId(camera_id: string | string): any {
  console.log('in getFurnaceByCameraId()')
  const furnaces: any[] = [];
  getConfig().furnaces.find((furnace) => {
    if (furnace.camera_ids.includes(camera_id)) {
      furnaces.push(furnace);
    }
  });
  
  return furnaces[0].id;
}

export function getFurnaceNonSelectedCameras(furnace_id: string,currCamera: string): any {
  console.log('in getFurnaceNonSelectedCameras()')
  const furnacecameras: any[] = [];
  const cameras: any[] = [];
  const nonSelectedCameras: any[] = [];
  getConfig().furnaces.forEach((furnace) => {
    if (furnace.id === furnace_id) {
      furnacecameras.push(furnace);
    }
  });
  
  furnacecameras[0].camera_ids.forEach(x => {
      getConfig().camera_configs.forEach((camera) => {
        if (camera.id === x) {
          cameras.push(camera);
        }
      });
    });

    cameras.forEach((camera) => {
      if (camera.display_name !== currCamera) {
        nonSelectedCameras.push(camera);
      }
    });

    return nonSelectedCameras;
}

export function getCoilgroupByNonSelectedCamera(cameraname: string): any {
  console.log('in getCoilgroupByNonSelectedCamera()')
  const cameras: any[] = [];
  getConfig().camera_configs.forEach((camera) => {
    if (camera.display_name !== cameraname) {
      cameras.push(camera);
    }
  });
  const coilgroups: any[] = [];
  getConfig().coilgroups.forEach((coilgroup) => {
    if (coilgroup.id === cameras[0].coilgroup_ids[0]) {
      coilgroups.push(coilgroup);
    }
  });

return coilgroups;
}

export function getSelectedCameraDetails(camera_id: string | number): any {
  console.log('in getSelectedCameraDetails()')
  return getConfig().camera_configs.find((camera) => camera.id === camera_id);
}

export function getFurnaceReference(
  plant_reference_id: string,
  furnace_reference_id: string
): any[] {
  console.log('in getFurnaceReference()')
  return getFurnaceReferences(plant_reference_id).find(
    (furnace) => parseInt(furnace.id) === parseInt(furnace_reference_id)
  );
}

export function getNumberOfPlants(): number {
  console.log('in getNumberOfPlants()')
  const plants = getPlantReferences();
  return plants.length;
}

export function getScenarioInputDefinitions(
  furnace_type_id: string,
  feed_type_id: string
): any[] {
  console.log('in getScenarioInputDefinitions()')
  if (feed_type_id !== "") {
    return getConfig().scenario_simulator.find(
      (x) =>
        x.furnace_type_id === Number(furnace_type_id) &&
        x.feed_type_id === Number(feed_type_id)
    ).values;
  } else {
    return getConfig().scenario_simulator.find(
      (x) => x.furnace_type_id === Number(furnace_type_id)
    ).values;
  }
}

export function getDefaultDiscreteComposition(
  plant_id: string
): IDiscreteCompositionComponent[] {
  console.log('in getDefaultDiscreteComposition()')
  const feedstocks: any[] = getConfig().feedstocks;
  return feedstocks.find(
    (feedstock) =>
      feedstock.plant_id === plant_id &&
      feedstock.type === FEEDSTOCK_TYPES.DISCRETE_COMPOSITION
  ).distribution;
}

export function getDefaultDetailedPiona(
  plant_id: string
): IDetailedPionaComponent[] {
  console.log('in getDefaultDetailedPiona()')
  const feedstocks: any[] = getConfig().feedstocks;
  return feedstocks.find(
    (feedstock) =>
      feedstock.plant_id === plant_id &&
      feedstock.type === FEEDSTOCK_TYPES.DETAILED_PIONA
  ).distribution;
}

export function getFireboxScenarioInputDefinitions(
  scenario_input_id: string,
  furnace_type_id: string,
  feed_type_id: string
): any[] {
  console.log('in getFireboxScenarioInputDefinitions()')
  const inputs = getConfig().scenario_simulator.find(
    (sce) =>
      sce.furnace_type_id === furnace_type_id &&
      sce.feed_type_id === feed_type_id
  ).values;
  return inputs.filter((input) => getHybridFields().includes(input.id));
}

export function getFireboxScenarioCommonInputDefinitions(
  furnace_type_id: string,
  feed_type_id: string
): any[] {
  console.log('in getFireboxScenarioCommonInputDefinitions()')
  const inputs = getConfig().scenario_simulator.find(
    (sce) =>
      sce.furnace_type_id === furnace_type_id &&
      sce.feed_type_id === feed_type_id
  ).values;
  return inputs.filter((input) => !getHybridFields().includes(input.id));
}

export function getDefaultShortPiona(feedstock_type: FEEDSTOCK_TYPES) {
  console.log('in getDefaultShortPiona()')
  const feedstocks: any[] = getConfig().feedstocks;
  return feedstocks.find((feedstock) => feedstock.type === feedstock_type)
    .distribution;
}

export function getDefaultShortPionaMethodDistributions(
  method: ShortPionaMethod = ShortPionaMethod.ASTM
): IMethodDistribution[] {
  console.log('in getDefaultShortPionaMethodDistributions()')
  const feedstocks: any[] = getConfig().feedstocks;
  const feedstockMethods: IMethodDistribution[] = feedstocks
    .find((feedstock) => feedstock.type === FEEDSTOCK_TYPES.SHORT_PIONA)
    .methods.find((mm) => mm.id === method).distribution;
  const updatedFeedstockMethods = feedstockMethods.map((element) => {
    return { ...element, value: 0 };
  });

return updatedFeedstockMethods;
}

export function getFeedstockTypesAvailable(): FEEDSTOCK_TYPES[] {
  console.log('in getFeedstockTypesAvailable()')
  const feedstock_types: FEEDSTOCK_TYPES[] = [];
  const feedstocks: any[] = getConfig().feedstocks;

  feedstocks.forEach((feed) => {
    if (feed.type in FEEDSTOCK_TYPES) {
      feedstock_types.push(feed.type);
    }
  });

  return feedstock_types;
}

export function getConfigFuelGasById(plant_id: string): IComponent[] {
  console.log('in getConfigFuelGasById()')
  return getConfig().fuelgas.find((fuelGas) => fuelGas.plant_id === plant_id)
    .distribution;
}

// export function getConfigFuelGasByPlantId(plant_id: string): IComponent[] {
//     const plant: any[] = getConfig().complex.plants;
//     const fuelGas_id = plant.find(plant => plant.id === plant_id).fuelGas_id;
//     return getConfigFuelGasById(fuelGas_id);
// }

export function getDefaultFuelGasComposition(): IComponent[] {
  console.log('in getDefaultFuelGasComposition()')
  const plant_id = getDefaultPlantReferenceId();
  return getConfig().fuelgas.find(
    (fuelGas) => fuelGas.plant_id === plant_id && fuelGas.default === true
  ).distribution;
}

export function getAssetName(furnace_id): string {
  console.log('in getAssetName()')
  
  let name = "";

  const furnaces: any[] = getConfig().furnaces;
  const furnace_type = furnaces.find((furnace) => furnace.id === furnace_id);
  if (furnace_type !== undefined) {
    name = furnace_type.asset_name;
  }
  return name;
}

export function getFurnaceId(name): string {
  console.log('in getFurnaceId()')
  return getConfig().furnaces.find((furnace) => furnace.name === name).id;
}

export function getFurnaceName(furnace_id): number {
  console.log('in getFurnaceName()')
  return getConfig().furnaces.find((furnace) => furnace.id === furnace_id).name;
}

export function getDefaultFurnaceTypeId(): string {
  console.log('in getDefaultFurnaceTypeId()')
  const plant_id = getDefaultPlantReferenceId();
  const furnace_id = getDefaultFurnaceReferenceId(plant_id);
  return getConfig().furnaces.find((furnace) => furnace.id === furnace_id)
    .furnace_type_id;
}

export function getDefaultFeedTypeId(): string {
  console.log('in getDefaultFeedTypeId()')
  const plant_id = getDefaultPlantReferenceId();
  const furnace_id = getDefaultFurnaceReferenceId(plant_id);
  return getConfig().furnaces.find((furnace) => furnace.id === furnace_id)
    .feed_type_id;
}

export function getCurrentClient() {
  console.log('in getCurrentClient()')
  return getConfig().client ?? "TOBOLSK";
}

export function getFurnaceTypeFeedTypeByFurnaceId(furnace_id: string) {
  console.log('in getFurnaceTypeFeedTypeByFurnaceId()')
  const furnace = getConfig().furnaces.find((furnace) => furnace.id === furnace_id);
  return [furnace.furnace_type_id, furnace.feed_type_id];
}

export function getFeedsbyType(plant_id: string, feed_type_id: string): any[] {
  console.log('in getFeedsbyType()')
  let distribution = getDefaultDiscreteComposition(plant_id);
  let res: string[] = [];
  distribution
    .filter((d) => d.feed_type_id === feed_type_id)
    .map((dd) => res.push(dd.name));
  return res;
}

export function getProductPredictorGroupDetails() {
  console.log('in getProductPredictorGroupDetails()')
  return getConfig().product_predictor.default_settings;
}

export function getProductPredictorGroupDetailLabel(groupname) {
  console.log('in getProductPredictorGroupDetailLabel()')
  return getProductPredictorGroupDetails().filter(
    (x) => x.name.toLowerCase() === groupname.toLowerCase()
  )[0].grouplabel[0];
}

export function getScenarioInputsForProductPredictorDefinations(
  furnace_type_id: string,
  feed_type_id: string
): any[] {
  console.log('in getScenarioInputsForProductPredictorDefinations()')
  let scenarioInputs = getConfig().scenario_simulator.find(
    (x) =>
      x.furnace_type_id === Number(furnace_type_id) &&
      x.feed_type_id === Number(feed_type_id)
  ).values;
  return scenarioInputs.filter((x) =>
    getConfig().product_predictor.scenarioInputs.includes(x.id)
  );
}

export function getDefaultHeavyFeed(feedstock_type: FEEDSTOCK_TYPES) {
  console.log('in getDefaultHeavyFeed()')
  const feedstocks: any[] = getConfig().feedstocks;
  return feedstocks.find((feedstock) => feedstock.type === feedstock_type)
    .distribution;
}

export function getDefaultHeavyFeedStockMethodDistributions(
  method: HeavyFeedMethod = HeavyFeedMethod.ASTM
): IMethodDistribution[] {
  console.log('in getDefaultHeavyFeedStockMethodDistributions()')
  const feedstocks: any[] = getConfig().feedstocks;
  const feedstockMethods: IMethodDistribution[] = feedstocks
    .find((feedstock) => feedstock.type === FEEDSTOCK_TYPES.HEAVY_FEED)
    .methods.find((mm) => mm.id === method).distribution;
  return feedstockMethods;
}