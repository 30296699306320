import React, { useState, useEffect, useRef } from "react";
import { Drawer, List, ListItem, ListItemText, IconButton, Collapse } from '@material-ui/core';
import { Menu } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { RootState } from "../../reducers/rootReducer";
import { HOME_DASHBOARD_ID } from "../parent-dashboard/parentDashboard";
import { CRACKED_GAS_DASHBOARD_ID } from "../cracked-gas/crackedGas";
import { COIL_OUTLET_TEMPERATURE_DASHBOARD_ID } from "../cot/coilOutletTemperature";
import { COIL_TUBE_METAL_TEMPERATURE_DASHBOARD_ID } from "../ctmt/coilTubeMetalTemperature";
import { COIL_INLET_PRESSURE_DASHBOARD_ID } from "../cip/coilInletPressure";
import { COIL_DYNAMICS_DASHBOARD_ID } from "../coil-dynamics/coilDynamics";
import store from "../..";
import { FEEDSTOCK_OVERVIEW_DASHBOARD_ID } from "../feedstocks/feedstockOverview";
import { SCENARIO_SIMULATOR_DASHBOARD_ID } from "../scenarioSimulator/scenarioSimulatorOverview";
import { FUELGAS_OVERVIEW_DASHBOARD_ID } from "../fuelgases/fuelGasOverview";
import { FURNACE_OVERVIEW_STATIC_ID } from "../furnace-overview/furnaceOverview";
import isDeepEqual from 'fast-deep-equal/react'
import { CONVECTION_SECTION_DASHBOARD_ID } from "../convectionsection/convectionsectionOverview";
import { PRODUCT_PREDICTOR_DASHBOARD_ID } from "../product-predictor/productPredictor";
import { USER_ROLES_DASHBOARD_ID } from "../admin-panel/roles/UserRolesOverview";
import { THERMAL_IMAGE_OVERVIEW_DASHBOARD_ID } from "../thermalImage/thermalImageOverview";
import { MULTI_CAMERA_DASHBOARD_ID } from "../multiple-camera/multiCamera";
import { CAMERA_HEALTH_DASHBOARD } from "../camera/cameraHealthDashboard";
import { CAMERA_STATUS_DASHBOARD } from "../camera/cameraStatus";
import { NOTIFICATION_CONFIGURATION_ID } from "../notification-configuration/notificationConfiguration";
import { NOTIFICATION_LOG_DASHBOARD } from "../notification-log/notificationLog";

const DrawerMenu: React.SFC = (props: any, context: any) => {

  const { features, accessibleFeatures } = useSelector((state: RootState) => state.features)

  const getFilteredMenuList = (menuItemsList) => {

    let tempItemList: any = [];
    for (let menuItem in menuItemsList) {
        if (menuItemsList[menuItem].child_dashboards === undefined) {
          if (visible_dashboards.includes(menuItemsList[menuItem].dashboard_id)) {
            tempItemList.push(menuItemsList[menuItem]);
          }
        }
        else {
          let childList = getFilteredMenuList(menuItemsList[menuItem].child_dashboards);
          if (childList.length > 0) {
            menuItemsList[menuItem].child_dashboards = childList;
            tempItemList.push(menuItemsList[menuItem]);
          }
        }
    }
    return tempItemList;
  }

  const getClientFeatures = () => { return features ? features.map(x => "_" + x) : [] }
  const getClientAccessibleFeatures = () => { return accessibleFeatures ? accessibleFeatures.map(x => "_" + x) : [] }
  let menuItemsList = [
    {
      id: 0,
      path: "/",
      name: "Home",
      dashboard_id: HOME_DASHBOARD_ID
    },
    {
      id: 2,
      path: "/furnace-overview",
      name: "Furnace Overview",
      dashboard_id: FURNACE_OVERVIEW_STATIC_ID
    },
    {
      id: 4,
      path: "/feedstock-overview",
      name: "Feedstock",
      dashboard_id: FEEDSTOCK_OVERVIEW_DASHBOARD_ID
    },
    {
      id: 5,
      path: "/fuelgas-overview",
      name: "FuelGas",
      dashboard_id: FUELGAS_OVERVIEW_DASHBOARD_ID
    },
    {
      id: 6,
      path: "/convection-section",
      name: "Convection Section",
      dashboard_id: CONVECTION_SECTION_DASHBOARD_ID
    },
    {
      id: 1,
      path: "/scenario-simulator",
      name: "Scenario Simulator",
      dashboard_id: SCENARIO_SIMULATOR_DASHBOARD_ID
    },
    {
      id: 13,
      path: "/product-predictor",
      name: "Product Predictor",
      dashboard_id: PRODUCT_PREDICTOR_DASHBOARD_ID
    },
    {
      id: 14,
      path: "/thermalimage",
      name: "Thermal Image",
      dashboard_id: THERMAL_IMAGE_OVERVIEW_DASHBOARD_ID
    },
    {
      id: 8,
      path: "/cracked-gas",
      name: "Cracked Gas",
      feedstockFirst: true,
      dashboard_id: CRACKED_GAS_DASHBOARD_ID
    },
    {
      id: 9,
      path: "/cot",
      name: "Coil Outlet Temperature",
      feedstockFirst: true,
      dashboard_id: COIL_OUTLET_TEMPERATURE_DASHBOARD_ID
    },
    {
      id: 10,
      path: "/ctmt",
      name: "Coil Tube Metal Temperature",
      feedstockFirst: true,
      dashboard_id: COIL_TUBE_METAL_TEMPERATURE_DASHBOARD_ID
    },
    {
      id: 11,
      path: "/cip",
      name: "Coil Inlet Pressure",
      feedstockFirst: true,
      dashboard_id: COIL_INLET_PRESSURE_DASHBOARD_ID
    },
    {
      id: 12,
      path: "/coil-dynamics",
      name: "Coil Dynamics",
      feedstockFirst: true,
      dashboard_id: COIL_DYNAMICS_DASHBOARD_ID
    },
    {
      id: 15,
      path: "/camera-health",
      name: "Thermal Camera Health",
      feedstockFirst: true,
      dashboard_id: CAMERA_HEALTH_DASHBOARD
    },
    {
      id: 16,
      path: "/camera-status",
      name: "Thermal Camera Status",
      feedstockFirst: true,
      dashboard_id: CAMERA_STATUS_DASHBOARD
    },
    {
      id: 17,
      path: "/multi-camera",
      name: "Multi Camera",
      dashboard_id: MULTI_CAMERA_DASHBOARD_ID
    },
    {
      id: 18,
      path: "/configuration",
      name: "Configuration",
      dashboard_id: NOTIFICATION_CONFIGURATION_ID
    },{
      id: 21,
      path: "/notification-log",
      name: "Notification Log",
      dashboard_id: NOTIFICATION_LOG_DASHBOARD
    },
    {
      id: 19,
      path: "/admin-panel",
      name: "Admin Panel",
      child_dashboards: [
        {
          id: 20,
          path: "/user-roles",
          name: "User Roles",
          dashboard_id: USER_ROLES_DASHBOARD_ID,
        }
      ]
    }
  ];

  const visible_dashboards = getClientFeatures();
  // let menuItems = menuItemsList.filter((menuItem) => visible_dashboards.includes(menuItem.dashboard_id) && menuItem.dashboard_id !== CAMERA_HEALTH_DASHBOARD);
  let menuItems = getFilteredMenuList(menuItemsList);
  const [opened, setOpened] = useState(false);
  const [collapseChildMenu, setCollapseChildMenu] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();


  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {

      return;
    }
    setOpened(open);
  };

  const menuItemRef = useRef(menuItems);
  if (!isDeepEqual(menuItemRef.current, menuItems)) {
    menuItemRef.current = menuItems
  }
  /**
   * Update page title on change of URL of the page
   */
  useEffect(() => {

    findPageName(menuItemRef.current);
 
    // 3180: Developement changes 
    // let pageName : any = ""
    // //const pageName = menuItemRef.current.find((item) => item.path === location.pathname)?.name;
    // menuItemRef.current.forEach(menuItemRefVal => {
    //   if(location.pathname.includes(menuItemRefVal.path))
    //   {
    //     pageName = menuItemRefVal.name
    //   }
    //   if(location.pathname.includes('/camera-status'))
    //   {
    //     pageName = menuItemsList.find((element) => element.path === '/camera-status')?.name;
    //   }
    //   if(location.pathname.includes('/camera-health'))
    //   {
    //     pageName = menuItemsList.find((element) => element.path === '/camera-health')?.name;
    //   }
    // });
    // if (pageName !== undefined) {
    //   dispatch({ type: "UPDATE_PAGE_TITLE", payload: { value: pageName } });
    //   setCurrentPage(menuItemRef.current.findIndex((item) => item.name === pageName));
    // }

  }, [dispatch, location.pathname, menuItemRef]);
  const accessibleFeaturesList = getClientAccessibleFeatures();

  const findPageName = (dataObj) => {

    for (let obj in dataObj) {
      if (dataObj[obj].path === location.pathname && dataObj[obj].child_dashboards === undefined) {
        dispatch({ type: "UPDATE_PAGE_TITLE", payload: { value: dataObj[obj].name } });
        setCurrentPage(dataObj[obj].id);
        return;
      }
      else if (dataObj[obj].path !== location.pathname && dataObj[obj].child_dashboards !== undefined) {
        findPageName(dataObj[obj].child_dashboards);
      }
    }
    return;

  }

  const openChildMenu = (key) => {
    setCollapseChildMenu({ ...collapseChildMenu, [key]: !collapseChildMenu[key] })
  }

  let accessibleChildListID : number[] = [];

  const checkAccessibleFeatureChild = (childList) => {

    childList.filter(list => {
      return accessibleFeaturesList.includes(list.dashboard_id)
    }).map((x) => {
      accessibleChildListID.push(x.id)
    })

    if(accessibleChildListID.length > 0)
      return false;
    else
      return true;
  }

  // const menuEnable = accessibleFeatures.includes('RTA') ? true : store.getState().scenarioSimulator.scenarioRunlengths.find(runlength => runlength.scenarios.find(scenario => scenario.firebox.find(firebox => firebox.CALCULATIONS.length > 0)));
  const checkForThermalImaging: boolean = !((accessibleFeatures.includes("THERMAL_IMAGE") && accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE")) || (!accessibleFeatures.includes("THERMAL_IMAGE") && !accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE")))

  const menuEnable = (features.includes('RTA') || features.includes('THERMAL_IMAGE')) ? true : store.getState().scenarioSimulator.scenarioRunlengths.find(runlength => runlength.scenarios.find(scenario => scenario.firebox.find(firebox => firebox.CALCULATIONS.length > 0)));
  const list = () => (

    <div role="presentation">
      <List id="drawer-menu">
        {menuItems.map((item, i) => {
          if(item.id !== 16 && item.id !== 17 && item.id !== 15) {
            const open = collapseChildMenu[item.id] || false;
            return (
              <>
                {item.child_dashboards !== undefined ?
                  <>
                    <ListItem button onClick={() => openChildMenu(item.id)} className={`drawer-menu-item ${(item.feedstockFirst || checkAccessibleFeatureChild(item.child_dashboards)) && "is-disabled"}`}>
                      <ListItemText primary={item.name} className="drawer-menu-item-text" />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List onClick={toggleDrawer(false)} component="div" disablePadding>
                        {item.child_dashboards.map((childItem) => (
                          <>
                            <NavLink to={item.feedstockFirst && !menuEnable ? "/feedstock" : childItem.path} className="drawer-menu-item" key={item.id}>
                              <ListItem key={childItem.id} button className={currentPage === childItem.id ? "is-active" : "" && `${!(accessibleChildListID.includes(childItem.id)) && "is-disabled"}`}>
                                <ListItemText inset primary={childItem.name}/>
                                <ChevronRightIcon className={`${item.feedstockFirst && !menuEnable}`} />
                              </ListItem>
                            </NavLink>
                          </>
                        ))}
                      </List>
                    </Collapse>
                  </>
                  : <NavLink to={item.feedstockFirst && !menuEnable ? "/feedstock" : item.path} className={`drawer-menu-item ${((item.feedstockFirst && !menuEnable) || !accessibleFeaturesList.includes(item.dashboard_id) || (item.dashboard_id === "_COIL_TUBE_METAL_TEMPERATURE" ? checkForThermalImaging : false)) && "is-disabled"}`} key={item.id}>
                    <ListItem button onClick={toggleDrawer(false)} className={currentPage === item.id ? "is-active" : ""}>
                      <ListItemText primary={item.name} className="drawer-menu-item-text" />
  
                      <ChevronRightIcon />
                    </ListItem>
                  </NavLink>}
              </>
  
            )
          }

        })
        }
      </List >
    </div >

  );

  return (
    <React.Fragment>
      <IconButton aria-label="menu" color="inherit" edge="start" onClick={toggleDrawer(true)}>
        <Menu className="drawer-menu-icon" />
      </IconButton>
      <Drawer open={opened} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </React.Fragment>
  );
};

export default DrawerMenu;
