import React, { Component } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IFuelGas } from "../../interfaces/IFuelGas";
import { RootState } from "../../reducers/rootReducer";
import { getFuelGasById, saveNewFuelGas } from "../../services/fuelgasServices";
import { modifyFuelGas, setCurrentFuelGasId, setNewSelectedFuelGasId, updateFualGasSignature } from "../../slices/fuelGasSlice";
import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import { updateModifiedFuelGasInDbForScenario } from "../../slices/scenarioSimulatorSlice";
import { updateModifiedFuelGasInPP } from "../../slices/productPredictorSlice";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface ISaveFuelGasProps extends RouteComponentProps {
    fuelGas: IFuelGas;
}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        fuelGases: state.fuelGas.fuelGases,
        new_selected_fuelGas_id: state.fuelGas.new_selected_fuelGas_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSignature: (fuelgas_id: string) => dispatch(updateFualGasSignature({ fuelgas_id: fuelgas_id })),
        modifyFuelGas: (newFuelGas: IFuelGas) => dispatch(modifyFuelGas(newFuelGas)),
        setCurrentFuelGasId: (newFuelGasId: string) => dispatch(setCurrentFuelGasId(newFuelGasId)),
        setNewSelectedFuelGasId: (newFeedstockId: string) => dispatch(setNewSelectedFuelGasId(newFeedstockId)),
        updateModifiedFuelGasInDbForScenario: (fuelGas_id: string) => dispatch(updateModifiedFuelGasInDbForScenario(fuelGas_id)),
        updateModifiedFuelGasInPP:(fuelGas_id: string) => dispatch(updateModifiedFuelGasInPP(fuelGas_id))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ISaveFuelGasReduxProps = PropsFromRedux & ISaveFuelGasProps & OIDCAuth;

type ISaveFuelGasState = {
    showDialog: boolean;
    name: string;
    message: string;
    location: string;
    signature: string;
    fuelGas: IFuelGas;
    showLoading: boolean;
}

class SaveFuelGas extends Component<ISaveFuelGasReduxProps, ISaveFuelGasState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: "",
            message: "",
            location: "",
            signature: !!this.props.fuelGas.signature ? this.props.fuelGas.signature : "",
            fuelGas: this.props.fuelGas,
            showLoading: false,
        }
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            name: "",
            message: ""
        })
    }

    componentDidMount() {
        // TO BE USED IN FEATURE 
        // if (this.props.fuelGas.is_display_leave_popup) {
        //     const newFuelGas: IFuelGas = {
        //         ...this.props.fuelGas,
        //         is_display_leave_popup: false
        //     }
        //     this.props.modifyFuelGas(newFuelGas)
        // }
    }

    async handleSaveNewFuelGas() {
        if (this.props.fuelGas.signature !== "") {
            this.setState({
                showLoading: true,
            })
            try {
                const accessToken = getToken(this.props.auth);
                let save = await saveNewFuelGas(this.props.fuelGas, accessToken);
                if (save.status) {
                    let getNewSavedUpdatedById = await getFuelGasById(this.props.fuelGas.id, accessToken);
                    if (getNewSavedUpdatedById.status) {
                        this.setState({
                            message: save.message,
                            signature: !!this.props.fuelGas.signature ? this.props.fuelGas.signature : "",
                            showLoading: false,
                        })
                        let fuelGas = getNewSavedUpdatedById.data[0] as IFuelGas;
                        const newFuelGas: IFuelGas = {
                            ...fuelGas,
                            is_display_leave_popup: false,
                            is_saved: true,
                        }

                        this.props.modifyFuelGas(newFuelGas)
                        if (!this.props.fuelGas.id.startsWith("_DEFAULT")) {
                            this.props.updateModifiedFuelGasInDbForScenario(newFuelGas.id)
                            this.props.updateModifiedFuelGasInPP(newFuelGas.id)
                        }

                        setTimeout(() => {
                            this.toggleShowDialog();
                            if (!!this.state.location && this.state.location !== "") {
                                this.props.history.push(this.state.location)
                            }
                        }, 0);
                    } else {
                        this.setState({
                            message: "Oops... somthing went to wrong!",
                            showLoading: false,
                        })
                    }
                } else {
                    this.setState({
                        message: "Oops... somthing went to wrong!",
                        showLoading: false,
                    })
                }
            } catch (error) {
                this.setState({
                    message: "Oops... somthing went to wrong!",
                    showLoading: false,
                })
            }
        } else {
            this.setState({
                message: "Please Add component value in fuelGas!",
                showLoading: false,
            })
        }

    }

    handleTransition = location => {
        if (this.props.fuelGas.signature !== this.state.signature && !this.props.fuelGas.id.startsWith("_DEFAULT")) {
            this.setState({
                location: location.pathname + location.search
            })
            this.toggleShowDialog();
            return false
        } else {
            return true
        }
    };

    handleCancelFuelGasRedirection() {
        this.toggleShowDialog();
        setTimeout(() => {
            this.setState({
                location: "",
            })
            if (this.props.fuelGas.is_display_leave_popup) {
                const newFuelGas: IFuelGas = {
                    ...this.props.fuelGas,
                }

                this.props.modifyFuelGas(newFuelGas)
            }
        }, 0);
    }

    async handleLeaveFuelGas() {
        const accessToken = getToken(this.props.auth);
        this.toggleShowDialog();
        let getNewSavedUpdatedById = await getFuelGasById(this.props.fuelGas.id, accessToken);
        let fuelGas = getNewSavedUpdatedById.data.length > 0 ? getNewSavedUpdatedById.data[0] as IFuelGas : this.state.fuelGas;
        const newFuelGas: IFuelGas = {
            ...fuelGas,
            is_display_leave_popup: false,
            is_saved: true
        }
        await this.props.modifyFuelGas(newFuelGas)
        this.setState({
            signature: !!this.props.fuelGas.signature ? this.props.fuelGas.signature : "",
            fuelGas: this.props.fuelGas
        })
        if (this.props.new_selected_fuelGas_id !== "") {
            await this.props.setCurrentFuelGasId(this.props.new_selected_fuelGas_id)
            setTimeout(async () => {
                this.props.setNewSelectedFuelGasId("");
            }, 10);
        }
        setTimeout(() => {
            if (!!this.state.location && this.state.location !== "") {
                this.props.history.push(this.state.location)
            }
        }, 0);
    }

    hendalClickOnSavePopup() {
        if (this.props.fuelGas.is_display_leave_popup) {
            const newFuelGas: IFuelGas = {
                ...this.props.fuelGas,
                is_display_leave_popup: false,
            }
            this.props.modifyFuelGas(newFuelGas)
        }
        this.toggleShowDialog();
    }

    hendalClickOnCancelbButtonInSavePopup() {
        if (this.state.signature !== this.props.fuelGas.signature) {
            const newFuelGas: IFuelGas = {
                ...this.props.fuelGas,
                is_display_leave_popup: true,
            }
            this.props.modifyFuelGas(newFuelGas)
        }
        this.toggleShowDialog();
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    id="saveButton"
                    className={this.props.fuelGas.is_display_leave_popup === true ? "button red" : "button"}
                    onClick={() => this.hendalClickOnSavePopup()}
                    disabled={!!this.props.fuelGas && this.props.fuelGas.id.startsWith("_DEFAULT")}
                >
                    Save
                </button>
                <button
                    id="saveFuelgasButtonHidden"
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => this.toggleShowDialog()}
                ></button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    {this.props.fuelGas.is_display_leave_popup === false && (
                        <>
                            <DialogTitle id="form-dialog-title">Save this fuelGas</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to save this fuelGas?
                                </DialogContentText>
                                {!!this.state.message && this.state.message !== "" && (
                                    <Typography variant="body1" component="p" color="secondary">
                                        {this.state.message}
                                    </Typography>
                                )}
                            </DialogContent>


                            <DialogActions>
                                <button className="button" onClick={() => this.hendalClickOnCancelbButtonInSavePopup()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveNewFuelGas()} disabled={this.state.showLoading}>
                                    Save {this.state.showLoading === true && (
                                        <Fade
                                            in={this.state.showLoading}
                                            style={{
                                                transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress size={11} />
                                        </Fade>
                                    )}
                                </button>
                            </DialogActions>
                        </>
                    )}
                    {this.props.fuelGas.is_display_leave_popup === true && (
                        <>
                            <DialogTitle id="form-dialog-title1">Leave Page</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Do you want to save ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className="button" onClick={() => this.handleCancelFuelGasRedirection()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveNewFuelGas()} >
                                    Yes
                                </button>
                                <button className="button" onClick={() => this.handleLeaveFuelGas()} >
                                    No
                                </button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
                <Prompt when={this.props.fuelGas.is_display_leave_popup} message={this.handleTransition} />
            </div >
        );
    }
};

export default withAuth(connector(withRouter(SaveFuelGas)));