import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAssetName, getDefaultFurnaceReferenceId, getDefaultPlantReferenceId } from "../configuration";

export type IFurnaceSlice = {
    furnaces: Array<any>,
    current_furnace_id: string,
    current_asset_name: string,
    furnace_metrics: Array<any>,
    furnace_op_mode: string,
    furnace_total_runlength: any
}

const initialState: IFurnaceSlice = {
    furnaces: [],
    current_furnace_id: '',
    current_asset_name: '',
    furnace_metrics:[],
    furnace_op_mode:'',
    furnace_total_runlength: []
}

export const furnaceSlice = createSlice({
    name: 'furnaceSlice',
    initialState: initialState,
    reducers: {
        setFurnaceId: (state, action: PayloadAction<string>) => {
            state.current_furnace_id = action.payload;
        },
        setAssetName: (state, action: PayloadAction<string>) => {
            state.current_asset_name = action.payload;
        },
        updateFurnaces: (state, action: PayloadAction<any[]>) => {
            state.furnaces = action.payload;
        }, resetFurnace: (state) => {
            state.furnaces = initialState.furnaces;
            state.current_asset_name = '';
            state.current_furnace_id = initialState.current_furnace_id;
        },
        setFurnaceMetrics: (state, action: PayloadAction<any[]>) => {
            state.furnace_metrics = action.payload
        },
        setFurnaceOpMode: (state, action: PayloadAction<string>) => {
            state.furnace_op_mode = action.payload
        },
        setFurnaceTotalRunLength: (state, action: PayloadAction<any>) => {
            state.furnace_total_runlength = action.payload
        }
    }
})

export const {
    setFurnaceId,
    setAssetName,
    updateFurnaces,
    resetFurnace,
    setFurnaceMetrics,
    setFurnaceOpMode,
    setFurnaceTotalRunLength

} = furnaceSlice.actions


export default furnaceSlice.reducer
