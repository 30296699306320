import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../reducers/rootReducer";
// import config from "../../assets/customer_specific_data/plant_config.json";
import { getFurnaceById, getSelectedCameraDetails } from '../../configuration';
import { Box, Card, Divider, Paper, IconButton, Tooltip as MaterialUITooltip, Grid } from "@material-ui/core";
import { Info } from '@material-ui/icons';
import { REACT_APP_APIM_URL } from "../../utilities/GlobalConstants";
import store from "../..";
import Loader from "react-spinners/ClipLoader";
import { TI_API_CALL_INTERVAL } from "../../utilities/GlobalConstants";
import { setCurrentCamera} from '../../slices/cameraSlice'
import { getToken,getFormattedDateTime } from "../../utilities/helperFunctions";

export const CAMERA_HEALTH_DASHBOARD = "_CAMERA_HEALTH";
const mapStateToProps = (state: RootState) => {
    return {
        token: state.authState.token,
        furnaces: state.furnace
    };
};
// write to redux
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentCamera: (name: string) => dispatch(setCurrentCamera(name))
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CameraHealthStatus = {
    CameraId: string,
    CameraTemperature: string,
    Location: string,
    ConnectionStatus: string,
    DateTime: string,
    ThermocoupleAlarmStatus: string
};

type cameraNotificationData = {
    furnace_id: string,
    camera_id: number,
    camera_name: string,
    Location: string,
    connection_status: string,
    camera_temperature: string,
    timestamp: string,
    DateTime: string
};

type MyState = {
    interval: number;
    camHealth: CameraHealthStatus[],
    loading: boolean,
    furnaceId: number,
    cameraNotificationData: cameraNotificationData[]
};

/**
 * Class component for initial dashboard with furnaces overview
 */
class CameraHealth extends Component<any, MyState> {

    interval;
    constructor(props) {
        super(props);
        this.state = { interval: 0, camHealth: [], loading: false, furnaceId: 0, cameraNotificationData: [] }
    }

    async componentDidMount() {
        const furnaceId = this.props.furnaces.current_furnace_id;
        this.setState({ furnaceId: parseInt(furnaceId) })
        this.fetchHealthDetails(furnaceId);

        this.interval = setInterval(() => {
            this.fetchHealthDetails(furnaceId);
        }, Number(TI_API_CALL_INTERVAL))
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    
    redirectToCameraStatus = (cameraName) => {
        this.props.setCurrentCamera(cameraName);
    }

    fetchHealthDetails = (furnaceId) => {
        this.setState({ loading: true })
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL}/thermalimage/fetchCameraNotificationDetails/${furnaceId}`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    this.setState({ cameraNotificationData: data })
                    this.setState({ loading: false })
                },
                (error) => {
                    console.log("error", error)
                    this.setState({ loading: false })
                }
            )
    }

    getDateTime(time) {
        var date = new Date(parseInt(time));
        return getFormattedDateTime(date); 
    }

    render() {
        let selectedFurnace = getFurnaceById(this.state.furnaceId);
        return (
            <div>
                <Paper elevation={3} className='controlPanelTI multi-camera-panel'>
                    <div className="control-header element tmtgen tmtgen-holder">
                        <div className="container-typography tmtgen">
                            <Grid item direction="row" justifyContent="flex-start" xs={12} lg={12} xl={12} className="gen_display">
                                <b>Furnace</b> : {selectedFurnace[0]?.name}
                            </Grid>
                            <Grid>
                                <div className="legend-holder">
                                    <div className="legend legend-red common-legend"></div>
                                    <span className="legend-text">Camera Status Deviation</span>
                                    <div className="legend legend-green common-legend"></div>
                                    <span className="legend-text">Camera Status Normal</span>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </Paper>
                {selectedFurnace.map((furnace) =>
                    <div className="camera-health-dashboard" >
                        <div className="camera-furnace">

                            {this.state.loading ?
                                <div className="camera-loader-grid-parent">
                                    <div className="loader-grid">
                                        <Loader />
                                        <p> Loading...</p>
                                    </div>
                                </div>
                                :
                                <div className="furnace-container">
                                    {furnace.hasOwnProperty("camera_ids") && furnace.camera_ids && furnace?.camera_ids?.map((cam_id) => {
                                        const camera = getSelectedCameraDetails(cam_id);
                                        const healthDetail = this.state.cameraNotificationData.filter(health => health.camera_name === camera?.name)
                                        const cameraNotificationDetails = this.state.cameraNotificationData.filter(notificationDetail => notificationDetail.camera_id === cam_id)

                                        let cameraNotificationColor = "legend-green"
                                        if (cameraNotificationDetails[0]["severity"] === 1) {
                                            cameraNotificationColor = "legend-red"
                                        }

                                        return (healthDetail && healthDetail.length > 0 && healthDetail[0] && camera &&
                                            <Card elevation={2} className="cam-detail-card" variant="outlined">
                                                <Box sx={{ p: 2 }}>
                                                    <div className="furnacesCameraFont">{camera.display_name} - {healthDetail[0].camera_name}</div>
                                                    <br />
                                                    <div className="cam-container">
                                                        <div className="cam-content">
                                                            <div>Location : {healthDetail[0].Location}<MaterialUITooltip className='materialUITooltip' title={<span className='TooltipTitle'>Camera Location</span>}>
                                                                <IconButton>
                                                                    <Info />
                                                                </IconButton>
                                                            </MaterialUITooltip></div>
                                                            <div>Status : {healthDetail[0].connection_status}</div>
                                                        </div>
                                                        <div>
                                                            <div>Camera Temperature : {healthDetail[0].camera_temperature}</div>
                                                            <div>Last Image Date / Time : {this.getDateTime(healthDetail[0].DateTime)}</div>
                                                        </div>
                                                    </div>
                                                </Box>
                                                <Divider />
                                                <div className="camStatus">
                                                    <div className="alarmStatus"><div className={"legend " + cameraNotificationColor}></div></div>
                                                    <div className="detailed-status-btn"><Link className="detailedStatus" onClick={() => this.redirectToCameraStatus(camera.name)} to={'/camera-status/'}>Detailed Status {'>'}</Link></div>
                                                </div>
                                            </Card>
                                        )
                                    }

                                    )}
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default connector(CameraHealth);
