import Grid from "@material-ui/core/Grid";
import equal from 'fast-deep-equal';
import React, { Component } from "react";
import { AzureAD } from "react-aad-msal";
import { connect, ConnectedProps } from "react-redux";
import { Route, Switch } from "react-router-dom";
import store from "..";
import { authProvider } from "../components/authProvider";
import ConvectionsectionOverview from "../components/convectionsection/convectionsectionOverview";
import FeedstockOverview, { FEEDSTOCK_OVERVIEW_DASHBOARD_ID } from "../components/feedstocks/feedstockOverview";
import FuelGasOverview, { FUELGAS_OVERVIEW_DASHBOARD_ID } from "../components/fuelgases/fuelGasOverview";
import FurnaceOverview, { FURNACE_OVERVIEW_STATIC_ID } from "../components/furnace-overview/furnaceOverview";
import FurnaceOverviewDahej from "../components/furnace-overview/furnaceOverview_Dahej";
import FurnaceOverviewScholven from "../components/furnace-overview/furnaceOverview_Scholven";
import FurnaceOverviewTisza from "../components/furnace-overview/furnaceOverview_Tisza";
import ScenarioSimulatorOverview, { SCENARIO_SIMULATOR_DASHBOARD_ID } from "../components/scenarioSimulator/scenarioSimulatorOverview";
import Footer from "../components/supplementary/footer";
import Header from "../components/supplementary/header";
import { getCurrentClient } from "../configuration";
import { CALCULATION_STATUS, SCENARIO_STATUS } from "../interfaces/IScenario";
import { RootState } from "../reducers/rootReducer";
import { loadFeatures } from "../slices/featuresSlice";
import { setStatusScenarioInRunlength, updateCalculation, updateRTAScenarioRunlength } from "../slices/scenarioSimulatorSlice";
import { REACT_APP_APIM_URL_METADATA } from "../utilities/GlobalConstants";
import { checkPortalPermission, getRTALiveData, getRTATilldateData, RTATimeFrequency, isPingIDAuth, getToken } from "../utilities/helperFunctions";
import { restartSimulation, scenarioSimulation } from "../utilities/scenarioSimulationCalculator";
import CIP, { COIL_INLET_PRESSURE_DASHBOARD_ID } from "./cip/coilInletPressure";
import CoilDynamics, { COIL_DYNAMICS_DASHBOARD_ID } from "./coil-dynamics/coilDynamics";
import { CONVECTION_SECTION_DASHBOARD_ID } from "./convectionsection/convectionsectionOverview";
import COT, { COIL_OUTLET_TEMPERATURE_DASHBOARD_ID } from "./cot/coilOutletTemperature";
import CrackedGas, { CRACKED_GAS_DASHBOARD_ID } from "./cracked-gas/crackedGas";
import CTMT, { COIL_TUBE_METAL_TEMPERATURE_DASHBOARD_ID } from "./ctmt/coilTubeMetalTemperature";
import FurnaceOverviewRuwais from "../components/furnace-overview/furnaceOverview_Ruwais";
import Login from "./login/login";
import ParentDashboard, { HOME_DASHBOARD_ID } from "./parent-dashboard/parentDashboard";
import ProductPredictor, { PRODUCT_PREDICTOR_DASHBOARD_ID } from "./product-predictor/productPredictor";
import { LegalNotice } from "./supplementary/LeagleNotice";
import { PrivacyPolicy } from "./supplementary/PrivacyPolicy";
import { TermCondition } from "./supplementary/TermCondition";
import { UserGuide } from "./supplementary/UserGuide";
import Loader from "react-spinners/ClipLoader";
import UserRolesOverview, { USER_ROLES_DASHBOARD_ID } from "./admin-panel/roles/UserRolesOverview";
import ThermalImageOverview, { THERMAL_IMAGE_OVERVIEW_DASHBOARD_ID } from "./thermalImage/thermalImageOverview";
import MultiCamera, { MULTI_CAMERA_DASHBOARD_ID } from "./multiple-camera/multiCamera";
import NotificationConfiguration, { NOTIFICATION_CONFIGURATION_ID } from "./notification-configuration/notificationConfiguration";
import CameraHealth, { CAMERA_HEALTH_DASHBOARD } from "./camera/cameraHealthDashboard";

import CameraStatus, { CAMERA_STATUS_DASHBOARD } from "./camera/cameraStatus";
import { AuthContextProps, withAuth } from "react-oidc-context";

import NotificationLog, { NOTIFICATION_LOG_DASHBOARD } from "./notification-log/notificationLog"
import ServiceHealthStatus from "./service-health-status/serviceHealthStatus";
interface AuthProps {
  auth: AuthContextProps
}


const mapStateToProps = (state: RootState) => {
  return {
    userRoles: state.adminPanel.currentUserRoleState.role || "",
    scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
    currentPlantId: state.scenarioSimulator.currentPlantId,
    fuelGases: state.fuelGas.fuelGases,
    token: state.authState.token,
    accessibleFeatures: state.features.accessibleFeatures,
    furnaceState: state.furnace,
    config: state.configuration
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadFeatures: (newFeature: Array<string>, accessibleFeatures: Array<string>) => dispatch(loadFeatures({ features: newFeature, accessibleFeatures: accessibleFeatures })),
    updateCalculation: (newRunLengthId: string, scenarioId: string, data: any, time: any, day: any) => dispatch(updateCalculation({ runlength_id: newRunLengthId, scenarioId: scenarioId, data: data, time: time, day: day })),
    updateRTAScenarioRunlength: (runlengthId: string, scenarioId: string, data: any, timeline: any, days: any, fireboxIndex: number) => dispatch(updateRTAScenarioRunlength({ runlengthId: runlengthId, scenarioId: scenarioId, data: data, timeline: timeline, days: days, fireboxIndex: fireboxIndex }))
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MyProps = PropsFromRedux & AuthProps;

/**
 * Initial component:
 * - check if user is logged in and has permissions for access
 * - routing of the views or to login screen
 */
class Start extends Component<MyProps> {

  constructor(props) {
    super(props);
    window.onbeforeunload = function (event) {
      store.getState().scenarioSimulator.scenarioRunlengths.forEach(runlength => {
        runlength.scenarios.forEach(scenario => {
          if (scenario.status === SCENARIO_STATUS.RUNNING) {
            store.dispatch(setStatusScenarioInRunlength({ runlength_id: runlength.id, scenario_id: scenario.id, status: SCENARIO_STATUS.RESTORE }));
          }
        })
      })
    };
    setInterval(() => this.resumeRTA(), 60000 * RTATimeFrequency());
    this.resumeCalculations();
  }

  /* // TO BE USED IN FUTURE
  addDefaultFuelGas() {
    const plants = getPlantReferences();
    plants.forEach(plant => {
      const newFuelGas: IFuelGas = {
        id: "_DEFAULT_" + plant.name,
        name: "DEFAULT_" + plant.name,
        unit: UNIT.MOL,
        components: getDefaultFuelGasComposition(),
        status: FUELGAS_STATUS.UNCOMPUTED,
        error_message: "",
        O2BridgeWall: -1,
        Pressure: -1,
        Temperature: -1,
        signature: ""
      };
      if (this.props.defaultFuelGas.find(fuelGas => fuelGas.id === newFuelGas.id) === undefined) {
        // this.props.addFuelGas(newFuelGas);  // TO BE USED IN FUTURE
        this.props.addDefaultFuelGas(newFuelGas);
      }
    });
  } */

  /**
   * get user features by user role and plant and store into redux
   */
  getFeature = async (role, plant) => {
    const accessToken = getToken(this.props.auth);
    fetch(`${REACT_APP_APIM_URL_METADATA}/features/` + role + `/` + plant, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            features: data,
          });

          // store in redux
          this.props.loadFeatures(data.Features, data.AccessibleFeatures);

          return data;
        },
        (error) => console.log("error", error)
      );
  };
  resumeCalculations() {
    const fuelGases = store.getState().fuelGas.fuelGases;
    const feedstocks = store.getState().feedstock.feedstocks;
    const convectionsections = store.getState().convectionsection.convectionsections;
    const token = getToken(this.props.auth);
    store.getState().scenarioSimulator.scenarioRunlengths.forEach(runlength => {
      runlength.scenarios.forEach(async scenario => {
        if (scenario.status === SCENARIO_STATUS.RESTORE) {
          store.dispatch(setStatusScenarioInRunlength({ runlength_id: runlength.id, scenario_id: scenario.id, status: SCENARIO_STATUS.RUNNING }));
          switch (scenario.calculation_status) {
            case (CALCULATION_STATUS.INIT):
              await scenarioSimulation(scenario, feedstocks, fuelGases, convectionsections, runlength.id, token);
              break;
            case (CALCULATION_STATUS.LOOP):
              await restartSimulation(scenario, runlength.id, token);
          }
        }
      })
    })
  }

  resumeRTA() {
    store.getState().scenarioSimulator.scenarioRunlengths.forEach(runlength => {
      runlength.scenarios.forEach(async scenario => {
        if (scenario.currentScenarioIsRTA) {
          const today = new Date();
          let date: any = scenario.modifiedDateTime?.toString() ?? new Date();
          const endDate = new Date(date)
          const minute = Math.abs(today.getTime() - endDate.getTime()) / (1000 * 60) % 60;
          store.dispatch(setStatusScenarioInRunlength({ runlength_id: runlength.id, scenario_id: scenario.id, status: SCENARIO_STATUS.RUNNING }));
          switch (scenario.calculation_status) {
            case (CALCULATION_STATUS.LOOP):
              if (minute > (RTATimeFrequency() + 1)) // refresh whole data
              {
                const firebox = store.getState().scenarioSimulator.currentScenarioFireboxId === 0 ? "A" : "B";
                const furnace = store.getState().furnace.current_furnace_id + "." + firebox;
                const furnaceTypeId = store.getState().scenarioSimulator.current_furnace_type_id;
                const feedTypeId = store.getState().scenarioSimulator.current_feed_type_id;
                const accessToken = getToken(this.props.auth);
                const data = await getRTATilldateData(furnace, furnaceTypeId, feedTypeId, accessToken);
                this.props.updateRTAScenarioRunlength(runlength.id, scenario.id, data.map(x => {
                  return x.forecast !== 0 ? (x.forecast) : x.now
                }), data.map(x => x.UTCTimeStamp), data.map(y => y.Day), store.getState().scenarioSimulator.currentScenarioFireboxId);
              }
              else { // just fetch last record and update
                await this.updateRTA();
              }
              break;

          }
        }
      })
    })
  }

  async updateRTA() {
    const firebox = store.getState().scenarioSimulator.currentScenarioFireboxId === 0 ? "A" : "B";
    const c_furnaceId = store.getState().furnace.current_furnace_id;
    const furnace = c_furnaceId + "." + firebox;
    const accessToken = getToken(this.props.auth);
    const data = await getRTALiveData(furnace, accessToken);
    if (data.length > 0)
      this.updateRTAData(data, c_furnaceId);
  }

  updateRTAData = async (data, furnace_num) => {
    if (store.getState().scenarioSimulator.scenarioRunlengths.find(x => x.name === "Realtime Advisor " + furnace_num)) {
      let scenairoRunlengthId = store.getState().scenarioSimulator.scenarioRunlengths.filter(x => x.name === "Realtime Advisor " + furnace_num)[0].id
      const scenario_index = store.getState().scenarioSimulator.scenarioRunlengths.filter(x => x.id === scenairoRunlengthId)[0].scenarios[0].id;
      this.props.updateCalculation(scenairoRunlengthId, scenario_index.toString(), data.map(x => x.now), data.map(x => x.UTCTimeStamp), data.map(x => x.Day));
    }
  }

  componentDidMount() {
    // this.addDefaultFuelGas(); // TO BE USED IN FUTURE
  }

  componentDidUpdate(prevProps) {
    if ((!this.props.userRoles.includes('N/A')) && (!equal(this.props.userRoles, prevProps.userRoles) || !equal(this.props.currentPlantId, prevProps.currentPlantId) || this.props.accessibleFeatures === undefined)) {
      if(this.props.userRoles && this.props.currentPlantId)
      this.getFeature(this.props.userRoles, this.props.currentPlantId)
    }
  }

  getFeatures() {
    let result = this.props.accessibleFeatures?.length > 0 ? this.props.accessibleFeatures.map(x => "_" + x) : [];
    return result;
  }

  routes = () => {
    const roles = checkPortalPermission(this.props.userRoles);
    if (roles && roles.indexOf(true) >= 0) {
      const visible_dashboards = this.getFeatures()
      const no_permission = (<div>
        <p>You have no permission. Please contact your system admin.</p>
      </div>);
      if (visible_dashboards.indexOf('_N/A') < 0) {
        return (
          <Switch>
            <Route path="*/furnaces/" render={(props) => visible_dashboards.includes(HOME_DASHBOARD_ID) ? <ParentDashboard /> : no_permission} />
            <Route path="*/furnace-overview/" render={(props) =>
              visible_dashboards.includes(FURNACE_OVERVIEW_STATIC_ID)
                ?
                getCurrentClient() === "OPAL"
                  ? <FurnaceOverviewDahej />
                  : (getCurrentClient() === "RUWAIS"
                    ? <FurnaceOverviewRuwais />
                    : ((getCurrentClient() === "BP"
                      ? <FurnaceOverviewScholven />
                      : (getCurrentClient() === "MOL"
                        ? <FurnaceOverviewTisza />
                        : <FurnaceOverview />))))
                : no_permission} />
            <Route path="*/product-predictor/" render={(props) => visible_dashboards.includes(PRODUCT_PREDICTOR_DASHBOARD_ID) ? <ProductPredictor /> : no_permission} />
            <Route path="*/cracked-gas/" render={(props) => visible_dashboards.includes(CRACKED_GAS_DASHBOARD_ID) ? <CrackedGas /> : no_permission} />
            <Route path="*/cot" render={() => visible_dashboards.includes(COIL_OUTLET_TEMPERATURE_DASHBOARD_ID) ? <COT /> : no_permission} />
            <Route path="*/ctmt" render={() => (this.props.accessibleFeatures.includes("THERMAL_IMAGE") && visible_dashboards.includes(COIL_TUBE_METAL_TEMPERATURE_DASHBOARD_ID) && this.props.accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE")) || visible_dashboards.includes(COIL_TUBE_METAL_TEMPERATURE_DASHBOARD_ID) ? <CTMT /> : no_permission} />
            <Route path="*/multi-camera" render={() => visible_dashboards.includes(MULTI_CAMERA_DASHBOARD_ID) ? <MultiCamera /> : no_permission} />
            <Route path="*/configuration" render={() => visible_dashboards.includes(NOTIFICATION_CONFIGURATION_ID) ? <NotificationConfiguration /> : no_permission} />
            <Route path="*/cip" render={() => visible_dashboards.includes(COIL_INLET_PRESSURE_DASHBOARD_ID) ? <CIP /> : no_permission} />
            <Route path="*/coil-dynamics" render={() => visible_dashboards.includes(COIL_DYNAMICS_DASHBOARD_ID) ? <CoilDynamics /> : no_permission} />
            <Route path="*/convection-section" render={() => visible_dashboards.includes(CONVECTION_SECTION_DASHBOARD_ID) ? <ConvectionsectionOverview /> : no_permission} />
            <Route path="*/scenario-simulator" render={(props) => visible_dashboards.includes(SCENARIO_SIMULATOR_DASHBOARD_ID) ? <ScenarioSimulatorOverview /> : no_permission} />
            <Route path="*/feedstock-overview" render={(props) => visible_dashboards.includes(FEEDSTOCK_OVERVIEW_DASHBOARD_ID) ? <FeedstockOverview /> : no_permission} />
            <Route path="*/fuelgas-overview" render={(props) => visible_dashboards.includes(FUELGAS_OVERVIEW_DASHBOARD_ID) ? <FuelGasOverview /> : no_permission} />
            <Route path="*/thermalimage" render={(props) => visible_dashboards.includes(THERMAL_IMAGE_OVERVIEW_DASHBOARD_ID) ? <ThermalImageOverview /> : no_permission} />
            <Route path="*/legal-notice" render={() => <LegalNotice />} />
            <Route path="*/terms-conditions" render={() => <TermCondition />} />
            <Route path="*/privacy-policy" render={() => <PrivacyPolicy />} />
            <Route path="*/user-guide" render={() => <UserGuide />} />
            <Route path="*/login" render={() => <Login />} />
                  <Route path="*/user-roles" render={(props) => visible_dashboards.includes(USER_ROLES_DASHBOARD_ID) ? <UserRolesOverview /> : no_permission} />
                  <Route path="*/camera-health" render={() => visible_dashboards.includes(CAMERA_HEALTH_DASHBOARD) ? <CameraHealth /> : no_permission} />

            <Route path="*/camera-status" render={() => visible_dashboards.includes(CAMERA_HEALTH_DASHBOARD) ? <CameraStatus /> : no_permission} />
                  <Route path="*/notification-log" render={() => visible_dashboards.includes(NOTIFICATION_LOG_DASHBOARD) ? <NotificationLog /> : no_permission} />
                  <Route path="*/health" render={() => <ServiceHealthStatus />} />
                  <Route exact path="**/" render={(props) => visible_dashboards.includes(HOME_DASHBOARD_ID) ? <ParentDashboard /> : no_permission} />
          </Switch>
        );
      }
      else {
        return (
          <div className="loader-grid-parent">
            <div className="loader-grid">
              <Loader />
              <p> Loading...</p>
            </div>
          </div>
        );
      }
    } else {
      // error message
      return (this.props.userRoles.indexOf('N/A') >= 0 ?
        <div className="loader-grid-parent">
          <div className="loader-grid">
            <Loader />
            <p> Loading...</p>
          </div>
        </div> : <div>
          <p>You have no permission. Please contact your system admin.</p>
        </div>
      );
    }
  }

  azureADAuth = () => {
    return (
      <AzureAD provider={authProvider} forceLogin={false}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          if (authenticationState === "Authenticated") {
            return this.routes();
          } else {
            return (
              <Switch>
                <Route path="*/legal-notice" render={() => <LegalNotice />} />
                <Route path="*/terms-conditions" render={() => <TermCondition />} />
                <Route path="*/privacy-policy" render={() => <PrivacyPolicy />} />
                <Route exact path="**/" component={Login} />
              </Switch>
            );
          }
        }}
      </AzureAD>
    )
  }

  pingIDAuth = () => {
    console.log("In a start component: ")
    console.log(this.props.auth)
    if (this.props.auth.isAuthenticated) {
      return this.routes();
    } else {
      return (
        <Switch>
          <Route path="*/legal-notice" render={() => <LegalNotice />} />
          <Route path="*/terms-conditions" render={() => <TermCondition />} />
          <Route path="*/privacy-policy" render={() => <PrivacyPolicy />} />
          <Route exact path="**/" component={Login} />
        </Switch>
      );
    }
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Grid container className="page-container">
          <Grid item xs={12}>
             {/* Ping ID change: Will add condition for authentication type */}
              {isPingIDAuth() ? this.pingIDAuth() : this.azureADAuth()}
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default withAuth(connector(Start));
