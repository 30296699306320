import Box from "@material-ui/core/Box";
import React, { Component } from "react";
import { AzureAD } from "react-aad-msal";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { authProvider } from "../components/authProvider";
import { RootState } from "../reducers/rootReducer";
import { resetFeatures } from "../slices/featuresSlice";
import { resetFeedstocks } from "../slices/feedstockSlice";
import { resetFurnace, setAssetName, setFurnaceId } from "../slices/furnaceSlice";
import { resetFuelgas } from "../slices/fuelGasSlice";
import { REACT_APP_APIM_URL_RESULT } from "../utilities/GlobalConstants";
import { resetConvectionSections } from '../slices/convectionSectionSlice';
import { resetScenarios, setCurrentFeedTypeId, setCurrentFurnaceId, setCurrentFurnaceTypeId, setCurrentPlantId } from '../slices/scenarioSimulatorSlice';
import { resetProductPredictorScenario } from "../slices/productPredictorSlice";
import { resetThermalImage } from "../slices/thermalImageSlice";

import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken, isPingIDAuth } from "../utilities/helperFunctions";
import { resetNotificationLog } from "../slices/notificationLogSlice";
import { addCurrentUserRole, resetCurrentUserRole } from "../slices/userRolesSlice"
import { loadConfigVersion, loadConfigCreationdate, loadConfigClient, loadConfigPlants, loadConfigFurnaces, loadConfigFuelgas, loadConfigScenariosimulator, loadConfigCoilgroups, loadConfigCameras, loadConfigFeedstocks } from "../slices/configurationSlice";
import { getAssetName, getDefaultFeedTypeId, getDefaultFurnaceReferenceId, getDefaultFurnaceTypeId, getDefaultPlantReferenceId, setConfig } from "../configuration";

interface OIDCAuth {
  auth: AuthContextProps
}


const mapStateToProps = (state: RootState) => {
  return {
    userRoleState: state.adminPanel.currentUserRoleState.role,
    expiration: state.authState.expiration,
    userRolesResponse : state.adminPanel.userRolesResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUserRoles: (data) => dispatch(addCurrentUserRole(data)),
    addToken: (data) => dispatch({ type: "ADD_TOKEN", payload: { value: data } }),
    addExpiration: (data) => dispatch({ type: "ADD_EXPIRATION", payload: { value: data } }),
    resetTermination: () => dispatch({ type: "RESET_TERMINATION" }),
    loadConfigVersion: (config_version: string) => dispatch(loadConfigVersion(config_version)),
    loadConfigCreationdate: (creation_date: string) => dispatch(loadConfigCreationdate(creation_date)),
    loadConfigClient: (config_client: string) => dispatch(loadConfigClient(config_client)),
    loadConfigPlants: (config_plants: []) => dispatch(loadConfigPlants(config_plants)),
    loadConfigFurnaces: (config_furnaces: []) => dispatch(loadConfigFurnaces(config_furnaces)),
    loadConfigFuelgas: (fuelgas: []) => dispatch(loadConfigFuelgas(fuelgas)),
    loadConfigScenariosimulator: (scenario_simulator: []) => dispatch(loadConfigScenariosimulator(scenario_simulator)),
    loadConfigCoilgroups: (coilgroups: []) => dispatch(loadConfigCoilgroups(coilgroups)),
    loadConfigCameras: (cameras: []) => dispatch(loadConfigCameras(cameras)),
    loadConfigFeedstocks: (feedstocks: []) => dispatch(loadConfigFeedstocks(feedstocks)),
    setFurnaceId: (newFurnaceId: string) => dispatch(setFurnaceId(newFurnaceId)),
    setAssetName: (assetname: string) => dispatch(setAssetName(assetname)),
    setCurrentFurnaceId: (newFurnaceId: string) => dispatch(setCurrentFurnaceId(newFurnaceId)),
    setCurrentPlantId: (plantId: string) => dispatch(setCurrentPlantId(plantId)),
    setCurrentFurnaceTypeId: (furnace_type_id: string) => dispatch(setCurrentFurnaceTypeId(furnace_type_id)),
    setCurrentFeedTypeId: (feed_type_id: string) => dispatch(setCurrentFeedTypeId(feed_type_id)),
    resetRedux: () => {
      dispatch({ type: "RESET_APP_STATE" });
      dispatch({ type: "RESET_AUTH_STATE" });
      dispatch({ type: "RESET_COIL_STATE" });
      dispatch({ type: "RESET_ERRORS_STATE" });
      dispatch(resetFeedstocks());
      dispatch(resetFurnace());
      dispatch(resetFuelgas());
      dispatch(resetScenarios());
      dispatch({ type: "RESET_SCENARIOS_STATE" });
      dispatch(resetCurrentUserRole());
      dispatch({ type: "RESET_TERMINATION_STATE" });
      dispatch(resetFeatures());
      dispatch(resetConvectionSections());
      dispatch(resetProductPredictorScenario());
      dispatch(resetThermalImage());
      dispatch(resetNotificationLog());
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MyProps = PropsFromRedux & OIDCAuth;

type MyState = {
  userRole: string;
  refreshInterval: any;
};

/**
 * show user credentials in footer
 * service for token refreshing
 */
class LoginState extends Component<MyProps, MyState> {
  constructor(props) {
    super(props);

    this.state = {
      userRole: "",
      refreshInterval: undefined,
    };
  }

  /**
   * get user role & refresh accessToken in interval
   */
  componentDidMount() {
    
    this.getUserRole();
    // this.getConfigForPlantsAndFurnaces()
    // this.getConfigForFuelgasAndScenarioSimulator()
    // this.getConfigForCoilgroupsAndCamera()
    // this.getConfigForFeedstock()
  
  }


  getConfigForPlantsAndFurnaces = async () => {
    const accessToken = getToken(this.props.auth);
    await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForPlantsAndFurnaces`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          this.props.loadConfigVersion(data.config_version)
          this.props.loadConfigCreationdate(data.creation_date)
          this.props.loadConfigClient(data.client)
          this.props.loadConfigPlants(data.plants)
          this.props.loadConfigFurnaces(data.furnaces)
          this.props.setFurnaceId(getDefaultFurnaceReferenceId(getDefaultPlantReferenceId()))
          this.props.setAssetName(getAssetName(getDefaultFurnaceReferenceId(getDefaultPlantReferenceId())))
          this.props.setCurrentFurnaceId(getDefaultFurnaceReferenceId(getDefaultPlantReferenceId()))
          this.props.setCurrentPlantId(getDefaultPlantReferenceId())
          this.props.setCurrentFurnaceTypeId(getDefaultFurnaceTypeId())

          //this.props.setCurrentFeedTypeId(getDefaultFeedTypeId())
          this.getUserRole()
          return data;
        },
        (error) => console.log("error", error)
      );
  };

  getConfigForFuelgasAndScenarioSimulator = async () => {
    const accessToken = getToken(this.props.auth);
    await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForFuelgasAndScenarioSimulator`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          this.props.loadConfigFuelgas(data.fuelgas)
          this.props.loadConfigScenariosimulator(data.scenario_simulator)
          return data;
        },
        (error) => console.log("error", error)
      );
  };

  getConfigForCoilgroupsAndCamera = async () => {
    const accessToken = getToken(this.props.auth);
    await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForCoilgroupsAndCamera`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          this.props.loadConfigCoilgroups(data.coilgroups)
          this.props.loadConfigCameras(data.camera_configs)
          return data;
        },
        (error) => console.log("error", error)
      );
  };

  getConfigForFeedstock = async () => {
    const accessToken = getToken(this.props.auth);
    await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForFeedstock`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {
          this.props.loadConfigFeedstocks(data.feedstocks)
          return data;
        },
        (error) => console.log("error", error)
      );
  };
  /**
   * get user roles by api adn store into redux
   */
  getUserRole = async () => {
    const accessToken = await getToken(this.props.auth);
    setConfig(accessToken)
    fetch(`${REACT_APP_APIM_URL_RESULT}/user/role`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (data) => {

          this.setState({
            userRole: data && data.displayName ? data.displayName.toLowerCase() : "",
          });


          // store user role index too. 
          
          let userRoles = this.props.userRolesResponse;
          // store in redux
          this.props.addUserRoles(data);

          // reset termination on page refresh
          this.props.resetTermination();
          
          return data?.[0];
        },
        (error) => console.log("error", error)
      );
  };

  handleLogout = (logout) => {
    this.props.resetRedux();
    logout();
    authProvider.logout();
  };

  // loginState = ({ name}) => {
  //   return (
  //     <Box display="flex" alignItems="center">
  //     <Box>
  //       <span className="username is-secondary">
  //         {name}
  //         {this.state.userRole ? (
  //           <span className="user-role-wrapper">
  //             (<span className="user-role">{this.state.userRole}</span>)
  //           </span>
  //         ) : null}
  //       </span>
  //     </Box>
  //     <Box px={2}>
  //       <Link to="/login?userLogOut=true" className="icon-exit" title="Logout"></Link>
  //     </Box>
  //   </Box>
  //   )
  // }
  
  azureADLoginstate = () => {
    return (
      <AzureAD provider={authProvider} forceLogin={false}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        if (authenticationState === "Authenticated") {
          return (
            <Box display="flex" alignItems="center">
              <Box>
                <span className="username is-secondary">
                  {accountInfo.account.name}
                  {this.state.userRole ? (
                    <span className="user-role-wrapper">
                      (<span className="user-role">{this.state.userRole}</span>)
                    </span>
                  ) : null}
                </span>
              </Box>
              <Box px={2}>
                <Link to="/login?userLogOut=true" className="icon-exit" title="Logout"></Link>
              </Box>
            </Box>
          );
        } else {
          return <div></div>;
        }
      }}
    </AzureAD>
    )
  }

  pingIdLoginState = () => {

    console.log(this.props.auth.isAuthenticated);
    if(this.props.auth.isAuthenticated) {
        return(
          <Box display="flex" alignItems="center">
            <Box>
              <span className="username is-secondary">
                {this.props.auth.user?.profile.givenName + " " + this.props.auth.user?.profile.sn}
                {this.state.userRole ? (
                  <span className="user-role-wrapper">
                    (<span className="user-role">{this.state.userRole}</span>)
                  </span>
                ) : null}
              </span>
            </Box>
            <Box px={2}>
              <Link to="/login?userLogOut=true" className="icon-exit" title="Logout"></Link>
            </Box>
        </Box>
        )
    }
    else{
        return(
            <div> </div>
        )
       
    }
  }

  render() {
    return (
      <div className="login-state">

        {/* Ping ID change: Will add condition for authentication type */}

        {isPingIDAuth() ? this.pingIdLoginState() : this.azureADLoginstate()}

      </div>
    );
  }
}

export default withAuth(connector(LoginState));
