import { IScenarioRunlength } from "../interfaces/IScenarioRunlength";
import { REACT_APP_APIM_URL_SCENARIO } from "../utilities/GlobalConstants";
import store from "..";

/**
 *
 * @param newScenarioRunlength 
 * @returns 
 */
export async function checkScenarioRunlengthName(newScenarioRunlength: IScenarioRunlength, token: string) {
    // Refresh token before calling compute Initialization
    const repsonce = {
        status: false,
        message: "",
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/scenarios/${newScenarioRunlength.name}/${newScenarioRunlength.is_public}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                if (data.status) {
                    data.message = "Name already exist!"
                }
                return data
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check;
}

/**
 * 
 * @param scenarioRunlengthId 
 * @returns 
 */
export async function deleteScenarioRunlengthById(scenarioRunlengthId: IScenarioRunlength, token: string) {

    const repsonce = {
        status: false,
        message: "",
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/scenarios`, {
        method: "DELETE",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
            id: (!!scenarioRunlengthId.cases_id && scenarioRunlengthId.cases_id > 0) ? scenarioRunlengthId.cases_id : 0
        }),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "This scenarioRunlength deleted sucessfully!"
                return repsonce
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check
}

/**
 * 
 * @param feedstockId 
 * @returns 
 */
export async function getScenarioRunlengthById(scenarioRunlengthId: IScenarioRunlength, token: string) {

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let id = (!!scenarioRunlengthId.cases_id && scenarioRunlengthId.cases_id > 0) ? scenarioRunlengthId.cases_id : 0

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/scenarios/${id}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = !!data ? data : [];
                return repsonce;
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce;
        });

    return check

}