import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import PlantSelector from '../common/PlantSelector';
import AddProductpredictor from './addProductPredictor';
import DeleteProductPredictor from './deleteProductPredictor';
import GroupCardProductPredictor from './groupcardProductPredictor';
import LoadProductPredictor from './loadProductPredictor';
import ProductPredictorSelector from './ProductPredictorSelector';
import { Grid } from '@material-ui/core'; 
import "../../assets/styles/_product-predictor.scss"
import { init_calculations } from '../../utilities/productpredictorCalculator';
import { SCENARIO_STATUS } from '../../interfaces/IScenario';
import ProductPredictorOutput from './ProductPredictorOutput';
import SaveProductPredictor from './saveProductPredictor';
import CloneProductPredictor from './cloneProductPredictor';
import { Checkbox, FormControlLabel, Paper, Typography } from '@material-ui/core';
import { setIsExportable } from '../../slices/productPredictorSlice';
import { compiledFlowHC } from '../../utilities/helperFunctions';
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";

export const PRODUCT_PREDICTOR_DASHBOARD_ID = "_PRODUCT_PREDICTOR";

interface IProductPredictorProps { }
interface OIDCAuth {
    auth?: AuthContextProps
 }
const mapStateToProps = (state: RootState) => {
    return {
        ProductPredictorScenario: state.productPredictor.productPredictorScenarios,
        currentProductPredictorScenarioId: state.productPredictor.currentProductPredictorScenarioId,
        currentProductPredictorScenario: state.productPredictor?.productPredictorScenarios?.filter(x => x.id === state.productPredictor?.currentProductPredictorScenarioId)[0],
        currentProductPredictorScenarioGroups: state.productPredictor?.productPredictorScenarios?.filter(x => x.id === state.productPredictor?.currentProductPredictorScenarioId)[0]?.groups,
        token: state.authState.token,
        fuelGases: state.fuelGas.fuelGases,
        convectionsections: state.convectionsection.convectionsections,
        feedstocks: state.feedstock.feedstocks,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setIsExportable: (scenario_id: string, isTrueOrFalse: boolean) => dispatch(setIsExportable({ scenario_id, isTrueOrFalse }))
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProductProductorReduxProps = PropsFromRedux & IProductPredictorProps & OIDCAuth;

type IProductPredictorState = {
    isExport: boolean;
}

class ProductPredictor extends Component<IProductProductorReduxProps, IProductPredictorState>{

    constructor(props) {
        super(props);

        this.state = {
            isExport: this.props.currentProductPredictorScenario?.isExportable ? this.props.currentProductPredictorScenario.isExportable : false,
        }
    }

    handleChangeIsExport(event) {
        this.setState({
            isExport: !this.state.isExport
        }, async () => {
            if (this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.COMPUTED) {
                await this.props.setIsExportable(this.props.currentProductPredictorScenarioId, this.state.isExport)
            }
        })
    }

    saveProductPredictor() {

    }
    saveasProductPredictor() {

    }
    runProductPredictor() {
        const accessToken = getToken(this.props.auth);
        init_calculations(this.props.currentProductPredictorScenario, accessToken)
    }

    getFeedStockName(groups) {
        let feedStockNamesArray = [] as any;
        groups.forEach(groupValue => {
            groupValue.feedstock.forEach(feedstockValue => {
                this.props.feedstocks.forEach(feedstock => {
                    if (feedstock.id === feedstockValue.feedstock_id) {
                        feedStockNamesArray.push(feedstock.name)
                    }
                })
            })
        });
        let names = feedStockNamesArray.toString();
        return names;
    }

    getFuelgasName(fuelGas_id) {
        let name = this.props.fuelGases.forEach(fuelGas => {
            if (fuelGas.id === fuelGas_id) {
                return fuelGas.name
            }
        })
        return name;
    }

    getconvectionsectionName(groups) {
        let convectionSectionNamesArray = [] as any;
        groups.forEach(groupValue => {
            if (!!groupValue.convectionsection_id) {
                this.props.convectionsections.forEach(convectionsection => {
                    if (convectionsection.id === groupValue.convectionsection_id) {
                        return convectionSectionNamesArray.push(convectionsection.name)
                    }
                })
            }
        });
        let names = convectionSectionNamesArray.toString();
        return names;
    }

    getSelectivityCalculation() {
        let ethylene = this.props.currentProductPredictorScenario.output.products.find(x => x.name === "Ethylene")?.value;
        const multipliedArray = this.props.currentProductPredictorScenarioGroups.map((ele) => Number(compiledFlowHC(this.props.currentProductPredictorScenario, this.props.currentProductPredictorScenarioGroups, ele.name)) * Number(ele.furnace_number))
        const sum = multipliedArray.reduce((acc, curr) => acc + Number(curr), 0)
        return ethylene !== undefined ? Number((ethylene / sum).toFixed(2)) : 0;
    }

    render() {
        return (
            <div className="">
                <div className="control-header">
                    <PlantSelector disabled={true} />
                    <ProductPredictorSelector />
                    <AddProductpredictor />
                    <LoadProductPredictor />
                    {this.props.ProductPredictorScenario.length > 0 ? <SaveProductPredictor currentProductPredictor={this.props.currentProductPredictorScenario} /> : <div />}
                    {this.props.ProductPredictorScenario.length > 0 ? <AddProductpredictor currentProductPredictor={this.props.currentProductPredictorScenario} /> : <div />}
                    {this.props.ProductPredictorScenario.length > 0 ? <CloneProductPredictor currentProductPredictor={this.props.currentProductPredictorScenario} /> : <div />}
                    {this.props.ProductPredictorScenario.length > 0 ?
                        <div className="control-header">
                            <button className={!!this.props.currentProductPredictorScenario && this.props.currentProductPredictorScenario?.progress === 0 ? "button margin-right red" : "button margin-right"} disabled={this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.RUNNING ? true : false} onClick={() => this.runProductPredictor()}>Run</button>
                        </div>
                        : <div />
                    }
                    <div className='control-header margin-right right-alignment'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.isExport}
                                    onChange={(event) => this.handleChangeIsExport(event)}
                                    name="isExport"
                                    value={this.state.isExport}
                                    color="primary"
                                    disabled={this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.COMPUTED ? false : true}
                                />
                            }
                            label="Select (For Export)"
                        />
                    </div>
                    {this.props.ProductPredictorScenario.length > 0 ? <DeleteProductPredictor ProductPredictorScenarioId={this.props.currentProductPredictorScenarioId} /> : <div />}
                </div>
                {this.props.ProductPredictorScenario.length > 0 ?
                    <Paper className="paper" elevation={1} style={{ padding: '10px', minHeight: 'calc(100vh - 225px)' }}>
                        <div style={{ marginBottom: '10px' }}>
                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.IS_CHANGED_IN_FEEDSTOCK && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        Feedstock #{this.getFeedStockName(this.props.currentProductPredictorScenario?.groups)} has been modified
                                    </Typography>
                                )
                            }

                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.IS_CHANGED_IN_FUELGAS && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        Fuelgas #{this.getFuelgasName(this.props.currentProductPredictorScenario?.fuelgas_id)} has been modified
                                    </Typography>
                                )
                            }

                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.IS_CHANGED_IN_CONVECTION_SECTION && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        Convectionsection #{this.getconvectionsectionName(this.props.currentProductPredictorScenario?.groups)} has been modified
                                    </Typography>
                                )
                            }

                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.DELETED_FEEDSTOCK && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        Assigned feedstock has been deleted please assign new one
                                    </Typography>
                                )
                            }

                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.DELETED_FUELGAS && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        Assigned fuel gas has been deleted please assign new one
                                    </Typography>
                                )
                            }

                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.DELETED_CONVECTION_SECTION && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        Assigned convectionsection has been deleted please assign new one
                                    </Typography>
                                )
                            }

                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        This scenario has revived from the graveyard previous check of a feedstock or fuelgas ( new one ) will exist
                                    </Typography>
                                )
                            }

                            {
                                this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION && (
                                    <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                                        This scenario has revived from the graveyard previous check of a feedstock or fuelgas or convectionsection ( new one ) will exist
                                    </Typography>
                                )
                            }
                        </div>
                        <div className={this.props.ProductPredictorScenario.length > 0 && this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.RUNNING ? "scenario-runlength-list scenario-running" : (this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.COMPUTED ? "scenario-runlength-list dispOutput" : "scenario-runlength-list")}>
                            {this.props.ProductPredictorScenario.length > 0
                                ? <GroupCardProductPredictor scenario={this.props.currentProductPredictorScenario} />
                                : <div />}
                        </div>

                        {this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.COMPUTED ? <ProductPredictorOutput currentProductPredictorScenairo={this.props.currentProductPredictorScenario} /> : <div />}
                        {this.props.ProductPredictorScenario.length > 0 && this.props.currentProductPredictorScenario?.status === SCENARIO_STATUS.COMPUTED ? 
                            <div style={{ marginTop: '10px' }}>
                                <Grid container xs={6} spacing={0}>
                                    <Paper className="output paper" elevation={3}>
                                        <Grid xs={12} alignContent={'stretch'} className="outputTitle">
                                            <Typography><b>Selectivity {this.getSelectivityCalculation()}</b></Typography>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </div>
                        : <div />}
                    </Paper>
                    : <div />
                }
            </div>
        );
    };

}

export default withAuth(connector(ProductPredictor))