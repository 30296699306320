import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { RootState } from "../../reducers/rootReducer";
import { deleteConvectionSectionById } from "../../services/convectionsectionServices";
import { deleteConvectionSection } from "../../slices/convectionSectionSlice";
import { updateDeletedConvectionSectionId } from "../../slices/scenarioSimulatorSlice";
import { updateDeletedConvectionSectionIdInPP } from "../../slices/productPredictorSlice";
import { getToken } from "../../utilities/helperFunctions";
import { AuthContextProps, withAuth } from "react-oidc-context";


interface IDeleteConvectionSectionProps {
    convectionsection: IConvectionSection;
}

interface OIDCAuth {
    auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
    return {
        convectionsections: state.convectionsection.convectionsections,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteConvectionSection: (convectiosection_id: string) => dispatch(deleteConvectionSection(convectiosection_id)),
        updateDeletedConvectionSectionId: (convectionsection_id: string) => dispatch(updateDeletedConvectionSectionId(convectionsection_id)),
        updateDeletedConvectionSectionIdInPP: (convectionsection_id: string) => dispatch(updateDeletedConvectionSectionIdInPP(convectionsection_id)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDeleteConvectionSectionReduxProps = PropsFromRedux & IDeleteConvectionSectionProps & OIDCAuth;

type IDeleteConvectionSection = {
    showDialog: boolean;
    message: string;
    showLoading: boolean;
}
class DeleteConvectionSection extends Component<IDeleteConvectionSectionReduxProps, IDeleteConvectionSection>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            message: "",
            showLoading: false,
        }
    }

    toggleShowDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog,
        })
    }

    deleteConvectionSection = async () => {
        this.setState({
            showLoading: true,
        })
        const accessToken = getToken(this.props.auth);
        let deleteFromDb = await deleteConvectionSectionById(this.props.convectionsection.id, accessToken);
        if (deleteFromDb.status) {
            this.props.updateDeletedConvectionSectionId(this.props.convectionsection.id);
            this.props.updateDeletedConvectionSectionIdInPP(this.props.convectionsection.id);
            this.props.deleteConvectionSection(this.props.convectionsection.id);
            this.setState({
                message: deleteFromDb.message
            })
        }
        setTimeout(() => {
            this.toggleShowDialog()
            setTimeout(() => {
                this.setState({
                    message: "",
                    showLoading: false,
                })
            }, 200);
        }, 1000);
    }

    render() {
        return (
            <div className="control-header element alignright">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                >
                    Delete
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete Convection Section</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this convection section?
                        </DialogContentText>
                        {!!this.state.message && this.state.message !== "" && (
                            <Typography variant="body1" component="p" color="secondary">
                                {this.state.message}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.deleteConvectionSection()} disabled={this.state.showLoading} >
                            Delete {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

};

export default withAuth(connector(DeleteConvectionSection));