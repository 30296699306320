import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export type ICameras = {
    camera_name: string;
    camera_location: string;
    tmt_threshold_value: string;
};

export type IcameraSlice = {
    id: string,
    name: string,
    cameras: ICameras[],
    currentTimestamp: string
}

const initialState: IcameraSlice = {
    id: "",
    name: "",
    cameras: [],
    currentTimestamp: ""
}

export const cameraSlice = createSlice({
    name: "cameraSlice",
    initialState: initialState,
    reducers: {
        setCurrentCamera: (state, action: PayloadAction<string>) => {
            state.name = action.payload
        },
        setCurrentCameraId: (state, action: PayloadAction<string>) => {
            state.id = action.payload
        },
        resetCameraSlice: (state) => {
            state.id = "";
            state.name = "";
            state.cameras = [];
        },
        getCameraList: (state, action: PayloadAction<ICameras[]>) => {
            state.cameras = action.payload
        },
        setSelectedTimestamp: (state, action: PayloadAction<string>) => {
            state.currentTimestamp = action.payload
        }
    }
});

export const {
    setCurrentCamera,
    setCurrentCameraId,
    resetCameraSlice,
    getCameraList,
    setSelectedTimestamp
} = cameraSlice.actions


export default cameraSlice.reducer
