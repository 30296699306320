import React, { Component } from "react";
import { MenuItem, Select, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";

import { getRTATilldateData } from "../../utilities/helperFunctions";
import store from "../..";
import { addCalculation, setCurrentRunLengthId, setCurrentScenarioFireboxId } from "../../slices/scenarioSimulatorSlice";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface IFireboxSelectorProps {
    setLoading?: (val) => void
}
interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        furnaceState: state.furnace,
        token: state.authState.token,
        scenarioState: state.scenarioSimulator,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
        currentScenarioRunLengthId: state.scenarioSimulator.currentScenarioRunLengthId,
        currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId,
        currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA
    };
};

const mapDispatchToProps = dispatch => {
    return {

        addCalculation: (runlength_id: string, scenario_id: string, result: any, timeline: any, days: string[], rta: boolean, fireboxIndex: number) => dispatch(addCalculation({ runlength_id, scenario_id, result, timeline, days, rta, fireboxIndex })),
        setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId)),
        setCurrentScenarioFireboxId: (newScenarioFireboxId: number) => dispatch(setCurrentScenarioFireboxId(newScenarioFireboxId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFireboxSelectorReduxProps = PropsFromRedux & IFireboxSelectorProps & OIDCAuth;

type IFireboxSelectorState = {
    fireboxId: number,
    disabled: boolean
}

// const fireboxes = ['Firebox-A', 'Firebox-B']

class FireboxSelector extends Component<IFireboxSelectorReduxProps, IFireboxSelectorState>{

    constructor(props) {
        super(props);
        this.state = { fireboxId: 1, disabled: false }
        this.props.setCurrentScenarioFireboxId(Number(this.getFireboxId()));
        //this.props.scenarioRunlengths.find(scenario => scenario.id === this.props.currentScenarioRunLengthId)?.scenarios[0].terminated_firebox !== -1 ? true : false;
    }

    async handleSelection(event) {
        this.props.setLoading && this.props.setLoading(true) //set loader true
        const new_firebox_id = event.target.value;
        this.props.setCurrentScenarioFireboxId(new_firebox_id);
        this.setState({ fireboxId: new_firebox_id });
        const furnace_id = this.props.furnaceState.current_furnace_id;
        // right now firebox only for rta

        const firebox = new_firebox_id === 1 ? "A" : "B";

        const furnace = furnace_id + "." + firebox;
        const furnaceTypeId = store.getState().scenarioSimulator.current_furnace_type_id;
        const feedTypeId = store.getState().scenarioSimulator.current_feed_type_id;
        const accessToken = getToken(this.props.auth);
        if (this.props.currentScenarioIsRTA) {
            const data = await getRTATilldateData(furnace, furnaceTypeId, feedTypeId, accessToken)
            this.setRTAData(data, furnace_id, new_firebox_id);
        }
        this.props.setLoading && this.props.setLoading(false) //set loader false
        this.props.setCurrentScenarioFireboxId(event.target.value);
    }

    setRTAData = async (data, furnace_num, firebox_id) => {
        const scenarioId = store.getState().scenarioSimulator.scenarioRunlengths.filter(x => x.id === this.props.scenarioState.currentScenarioRunLengthId)[0].scenarios[0].id
        const currentRunLengthId = this.props.scenarioState.currentRunLengthId
        this.props.addCalculation(this.props.scenarioState.currentScenarioRunLengthId, scenarioId.toString(), data.map(x => {
            return x.forecast !== 0 ? (x.forecast) : x.now
        }), data.map(x => x.UTCTimeStamp), data.map(y => y.Day), true, firebox_id);
        this.props.setCurrentRunLengthId(currentRunLengthId)
    }
    getFireboxId() {
        let fireboxId = this.props.scenarioRunlengths.find(scenario => scenario.id === this.props.currentScenarioRunLengthId)?.scenarios[0]?.terminated_firebox;
        return fireboxId !== -1 ? fireboxId : 0;
    }

    getSelectedFirebox() {
        return this.props.currentScenarioFireboxId;
    }

    getFirebox() {
        return this.props.scenarioRunlengths.find(scenario => scenario.id === this.props.currentScenarioRunLengthId)?.scenarios[0].firebox;
    }

    render() {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography>Firebox</Typography>
                </div>
                <Select autoWidth disabled={this.state.disabled} value={this.getSelectedFirebox()} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.getFirebox()?.map((box, i) => (
                        <MenuItem key={`Firebox-Select-${i}`} value={i}>
                            Firebox {i + 1}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }

};

export default withAuth(connector(FireboxSelector));