import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getDefaultFuelGasComposition, getPlantReferences } from "../../configuration";
import { UNIT } from "../../interfaces/IComponent";
import { FUELGAS_STATUS, IFuelGas } from "../../interfaces/IFuelGas";
import { RootState } from "../../reducers/rootReducer";
import { loadFuelgasData } from "../../services/fuelgasServices";
import { loadAllFuelGasData } from "../../slices/fuelGasSlice";
import LoadAutocomplete from "../common/LoadAutocomplete"
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";

interface ILoadFuelGasProps {
}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        fuelGases: state.fuelGas.fuelGases,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadAllFuelGasData: (data: any) => dispatch(loadAllFuelGasData(data))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ILoadFuelGasReduxProps = PropsFromRedux & ILoadFuelGasProps & OIDCAuth;

type ILoadFuelGasState = {
    showDialog: boolean;
    selected: any[];
    allFuelGasData: any[];
    isAllSelected: boolean;
    showLoading: boolean;
}

class LoadFuelGas extends Component<ILoadFuelGasReduxProps, ILoadFuelGasState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            selected: [],
            allFuelGasData: [],
            isAllSelected: false,
            showLoading: false
        }
    }

    // TO BE USED IN FUTURE
    async componentDidMount() {
        // await this.addDefaultFuelGas();
    }

    addDefaultFuelGas() {
        const plants = getPlantReferences();
        plants.forEach(plant => {
            const newFuelGas: IFuelGas = {
                id: "_DEFAULT_" + plant.name,
                name: "DEFAULT_" + plant.name,
                unit: UNIT.MOL,
                components: getDefaultFuelGasComposition(),
                status: FUELGAS_STATUS.UNCOMPUTED,
                error_message: "",
                O2BridgeWall: -1,
                Pressure: -1,
                Temperature: -1,
                signature: ""
            };
            // if (this.state.allFuelGasData.length === 0) {
            //     this.setState({
            //         allFuelGasData: [newFuelGas]
            //     })
            // }  // TO BE USED IN FUTURE
            if (!this.state.allFuelGasData.includes(newFuelGas)) {
                this.state.allFuelGasData.push(newFuelGas)
            }
        });
    }

    async loadFuelGasDataInit() {
        this.setState({
            showLoading: true
        })
        try {
            const accessToken = getToken(this.props.auth);
            let setAllFuelGasData = await loadFuelgasData(accessToken);
            // TO BE USED IN FUTURE
            // let setAll = [{
            //     ...getDefaultFeedstock(),
            //     id: "-1",
            //     name: "All"
            // }];

            if (!!setAllFuelGasData && !!setAllFuelGasData.data && setAllFuelGasData.data.length > 0) {
                setAllFuelGasData.data.forEach((fuelGasValue: IFuelGas) => {
                    fuelGasValue['is_saved'] = true;
                    fuelGasValue['is_display_leave_popup'] = false;
                })
            }
            if (setAllFuelGasData.status === true) {
                // TO BE USED IN FUTURE
                // let setAllFuelGasDataNew = [...setAll, ...setAllFuelGasData.data]
                let setAllFuelGasDataNew = [...this.state.allFuelGasData, ...setAllFuelGasData.data]
                this.setState({
                    showLoading: false,
                    allFuelGasData: setAllFuelGasDataNew
                })
            } else {
                this.setState({
                    showLoading: false
                })
            }
        } catch (error) {
            this.setState({
                showLoading: false
            })
        }

    }


    toggleShowDialog = async () => {
        await this.setState({
            showDialog: !this.state.showDialog,
            allFuelGasData: []
        })

        if (this.state.showDialog) {
            await this.addDefaultFuelGas();
            await this.loadFuelGasDataInit();
        }
    }

    loadFeedstock = async () => {
        let isAllSelected = this.state.selected.some(el => el.name.toLowerCase() === "all");

        if (isAllSelected) {
            let removeAllFromArray = this.state.allFuelGasData.filter(fuelgas => fuelgas.name.toLowerCase() !== "all");
            let filterData = removeAllFromArray.filter(fuelgas => {
                return this.props.fuelGases.filter(el => {
                    return el.id === fuelgas.id;
                }).length === 0
            });
            this.props.loadAllFuelGasData(filterData)
            this.setState({
                selected: []
            });
        } else {
            this.props.loadAllFuelGasData(this.state.selected);
            this.setState({
                selected: []
            });
        }
        this.toggleShowDialog()
    }

    selectedDataSet = (valueData) => {
        this.setState({
            selected: valueData
        })
    }

    render() {
        return (
            <>
                <LoadAutocomplete
                    showDialog={this.state.showDialog}
                    selected={this.state.selected}
                    allData={this.state.allFuelGasData}
                    isAllSelected={this.state.isAllSelected}
                    showLoading={this.state.showLoading}
                    componentName={'Fuelgas'}
                    disableData={this.props.fuelGases}
                    toggleShowDialog={this.toggleShowDialog}
                    loadFeedstock={this.loadFeedstock}
                    selectedDataSet={this.selectedDataSet}

                ></LoadAutocomplete>
            </>
        );
    }

};

export default withAuth(connector(LoadFuelGas));