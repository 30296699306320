import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IAdUsersResponse, ICurrentUserRoleState } from "../../../interfaces/IadminPanel";
import { RootState } from "../../../reducers/rootReducer";
import AddEditRoles from "./addEditRoles";
import RolesTable from "./rolesTable";
import { loadAllAdUsers } from "../../../slices/userRolesSlice";
import { getAllUsersFromAd } from "../../../services/userRolesServices";
import { getToken } from "../../../utilities/helperFunctions";
import { AuthContextProps, withAuth } from "react-oidc-context";

export const USER_ROLES_DASHBOARD_ID = "_USER_ROLES";

interface IUserRolesOverviewProps { }

interface OIDCAuth {
    auth?: AuthContextProps
  }

const mapStateToProps = (state: RootState) => {
    return {
        currentUserRole: state.adminPanel.currentUserRoleState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadAdUsers: (adUsersResponse: IAdUsersResponse) => dispatch(loadAllAdUsers(adUsersResponse)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IUserRolesOverviewReduxProps = PropsFromRedux & IUserRolesOverviewProps & OIDCAuth;


type IUserRolesState = {
    currentUserRole: ICurrentUserRoleState
}


class UserRolesOverview extends Component<IUserRolesOverviewReduxProps, IUserRolesState> {

    async componentDidMount() {
        const accessToken = getToken(this.props.auth);
        let getAllUsersFromAdData = await getAllUsersFromAd(accessToken);
        if (getAllUsersFromAdData.status === true) {
            this.props.loadAdUsers(getAllUsersFromAdData)
        }
    }

    render() {
        return (
            <>
                <div className="feedstock-overview">
                    <div className="control-header">
                        {this.props.currentUserRole.roleIndex !== 4 ? <AddEditRoles popupName={'Add'} id={0} /> : <></>}
                    </div>
                    <div className="feedstock-dashboard">
                        <RolesTable />
                    </div>
                </div>
            </>
        )
    }
}

export default withAuth(connector(UserRolesOverview));