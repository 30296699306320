import { Component } from "react";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControlLabel, TextField } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { RootState } from "../../reducers/rootReducer";
import { addScenarioRunlength, setCurrentRunLengthId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";
import { checkScenarioRunlengthName } from "../../services/scenarioSimulatorServices";
import { SCENARIO_TYPE } from "../../interfaces/IScenario";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface IAddScenarioRunlengthProps { }

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
        feedstocks: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.fuelGases,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addScenarioRunlength: (newScenarioRunlength: IScenarioRunlength) => dispatch(addScenarioRunlength(newScenarioRunlength)),
        setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
        setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IAddScenarioRunlengthReduxProps = PropsFromRedux & IAddScenarioRunlengthProps & OIDCAuth;

type IAddScenarioRunlengthState = {
    showDialog: boolean;
    name: string;
    is_public: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
    type: SCENARIO_TYPE;
}
class AddScenarioRunlength extends Component<IAddScenarioRunlengthReduxProps, IAddScenarioRunlengthState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: "",
            is_public: true,
            isNameAvailable: false,
            nameErrorMessage: "",
            showLoading: false,
            type: SCENARIO_TYPE.Cold_START
        }
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            name: "",
            isNameAvailable: false,
            nameErrorMessage: ""
        })
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value.toUpperCase()
        })
    }

    addNewScenarioRunlength = async () => {
        if (!!this.state.name?.trim()) {
            this.setState({
                showLoading: true,
            })

            let setName = this.state.is_public === true ? this.state.name.trim() + "(PUBLIC)" : this.state.name.trim();

            const newScenarioRunlength: IScenarioRunlength = {
                id: uuidv4(),
                furnaceId: this.props.currentFurnaceId,
                plantId: this.props.currentPlantId,
                name: setName,
                scenarios: [],
                isExportable: false,
                signature: "",
                is_public: this.state.is_public,
                is_display_leave_popup: false,
                type: this.state.type
            }

            try {
                let isNameInRedux = this.props.scenarioRunlengths.some(scenarioRunlength => scenarioRunlength.name === newScenarioRunlength.name);
                if (isNameInRedux) {
                    this.setState({
                        isNameAvailable: true,
                        nameErrorMessage: "Name already exist!",
                        showLoading: false,
                    })
                } else {
                    const accessToken = getToken(this.props.auth);
                    let isNameAvailable = await checkScenarioRunlengthName(newScenarioRunlength, accessToken);
                    if (isNameAvailable.status) {
                        this.setState({
                            isNameAvailable: true,
                            nameErrorMessage: !!isNameAvailable.message ? isNameAvailable.message : "Name already exist!",
                            showLoading: false,
                        })
                    } else {
                        this.props.addScenarioRunlength(newScenarioRunlength);
                        // this.props.setCurrentScenarioRunLengthId(newScenarioRunlength.id);
                        this.props.setCurrentRunLengthId(0);
                        this.toggleShowDialog();
                        this.setState({
                            showLoading: false,
                        })
                    }
                }
            } catch (error) {
                // Api error hendaling 
                this.setState({
                    isNameAvailable: true,
                    showLoading: false,
                    nameErrorMessage: "Oops somthing went to wrong!"
                })
            }

        } else {
            this.setState({
                isNameAvailable: true,
                nameErrorMessage: "Please enter a name!",
                showLoading: false,
            })
        }
    }
    setScenarioType = (e) => {
        const value = e.target.value;
        this.setState({
            type: value
        })
    }
    handleChangeIsPublic(event) {
        this.setState({
            is_public: !this.state.is_public
        })
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                >
                    Add
                </button>
                {this.props.feedstocks.length > 0 && this.props.fuelGases.length > 0 && (
                    <>
                        <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Add new scenario runlength</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To add a new scenario runlength, please provide the following information:
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    value={this.state.name}
                                    onChange={(event) => this.handleNameInput(event)}
                                    helperText={this.state.nameErrorMessage}
                                    error={this.state.isNameAvailable}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.is_public}
                                            onChange={(event) => this.handleChangeIsPublic(event)}
                                            name="isPublic"
                                            value={this.state.is_public}
                                            color="primary"
                                        />
                                    }
                                    label="Is Public?"
                                />
                                {/* {
                                    currentScenarioIsRTA ?
                                        <> <div className="">
                                            <Typography>Scenario Type: </Typography>
                                        </div>
                                            <Select autoWidth disabled={false} value={this.state.type} onChange={(e) => this.setScenarioType(e)} style={{ width: "100%" }}>
                                                <MenuItem value={SCENARIO_TYPE.Cold_START}>Cold Start</MenuItem>
                                                <MenuItem value={SCENARIO_TYPE.WARM_START}>Warm Start</MenuItem>
                                            </Select></> :
                                        <></>} */}
                            </DialogContent>

                            <DialogActions>
                                <button className="button" onClick={() => this.toggleShowDialog()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.addNewScenarioRunlength()} disabled={this.state.showLoading}>
                                    Add {this.state.showLoading === true && (
                                        <Fade
                                            in={this.state.showLoading}
                                            style={{
                                                transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress size={11} />
                                        </Fade>
                                    )}
                                </button>
                            </DialogActions>

                        </Dialog>
                    </>
                )}

                {(this.props.feedstocks.length === 0 || this.props.fuelGases.length === 0) && (
                    <>
                        <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                            <DialogContent>
                                <DialogContentText style={{ color: "red" }}>
                                    Please add / load atleast one feedstock and one fuelgas for add scenario
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className="button" onClick={() => this.toggleShowDialog()} >
                                    Ok
                                </button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>
        );
    }

};

export default withAuth(connector(AddScenarioRunlength));