import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { deleteProductPredictorIdByID } from '../../services/productPredictorServices';
import { deleteProductPredictorScenario } from '../../slices/productPredictorSlice';
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface IDeleteProductPredictor {
    ProductPredictorScenarioId: string
}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        deleteProductPredictorScenario: (id: string) => dispatch(deleteProductPredictorScenario(id))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDeleteProductPredictorFromRedux = IDeleteProductPredictor & PropsFromRedux & OIDCAuth;

type IDeleteProductPredictorState = {
    showDialog: boolean;
    message: string;
    showLoading: boolean;
}


class DeleteProductPredictor extends Component<IDeleteProductPredictorFromRedux, IDeleteProductPredictorState> {

    deleteProductPredictorScenario = async () => {
        this.setState({
            showLoading: true,
        })
        const accessToken = getToken(this.props.auth);
        let deleteFromDb = await deleteProductPredictorIdByID(this.props.ProductPredictorScenarioId, accessToken)
        if (deleteFromDb.status) {
            await this.props.deleteProductPredictorScenario(this.props.ProductPredictorScenarioId);
            this.setState({
                message: deleteFromDb.message,
            })
        }
        setTimeout(() => {
            this.setState({
                message: "",
                showLoading: false,
            })
            this.toggleShowDialog()
        }, 1000);

    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog
        })
    }

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            message: "",
            showLoading: false,
        }
    }
    render() {
        return (
            <div className="control-header element alignright" >
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                >
                    Delete
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete Product Predictor Scenario</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this Product Predictor Scenario?
                        </DialogContentText>
                        {!!this.state.message && this.state.message !== "" && (
                            <Typography variant="body1" component="p" color="secondary">
                                {this.state.message}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.deleteProductPredictorScenario()} disabled={this.state.showLoading}>
                            Delete {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withAuth(connector(DeleteProductPredictor));