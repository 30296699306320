import React, { Component } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IFeedstock } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { deleteFeedstock } from "../../slices/feedstockSlice";
import { updateDeletedFeedstockId } from "../../slices/scenarioSimulatorSlice";
import { deleteFeedStockByID } from "../../services/feedstockServices";
import { updateDeletedFeedstockIdInPP } from "../../slices/productPredictorSlice";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";


interface IDeleteFeedstockProps {
    feedstock: IFeedstock;
}

interface OIDCAuth {
    auth?: AuthContextProps
}


const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteFeedstock: (feedstock_id: string) => dispatch(deleteFeedstock(feedstock_id)),
        updateDeletedFeedstockId: (feedstock_id: string) => dispatch(updateDeletedFeedstockId(feedstock_id)),
        updateDeletedFeedstockIdInPP: (feedstock_id: string) => dispatch(updateDeletedFeedstockIdInPP(feedstock_id)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDeleteFeedstockReduxProps = PropsFromRedux & IDeleteFeedstockProps & OIDCAuth;

type IDeleteFeedstockState = {
    showDialog: boolean;
    message: string;
    showLoading: boolean;
}

class DeleteFeedstock extends Component<IDeleteFeedstockReduxProps, IDeleteFeedstockState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            message: "",
            showLoading: false,
        }
    }

    toggleShowDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog,
        })
    }

    deleteFeedstock = async () => {
        this.setState({
            showLoading: true,
        })
        const accessToken = getToken(this.props.auth);
        let deleteFromDb = await deleteFeedStockByID(this.props.feedstock.id, accessToken);
        if (deleteFromDb.status) {
            await this.props.updateDeletedFeedstockId(this.props.feedstock.id);
            await this.props.updateDeletedFeedstockIdInPP(this.props.feedstock.id);
            await this.props.deleteFeedstock(this.props.feedstock.id);
            this.setState({
                message: deleteFromDb.message,
            })
        }
        setTimeout(() => {
            this.toggleShowDialog()
            setTimeout(() => {
                this.setState({
                    message: "",
                    showLoading: false,
                })
            }, 200);
        }, 1000);

    }

    render() {
        return (
            <div className="control-header element alignright">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                >
                    Delete
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete Feedstock</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this feedstock?
                        </DialogContentText>
                        {!!this.state.message && this.state.message !== "" && (
                            <Typography variant="body1" component="p" color="secondary">
                                {this.state.message}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.deleteFeedstock()} disabled={this.state.showLoading}>
                            Delete {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

};

export default withAuth(connector(DeleteFeedstock));