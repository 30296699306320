import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { Grid, Box } from "@material-ui/core";
import OverviewTable from "../coil/overviewTable";
import Timechart from "../timechart";
import CIPSummaryTable from "./cipSummaryTable";
import CIPYSwitch from "./cipYSwitch";
import { CoilObjectI, ToFixedI } from "../../interfaces/coil";
import { generateTimechartData, generateDetailTimechartData } from "../../utilities/coilModelShapers";
import SettingsTable from "../coil/settingsTable";
import PlantSelector from "../common/PlantSelector";
import { getNumberOfPlants } from "../../configuration";
import FurnaceSelector from "../common/FurnaceSelector";
import ScenarioRunLengthSelector from "../common/ScenarioRunLengthSelector";
import { MODE } from "../../interfaces/IScenario";
import FireboxSelector from "../common/FireboxSelector";
import { isRTA, getGraphDCSData, getToken } from "../../utilities/helperFunctions";
import Loader from "react-spinners/ClipLoader";
import store from "../..";
import { AuthContextProps, withAuth } from "react-oidc-context";
export const COIL_INLET_PRESSURE_DASHBOARD_ID = "_COIL_INLET_PRESSURE";

interface ICoilInletPressureProps { }
interface OIDCAuth {
  auth?: AuthContextProps
}
const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA
    ,
    currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId

  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICoilInletPressureReduxProps = PropsFromRedux & ICoilInletPressureProps & OIDCAuth;

const CoilInletPressure: React.SFC<ICoilInletPressureReduxProps> = ({ scenarios, currentScenarioFireboxId, currentScenarioIsRTA, auth }) => {
  const { referencePositionIndex } = useSelector((state: RootState) => state.coilState);
  const selectedScenario: number = 0;
  const [cipDCSMax, setCipDCSMax] = useState("0")
  const [lrDCSMax, setLrDCSMax] = useState("0")
  const [filtereddataForLR, setfiltereddataForLR] = useState([]);
  const [dcsGraphData, setDCSGraphData] = useState([{
    runtime: "0",
    Day: "0",
    TagName: "0",
    DCS: "0",
    Datetime: "0"
  }]);
  /**
   * Data for primary top chart
   */

  const [data, setData] = useState({
    id: 0,
    name: "",
    data: [],
  } as CoilObjectI);

  /**
   * Data for secondary bottom chart
   */

  const [detailData, setDetailData] = useState({
    id: 0,
    name: "",
    data: [],
  } as CoilObjectI);

  /**
   * Data for top chart for Y-Axis switcher
   */

  const [yData, setYData] = useState({
    name: "Coil Inlet Pressure",
    key: "CIP",
    unit: "(bar g)",
    ToFixed: 3,
    y_axis_adjustment: [0, false],
  });
  const [currentView, setCurrentView] = React.useState('MAX');

  const [filtereddata, setFilteredData] = useState([]);

  useEffect(() => {
    filterData(data.data, currentView);
    // setIsScenarioChanged(true);
  }, [dcsGraphData,data.data,currentView])

  const getGraphDCS = async () => {
    setLoading(true)
    const c_furnaceId = store.getState().furnace.current_furnace_id;
    const curr_firebox = store.getState().scenarioSimulator.currentScenarioFireboxId + 1;
    const accessToken = getToken(auth);
    let result;
    if (yData.key === "CIP") {
      result = await getGraphDCSData('CIP.BOX' + curr_firebox + '.PRESSURE', c_furnaceId, accessToken).then((data) => data);
    }
    else {
      result = await getGraphDCSData('LAVAL_RATIO.BOX' + curr_firebox + '.VALUE', c_furnaceId, accessToken).then((data) => data);
    }
    if (result) {
      setDCSGraphData(result);
    }
  }

  /**
   * Get new data on change of scenario data, selected scenario, change of refence line or Y-Axis switch
   */

  useEffect(() => {
    const lr = yData.key === "LR" ? true : false;
    setData(generateTimechartData(yData.key, scenarios, selectedScenario, scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL, currentScenarioFireboxId, currentView));
    setDetailData(generateDetailTimechartData("PCG", scenarios, selectedScenario, referencePositionIndex, lr, currentScenarioFireboxId, currentView));
  }, [scenarios, selectedScenario, referencePositionIndex, yData, currentScenarioFireboxId, currentView]);

  const [firstLoad, setFirstLoad] = useState(true);
  const dispatch = useDispatch();

  /**
   * Resets reference line on first load
   */

  useEffect(() => {
    if (data.data.length > 0 && firstLoad) {
      dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: (data.data.length - 1) * parseFloat(scenarios ? String(scenarios[selectedScenario]?.INTERVAL) : "1") } });
      dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: data.data.length - 1 } });
      setFirstLoad(false);
    }
    if (firstLoad && currentScenarioIsRTA) {
      getGraphDCS();
    }
  }, [firstLoad, dispatch, scenarios, data, currentView, currentScenarioIsRTA]);

  useEffect(() => {
    if (currentScenarioIsRTA) {
      getGraphDCS();
    }
  }, [dispatch, scenarios, currentView, currentScenarioIsRTA, yData])

  const filterData = (data, day) => {
    let filter_data = data;
    let days = scenarios && scenarios[0]?.Days;
    let day1Index = days?.lastIndexOf('1') === -1 ? 0 : days && days.lastIndexOf('1') + 1;
    let day5Index = days?.lastIndexOf('5') === -1 ? day1Index : days && days.lastIndexOf('5') + 1;
    if ((day === "1" && !days?.includes('1')) || (day === "5" && !days?.includes('5'))) {
      day = 'MAX'
      setCurrentView('MAX');
    }
    filter_data = day === "1" ? filter_data?.slice(0, day1Index) : (day === "5" ? filter_data?.slice(day1Index, day5Index) : filter_data?.slice(day5Index))
    let newdcsGraphData = dcsGraphData.filter(y => y.Day === day);
    let array = newdcsGraphData?.map(obj => ({ runtime: obj.runtime, DCS: Number(obj.DCS), Datetime: obj.Datetime }))
    filter_data?.forEach(x => {
      const fixedPoint = day === "1" ? 2 : 1;
      const ele = array?.find(y => Number(y.runtime).toFixed(fixedPoint) === x.runtime);
      if (ele)
        x.DCS = ele.DCS
    })
    setFilteredData(filter_data);
    setLoading(false);
  }
  const [loader, setLoader] = React.useState(false)
  const setLoading = (val) => {
    setLoader(val);
  }
  const setDayView = (newDayView) => {
    setCurrentView(newDayView);
    filterData(data.data, newDayView);
  };

  useEffect(() => {
    if (yData.key === "LR") {
      const c = Math.max(...filtereddata.filter(d => d["DCS"]).map(o => o["DCS"]))
      setLrDCSMax(currentScenarioIsRTA ? c.toFixed(3).toString() : "N/A")
    }
    else {
      const c = Math.max(...filtereddata.filter(d => d["DCS"]).map(o => o["DCS"]))
      setCipDCSMax(currentScenarioIsRTA ? c.toFixed(3).toString() : "N/A")
      if (lrDCSMax === "0" && currentScenarioIsRTA)
        callLR()
    }
  }, [filtereddata])

  const callLR = async () => {
    const c_furnaceId = store.getState().furnace.current_furnace_id;
    const curr_firebox = store.getState().scenarioSimulator.currentScenarioFireboxId + 1;
    const accessToken = getToken(auth);
    const result = await getGraphDCSData('LAVAL_RATIO.BOX' + curr_firebox + '.VALUE', c_furnaceId, accessToken).then((data) => data);
    setfiltereddataForLR(result)
  }

  useEffect(() => {
    if (filtereddataForLR.length > 0) {
      const c = Math.max(...filtereddataForLR.filter(d => d["DCS"]).map(o => o["DCS"]))
      setLrDCSMax(currentScenarioIsRTA ? c?.toFixed(3).toString() : "N/A")
    }
  }, [filtereddataForLR])

  const handleYChange = (value) => {
    if (value === "Coil Inlet Pressure") {
      setYData({
        name: "Coil Inlet Pressure",
        key: "CIP",
        unit: "(bar g)",
        ToFixed: 3,
        y_axis_adjustment: [0, false]
      });
    } else {
      setYData({
        name: "Laval ratio",
        key: "LR",
        unit: "(-)",
        ToFixed: 3,
        y_axis_adjustment: [0.1, false],
      });
    }
    if (currentScenarioIsRTA)
      setCurrentView("MAX");
  };

  /**
 * Resets refernce line on scenario change
 */

  const handleScenarioChange = (newId) => {
    dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: (data.data.length - 1) * parseFloat(scenarios ? String(scenarios[selectedScenario]?.INTERVAL) : "1") } });
    dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: data.data.length - 1 } });
  };

  /**
   * Define decimal point for overview table
   * mention key and decimal point to display
   */
  let ToFixedOverViewTable: ToFixedI = {
    "runtime": currentScenarioIsRTA && currentView === "1" ? 2 : 1,
    [yData.key]: 3,
    'DCS': 3,
  }

  /**
   * Define decimal point for overview detail table
   * mention key and decimal point to display
   */
  let ToFixedOverViewDetailTable: ToFixedI = {
    "runtime": 1,
    "PCG": 3
  }

  return (
    <React.Fragment>
      {scenarios && scenarios.length === 0 && !isRTA ? <div>
        <p>Please run scenario simulaor.</p>
      </div> :
        <>
          {loader ? <div className="loader-grid-parent loader-overlay">
            <div className="loader-grid">
              <Loader />
            </div>
          </div> : null}

          <Box display="flex" className={loader ? "chartGridContainerLoader" : ""}>
            <Box mx={2} flexShrink={2}>
              <Box mb={2} width="100%">
                <PlantSelector disabled={getNumberOfPlants() === 1} />
                <FurnaceSelector disabled={false} setLoading={(val) => setLoading(val)} />
                {currentScenarioIsRTA || (scenarios && scenarios[selectedScenario].mode === MODE.HYBRID) ? <FireboxSelector setLoading={(val) => setLoading(val)} /> : <></>}
                <ScenarioRunLengthSelector disabled={false} onScenarioChange={(newId) => handleScenarioChange(newId)} />

                <CIPYSwitch onYChange={(e) => handleYChange(e)} />
              </Box>
              <Box width="100%">
                <OverviewTable currentView={currentView} data={currentScenarioIsRTA ? filtereddata : data.data} id="overview-table-one-third" keys={["runtime", "DCS", yData.key]} headers={["Run Length (Days)", `DCS ${yData.unit}`, ` LVF ${yData.unit}`]} className="l-table-overview-cip" ToFixed={ToFixedOverViewTable} />
              </Box>
              <Box mt={2} width="100%">
                <Box >
                  <CIPSummaryTable lr={yData.key === "LR"} cip_forecast={filtereddata[0]} cipDCSMax={cipDCSMax} lrDCSMax={lrDCSMax} selectedFirebox={currentScenarioFireboxId} />
                </Box>
                <Box mt={2} width="100%">
                  <SettingsTable />
                </Box>
              </Box>
            </Box>
            <Box width="100%" flexGrow={1}>
              <Grid item={true} container direction="column" alignItems="stretch" className={loader ? "chartGridContainerLoader" : ""}>
                <Grid>
                  <Timechart
                    data={currentScenarioIsRTA ? filtereddata : data.data}
                    keys={!(currentScenarioIsRTA) || (currentScenarioIsRTA && currentView !== "MAX") ? [yData.key, "DESIGN", "DCS"] : [yData.key, "DESIGN", "DCS", "FORECAST"]}
                    labels={["Run Length (Days)", yData.name + " " + yData.unit]}
                    key={data.id + "TC0"}
                    scenarioId={data.id}
                    height={375}
                    referenceLine
                    finalRunlength={scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL}
                    ToFixed={yData.ToFixed}
                    limitRange={true}
                    y_axis_adjustment_data={yData.y_axis_adjustment}
                    currentView={currentView}
                    dayViewChange={(newVal) => setDayView(newVal)}
                  />
                </Grid>
                <Grid>
                  <Timechart data={detailData.data} hideChartTooltip={true} keys={currentScenarioIsRTA ? ["PCG", "FORECAST"] : ["PCG"]} labels={["Coil Length (%)", "Coil Pressure (bar a)"]} key={data.id + "TC1"} scenarioId={data.id} height={375} ToFixed={yData.ToFixed} />
                </Grid>
              </Grid>
            </Box>
            <Box mx={2}>
              <Box width="104%">
                <OverviewTable data={detailData.data} id="overview-table-full" keys={["runtime", "PCG"]} headers={["Coil Length (%)", "Coil Pressure (bar a)"]} disableHighlight={true} className="l-table-detaildata" ToFixed={ToFixedOverViewDetailTable} />
              </Box>
            </Box>
          </Box>
        </>
      }
    </React.Fragment>
  );
};

export default withAuth(connector(CoilInletPressure));
