import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { RootState } from "../../reducers/rootReducer";
import { loadConvectionsectionData } from "../../services/convectionsectionServices";
import { addDefaultConvectionSectionData, loadAllConvectionSectionData } from "../../slices/convectionSectionSlice";
import { fetchConvectionSectionData } from "../../utilities/loadConvectionSection";
import LoadAutocomplete from "../common/LoadAutocomplete"
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";


interface ILoadConvectionSectionProps {
}

interface OIDCAuth {
    auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
    return {
        convectionSectiones: state.convectionsection.convectionsections,
        defaultConvectionSectionDatas: state.convectionsection.defaultConvectionSectionDatas
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadAllConvectionSectionData: (data: any) => dispatch(loadAllConvectionSectionData(data)),
        addDefaultConvectionSectionData: (data: any) => dispatch(addDefaultConvectionSectionData(data))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ILoadConvectionSectionReduxProps = PropsFromRedux & ILoadConvectionSectionProps & OIDCAuth;

type ILoadConvectionSectionState = {
    showDialog: boolean;
    selected: any[];
    allConvectionSectionData: any[];
    isAllSelected: boolean;
    showLoading: boolean;
}

class LoadConvectionSection extends Component<ILoadConvectionSectionReduxProps, ILoadConvectionSectionState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            selected: [],
            allConvectionSectionData: [],
            isAllSelected: false,
            showLoading: false
        }
    }

    async componentDidMount() {
        //await this.loadConvectionSectionDataInit();
    }

    async loadConvectionSectionDataInit() {
        this.setState({
            showLoading: true
        })
        try {
            const accessToken = getToken(this.props.auth);
            let setAllConvectionSectionData = await loadConvectionsectionData(accessToken);
            // TO BE USED IN FUTURE
            // let setAll = [{
            //     ...getDefaultFeedstock(),
            //     id: "-1",
            //     name: "All"
            // }];

            if (!!setAllConvectionSectionData && !!setAllConvectionSectionData.data && setAllConvectionSectionData.data.length > 0) {
                setAllConvectionSectionData.data.forEach((convectionSectionValue: IConvectionSection) => {
                    convectionSectionValue['is_saved'] = true;
                    convectionSectionValue['is_display_leave_popup'] = false;
                })
            }
            if (setAllConvectionSectionData.status === true) {
                // TO BE USED IN FUTURE
                // let setAllConvectionSectionDataNew = [...setAll, ...setAllConvectionSectionData.data]
                let setAllConvectionSectionDataNew = [...setAllConvectionSectionData.data]
                this.setState({
                    showLoading: false,
                    allConvectionSectionData: setAllConvectionSectionDataNew
                })
            } else {
                this.setState({
                    showLoading: false
                })
            }
        } catch (error) {
            this.setState({
                showLoading: false
            })
        }

    }


    toggleShowDialog = async () => {
        await this.setState({
            showDialog: !this.state.showDialog,
        })

        if (this.state.showDialog) {
            await this.loadConvectionSectionDataInit();
        }
    }

    setDefaultData = async (asset_name) => {
        const accessToken = getToken(this.props.auth);
        return new Promise(async resolve => {
            let defaultdata = await fetchConvectionSectionData(asset_name, accessToken);
            let setNewData = [...this.props.defaultConvectionSectionDatas, ...[defaultdata]]
            if (this.props.defaultConvectionSectionDatas.length === 0) {
                this.props.addDefaultConvectionSectionData(setNewData)
            } else {
                if (this.props.defaultConvectionSectionDatas.findIndex(f => f.asset_name === asset_name) === -1) {
                    this.props.addDefaultConvectionSectionData(setNewData)
                }
            }
            resolve(true)
        })
    }

    loadFeedstock = async () => {
        await this.setState({
            showLoading: true
        })
        let isAllSelected = this.state.selected.some(el => el.name.toLowerCase() === "all");

        if (isAllSelected) {
            let removeAllFromArray = this.state.allConvectionSectionData.filter(convectionsection => convectionsection.name.toLowerCase() !== "all");
            let filterData = removeAllFromArray.filter(convectionsection => {
                return this.props.convectionSectiones.filter(el => {
                    return el.id === convectionsection.id;
                }).length === 0
            });
            await this.props.loadAllConvectionSectionData(filterData)
        } else {
            // let defaultdata = await getDefaultConvectionSectionData(this.state.selected[0].asset_name);  // TO BE USED IN FUTURE
            await Promise.all(
                this.state.selected.map(async (element) => {
                    await this.setDefaultData(element.asset_name)
                })
            ).then(() => console.log('Waited enough!'));

            await this.props.loadAllConvectionSectionData(this.state.selected);
        }
        this.toggleShowDialog()
        setTimeout(async () => {
            await this.setState({
                selected: [],
                showLoading: false
            });
        }, 1000);
    }

    selectedDataSet = (valueData) => {
        this.setState({
            selected: valueData
        })
    }

    render() {
        return (
            <>
                <LoadAutocomplete
                    showDialog={this.state.showDialog}
                    selected={this.state.selected}
                    allData={this.state.allConvectionSectionData}
                    isAllSelected={this.state.isAllSelected}
                    showLoading={this.state.showLoading}
                    componentName={'Convectionsection'}
                    disableData={this.props.convectionSectiones}
                    toggleShowDialog={this.toggleShowDialog}
                    loadFeedstock={this.loadFeedstock}
                    selectedDataSet={this.selectedDataSet}

                ></LoadAutocomplete>
            </>
        );
    }

};

export default withAuth(connector(LoadConvectionSection));