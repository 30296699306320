import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { REACT_APP_APIM_URL_METADATA } from "../../utilities/GlobalConstants";
import { loadScenarioRunlengths } from "../../utilities/loadScenarioRunlengths";
import FurnaceSelector from "../common/FurnaceSelector";
import PlantSelector from "../common/PlantSelector";
import "../../assets/styles/_furnace-overview-dahej.scss"
import ScenarioRunLengthSelector from "../common/ScenarioRunLengthSelector";
import RunLengthSelector from "../common/RunLengthSelector";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";

export const FURNACE_OVERVIEW_STATIC_ID = "_FURNACE_OVERVIEW";
interface IFurnaceOverviewProps { }

interface OIDCAuth {
    auth?: AuthContextProps
}


const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        currentScenarioRunLengthId: state.scenarioSimulator.currentScenarioRunLengthId,
        currentFurnaceTypeId: state.scenarioSimulator.current_furnace_type_id,
        current_feedstock_id: state.feedstock.current_feedstock_id,
        currentFeedTypeId: state.scenarioSimulator.current_feed_type_id,
        convectionSections: state.convectionsection.convectionsections,
        furnaceState: state.furnace,
        token: state.authState.token,
        scenariosState: state.scenarioSimulator,
        feedstockState: state.feedstock,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFurnaceOverviewReduxProps = PropsFromRedux & IFurnaceOverviewProps & OIDCAuth;

type IFurnaceOverviewState = {
    designData: any[];
    weatherData: any[];
    dcsData: any[];
    lvfData: any[];
    designHidden: string;
    dscHidden: string;
    lvfHidden: string;
    lvfCheckboxHidden: boolean;
    assetstate: any[];
    assetName: string;
    assetList: any[];
    updated_feedtype_id: string;
    feedtype_tags: any[];
}

class FurnaceOverviewDahej extends Component<IFurnaceOverviewReduxProps, IFurnaceOverviewState> {
    constructor(props: any) {
        super(props);
        this.state = {
            designData: [],
            weatherData: [],
            dcsData: [],
            lvfData: [],
            assetstate: [],
            designHidden: this.props.feedstockState.showDesignData,
            dscHidden: "hidden",
            lvfHidden: "hidden",
            lvfCheckboxHidden: false,
            assetName: this.props.furnaceState.current_asset_name,
            assetList: [],
            updated_feedtype_id: this.props.currentFeedTypeId,
            feedtype_tags: []
        };
    }

    componentDidMount() {
        const accessToken = getToken(this.props.auth);
        this.getFeedtypeTags();
        loadScenarioRunlengths(accessToken);
        this.setState({
            assetName: this.props.furnaceState.current_asset_name
        });

        const currentScenarionRunlengthIndex = (this.props.scenariosState.scenarioRunlengths.findIndex(x => x.id === this.props.currentScenarioRunLengthId && x.furnaceId === this.props.currentFurnaceId));
        let currentScenario;

        currentScenario = this.props.scenariosState.scenarioRunlengths[currentScenarionRunlengthIndex]?.scenarios[0];
        let updated_feed = this.props.feedstockState?.feedstocks?.filter(x => x.id === currentScenario?.feedstock_id)[0]?.feed_type_id;
        if (updated_feed && Number(updated_feed) > 0) {
            this.setState({
                updated_feedtype_id: updated_feed
            }, () => { this.getDesignData(this.props.currentFurnaceTypeId, this.state.updated_feedtype_id) })
        }
        else {
            this.getDesignData(this.props.currentFurnaceTypeId, this.state.updated_feedtype_id)
        }


        this.getWeatherdata();
        this.setDecimalForTags();

        // this.setLVFVisibility(this.props.scenariosState.withConvections[0]);

    }
    getFeedtypeTags() {
        const feedtype_tags: any[] = [];

        const accessToken = getToken(this.props.auth);
        
        fetch(`${REACT_APP_APIM_URL_METADATA}/furnaces/feeddetails`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    // map object to array
                    for (const furnace of Object.entries(data)) {
                        feedtype_tags.push(furnace[1]);
                    }

                    this.setState({
                        feedtype_tags: feedtype_tags,
                    });

                    return feedtype_tags;
                },
                (error) => console.log("error", error)
            );
    }

    toggleLVFCheckboxHidden() {
        this.setState({
            lvfCheckboxHidden: !this.state.lvfCheckboxHidden
        })
    }

    toggleLVFHidden() {
        if (this.state.lvfHidden === "hidden") {
            this.setState({
                lvfHidden: "visible"
            })
        } else {
            this.setState({
                lvfHidden: "hidden"
            })
        }
    }
    convertToTag(tagname) {
        const furnaceNum = this.props.furnaceState.current_asset_name.split(".")[2].slice(-1);
        return (tagname.substring(tagname.indexOf('-') + 1)).replace('XX', '1' + furnaceNum);
    }


    setLVFVisibility(visible) {
        if (visible) {
            this.setState({
                lvfHidden: "visible",
                lvfCheckboxHidden: false,
            });
        } else {
            this.setState({
                lvfHidden: "hidden",
                lvfCheckboxHidden: true,
            });
        }
    }

    convertUnit(fromUnit, toUnit, data) {
        if (fromUnit.toLowerCase() === 'bar a' && toUnit.toLowerCase() === 'bar g') {
            return (Number(data) - 1.01325).toFixed(3)
        }
        return data;
    }


    /**
     * get detail data from assets 
     */
    getAssetState = async () => {
        const mappedArray: any[] = [];
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL_METADATA}/asset/assetstatus`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    // map object to array
                    for (const furnace of Object.entries(data)) {
                        mappedArray.push(furnace[1]);
                    }

                    this.setState({
                        assetstate: mappedArray,
                    });

                    return mappedArray;
                },
                (error) => console.log("error", error)
            );
    };

    /**
     * get detail design data per asset
     * @param assetname any
     */
    getDesignData = async (furnaectype_id, feeedtype_id) => {
        this.getAssetState();
        const desData: any[] = [];
        const accessToken = getToken(this.props.auth);
        await fetch(`${REACT_APP_APIM_URL_METADATA}/asset/lvfdesigndata/${furnaectype_id}/${feeedtype_id}`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    desData.push(data);

                    this.setState({
                        designData: desData,
                    });
                    return desData;
                },
                (error) => console.log("error", error)
            );
    };

    getWeatherdata = async () => {
        const weatherData: any[] = [];
        const plantname: String = this.props.currentPlantId;
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL_METADATA}/weather/` + plantname, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    weatherData.push(data);

                    this.setState({
                        weatherData: weatherData,
                    });

                    return weatherData;
                },
                (error) => console.log("error", error)
            );

    };


    /**
     * toggle design data
     */
    showDesignData() {
        let newHideState = "";

        // change state of hide/show data
        this.state.designHidden === "visible" ? (newHideState = "hidden") : (newHideState = "visible");

        //this.props.setDesignShow(newHideState);
        this.setState({
            designHidden: newHideState,
        });
    }

    /**
     * toggle dcs data
     */
    showDSCData() {
        let newHideState = "";

        // change state of hide/show data
        this.state.dscHidden === "visible" ? (newHideState = "hidden") : (newHideState = "visible");

        this.setState({
            dscHidden: newHideState,
        });
    }

    componentDidUpdate(prevProps) {

        const currentScenarionRunlengthIndex = (this.props.scenariosState.scenarioRunlengths.findIndex(x => x.id === this.props.currentScenarioRunLengthId && x.furnaceId === this.props.currentFurnaceId));
        let currentScenario = this.props.scenariosState.scenarioRunlengths[currentScenarionRunlengthIndex]?.scenarios[0];
        let updated_feedtype_id = this.props.feedstockState.feedstocks.filter(x => x.id === currentScenario?.firebox[0].feedstock_id)[0]?.feed_type_id
        updated_feedtype_id = updated_feedtype_id ? updated_feedtype_id : this.props.currentFeedTypeId ? this.props.currentFeedTypeId : this.state.updated_feedtype_id;
        if (this.state.updated_feedtype_id !== updated_feedtype_id || prevProps.currentFurnaceTypeId !== this.props.currentFurnaceTypeId) {
            this.getDesignData(this.props.currentFurnaceTypeId, updated_feedtype_id);
            this.setState({
                updated_feedtype_id: updated_feedtype_id
            }, () => {
                this.setDecimalForTags()
            })
        }
        else {
            this.setDecimalForTags()
        }

    }

    setDecimalForTags() {
        document.querySelectorAll('.furnace-overview div div p').forEach(element => {
            //3 decimal digit
            element.innerHTML = !isNaN(Number(element.innerHTML)) ? (Math.round(Number(element.innerHTML) * 1000) / 1000).toString() : (element.innerHTML === "N/A" ? "N/A" : element.innerHTML);
        });
        document.querySelectorAll('p.temp').forEach(element => {
            // 1 decimal digit
            element.innerHTML = !isNaN(Number(element.innerHTML)) ? (Math.round(Number(element.innerHTML) * 10) / 10).toString() : (element.innerHTML === "N/A" ? "N/A" : element.innerHTML);
        });
        document.querySelectorAll('p.flow').forEach(element => {
            // 1 decimal digit
            element.innerHTML = !isNaN(Number(element.innerHTML)) ? (Math.round(Number(element.innerHTML) * 10) / 10).toString() : (element.innerHTML === "N/A" ? "N/A" : element.innerHTML);
        });
        document.querySelectorAll('p.fh').forEach(element => {
            // 2 decimal digit
            element.innerHTML = !isNaN(Number(element.innerHTML)) ? (Math.round(Number(element.innerHTML) * 100) / 100).toString() : (element.innerHTML === "N/A" ? "N/A" : element.innerHTML);
        });
        document.querySelectorAll('p.pres').forEach(element => {
            // 2 decimal digit
            element.innerHTML = !isNaN(Number(element.innerHTML)) ? (Math.round(Number(element.innerHTML) * 100) / 100).toString() : (element.innerHTML === "N/A" ? "N/A" : element.innerHTML);
        });
    }

    shouldComponentUpdate(nextProps: any) {
        if (this.props.feedstockState !== nextProps.feedstockState) {
            this.setupData(nextProps);
        }
        return true;
    }

    setupData = async (props: any) => {
        let vis = "hidden";
        if (props.feedstockState.showLFVData === "visible" && props.feedstockState.convection === "true") { vis = "visible" }
        this.setState({
            lvfHidden: vis
        })

    }

    render() {
        //get cracking mode.
        let crackingMode = this.props.furnaceState.furnaces.filter((f) => f.id === this.props.furnaceState.current_asset_name)?.[0]?.["Cracking Mode"] ?? "";
        //filter it and set tag
        let ft_data = crackingMode.toLowerCase() === "c3c4" ? this.state.feedtype_tags.filter(f => f.key === "feedtype-tag" && f.name.toLowerCase() === "c3/c4")[0]
            : this.state.feedtype_tags.filter(f => f.key === "feedtype-tag" && f.name.toLowerCase() === crackingMode.toLowerCase())[0];
        let feedtype_data = crackingMode === "C3C4" ? "C3/C4 - Fraction" : crackingMode;
        let feedtype_tag = ft_data?.value;

        let furnanceValues: any = [];

        if (this.state.designData && this.state.designData.length > 0 && this.state.assetstate.length > 0 && this.state.designData[0] != null) {
            // get position of cosy data and store values in redux
            //ToDo for release > 1.2: make fields editable and update this values 
            const furnNumber = this.props.furnaceState.current_asset_name.split(".")[2].slice(-1);
            const selectedScenario = 0;
            furnanceValues = [];
            const selRun = this.props.scenariosState.currentRunLengthId;
            const currentScenarioRunLengthId = this.props.currentScenarioRunLengthId;

            const currentScenarionRunlengthIndex = (this.props.scenariosState.scenarioRunlengths.findIndex(x => x.id === currentScenarioRunLengthId && x.furnaceId === this.props.currentFurnaceId));
            let currentScenario;

            currentScenario = this.props.scenariosState.scenarioRunlengths[currentScenarionRunlengthIndex]?.scenarios[selectedScenario];

            const currentScenarioCalculation = currentScenario?.firebox[0].CALCULATIONS;

            const output_cosy_dict = currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_cosy_dict;
            const output_cosy_dict_bundle_data = output_cosy_dict?.COSY?.PERFORMANCE.BUNDLE_DATA;
            const output_cosy_dict_laval_data = output_cosy_dict?.COSY?.PERFORMANCE.LAVAL_DATA;
            const output_cosy_dict_quenching_data = output_cosy_dict?.COSY?.PERFORMANCE.QUENCHING_DATA;
            const output_cosy_dict_combustion_data = output_cosy_dict?.COSY?.PERFORMANCE.COMBUSTION_DATA;


            const current_Convectionsection_Id = currentScenario?.convectionsection_id;
            const currConvectionSection = this.props.convectionSections?.filter(c => c.id === current_Convectionsection_Id);
            const output_cosy_dict_battery_limit = currConvectionSection[0]?.components[0];

            if (this.state.designData[0].statusCode === 404) window.location.href = "/";

            // map data fit to crackingmode and tagname
            this.state.designData[0].forEach(
                (tagName, i: number) => {
                    // FIC-210
                    if (tagName.lvftagname === "BOILER_FEED_WATER.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_flow">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data?.BUNDLE2?.FLOW : "N/A"}</p>
                            </div>
                        );
                    }
                    // 2614-PI-407
                    if (tagName.lvftagname === "BOILER_FEED_WATER.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_press">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_battery_limit ? this.convertUnit(output_cosy_dict_battery_limit.BFW_PRESSURE.tag_unit, 'bar g', output_cosy_dict_battery_limit.BFW_PRESSURE.design) : "N/A"}</p>
                            </div>
                        );
                    }
                    // 2614-TI-603
                    if (tagName.lvftagname === "BOILER_FEED_WATER.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_battery_limit ? output_cosy_dict_battery_limit.BFW_TEMPERATURE.design : "N/A"}</p>
                            </div>
                        );
                    }
                    // 11001

                    if (tagName.lvftagname === "FEED.BOX1.FLOW") {
                        furnanceValues.push(
                            <div>
                                <p className={"feed_flow_tag"}>{this.convertToTag(tagName.tags)}</p>

                                <div key={i} className="feed_flow_ad">
                                    <p className={this.state.designHidden}>{tagName.design}</p>
                                    <p className={this.state.dscHidden}>{tagName.design}</p>
                                    <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE1.FLOW / 2 : "N/A"}</p>

                                </div>
                            </div>
                        );
                    }
                    // 2125-PIC-414
                    if (tagName.lvftagname === "FEED.BOX1.PRESSURE") {
                        furnanceValues.push(
                            <div>
                                <p className={"feed_flow_pres"}>{this.convertToTag(tagName.tags)}</p>
                                <div key={i + "AD"} className="feed_press_ad">
                                    <p className={this.state.designHidden}>{tagName.design}</p>
                                    <p className={this.state.dscHidden}>{tagName.design}</p>
                                    <p className={this.state.lvfHidden + " pres"}>
                                        {output_cosy_dict_battery_limit ? this.convertUnit(output_cosy_dict_battery_limit.FEED_PRESSURE.tag_unit, 'bar g', output_cosy_dict_battery_limit.FEED_PRESSURE.design) : "N/A"}</p>
                                </div>
                            </div>
                        );
                    }
                    //T-21403
                    if (tagName.lvftagname === "FEED.BOX1.TEMPERATURE") {
                        furnanceValues.push(
                            <div>
                                <p className={"feed_flow_temp"}>{this.convertToTag(tagName.tags)}</p>
                                <div key={i + "AD"} className="feed_temp_ad">
                                    <p className={this.state.designHidden}>{tagName.design}</p>
                                    <p className={this.state.dscHidden}>{tagName.design}</p>
                                    <p className={this.state.lvfHidden + " temp"}>
                                        {output_cosy_dict_battery_limit ? output_cosy_dict_battery_limit.FEED_TEMPERATURE.design : "N/A"}</p>
                                </div>
                            </div>
                        );
                    }
                    // 11007
                    if (tagName.lvftagname === "DILUTION.BOX1.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="dilut_flow_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_bundle_data ? (output_cosy_dict_bundle_data.BUNDLE5.FLOW - output_cosy_dict_bundle_data.BUNDLE1.FLOW) / 2 : "N/A"}</p>
                            </div>
                        );
                    }
                    // 28301
                    if (tagName.lvftagname === "DILUTION.PRESSURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="dilut_press_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_battery_limit ? this.convertUnit(output_cosy_dict_battery_limit.DILUTION_STEAM_PRESSURE.tag_unit, 'bar g', output_cosy_dict_battery_limit.DILUTION_STEAM_PRESSURE.design) : "N/A"}</p>
                            </div>
                        );
                    }
                    // 28321
                    if (tagName.lvftagname === "DILUTION.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="dilut_temp_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_battery_limit ? output_cosy_dict_battery_limit.DILUTION_STEAM_TEMPERATURE.design : "N/A"}</p>
                            </div>
                        );
                    }
                    // FI-200
                    if (tagName.lvftagname === "BOILER_FEED_WATER_STEAM_DESUPER.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_desuper">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_INJ && currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_INJ.length ? currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_INJ[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-641-644
                    if (tagName.lvftagname === "CG_POT.BOX1.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_pot_temp_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_quenching_data ? output_cosy_dict_quenching_data.EXCHANGER1.CG_TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-631-634
                    if (tagName.lvftagname === "CG_COT.BOX1.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_cot_temp_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COT && currentScenarioCalculation[selRun].output_dict.COT.length ? currentScenarioCalculation[selRun].output_dict.COT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-401
                    if (tagName.lvftagname === "BUNDLE7.BOX1.PRESSURE_HTC2_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle_press_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_laval_data ? this.convertUnit('bar a', 'bar g', output_cosy_dict_laval_data.UPSTREAM_PRESSURE) : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-621
                    if (tagName.lvftagname === "BUNDLE6.BOX1.TEMPERATURE_HTC2_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle_temp_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE8.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-420 Todo
                    if (tagName.lvftagname === "FLUE_GAS.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas_press">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{"0.7"}</p>
                            </div>
                        );
                    }
                    // TI-640
                    if (tagName.lvftagname === "FLUE_GAS1.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas_ad_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE8.FLUEGAS_TEMPERATURE_INLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // FI-261
                    if (tagName.lvftagname === "FUEL.BOX1.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="fuel_flow_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_combustion_data ? output_cosy_dict_combustion_data.FUELGAS_CONSUMPTION / 2 : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-451
                    if (tagName.lvftagname === "FUEL.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="fuel_press_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_battery_limit ? this.convertUnit(output_cosy_dict_battery_limit.FUELGAS_PRESSURE.tag_unit, 'bar g', output_cosy_dict_battery_limit.FUELGAS_PRESSURE.design) : "N/A"}</p>
                            </div>
                        );
                    }
                    // 2184-TI-601
                    if (tagName.lvftagname === "FUEL.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="fuel_temp_ad">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_battery_limit ? output_cosy_dict_battery_limit.FUELGAS_TEMPERATURE.design : "N/A"}</p>
                            </div>
                        );
                    }
                    // FI-265
                    if (tagName.lvftagname === "FUEL.BOX2.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="fuel_flow_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_combustion_data ? output_cosy_dict_combustion_data.FUELGAS_CONSUMPTION / 2 : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-635-638
                    if (tagName.lvftagname === "CG_COT.BOX2.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_cot_temp_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COT && currentScenarioCalculation[selRun].output_dict.COT.length ? currentScenarioCalculation[selRun].output_dict.COT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-450
                    if (tagName.lvftagname === "CG_COP1.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_cop_press">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_PQE_POUT && currentScenarioCalculation[selRun].output_dict.COSY_PQE_POUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_PQE_POUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-650
                    if ((Number(furnNumber) < 4 && tagName.lvftagname === "CG_SOT.TEMPERATURE") || (Number(furnNumber) > 3 && tagName.lvftagname === "CG_QUENCH_TEMPERATURE")) {
                        furnanceValues.push(
                            <div key={i} className="cg_sot_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_quenching_data && output_cosy_dict_quenching_data.EXCHANGER2?.CG_TEMPERATURE_OUTLET ? output_cosy_dict_quenching_data.EXCHANGER2?.CG_TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-645-658
                    if (tagName.lvftagname === "CG_POT.BOX2.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_pot_temp_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_quenching_data ? output_cosy_dict_quenching_data.EXCHANGER1?.CG_TEMPERATURE_OUTLET ?? "N/A" : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-401
                    if (tagName.lvftagname === "BUNDLE7.BOX2.PRESSURE_HTC2_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle_press_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_laval_data ? this.convertUnit('bar a', 'bar g', output_cosy_dict_laval_data.UPSTREAM_PRESSURE) : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-625
                    if (tagName.lvftagname === "BUNDLE6.BOX2.TEMPERATURE_HTC2_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle_temp_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE8.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-482
                    if (tagName.lvftagname === "HP_STEAM.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="hp_stream_press">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_bundle_data ? this.convertUnit('bar a', 'bar g', output_cosy_dict_bundle_data.BUNDLE7.PRESSURE_OUTLET) : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-660
                    if (tagName.lvftagname === "HP_STEAM.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="hp_stream_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE7.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    if (tagName.lvftagname === "HP_STEAM.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="hp_stream_flow">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE7.FLOW : "N/A"}</p>
                            </div>
                        );
                    }


                    // FI-225
                    if (tagName.lvftagname === "DILUTION.BOX2.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="dilut_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_bundle_data ? Number((output_cosy_dict_bundle_data.BUNDLE5.FLOW - output_cosy_dict_bundle_data.BUNDLE1.FLOW) / 2).toFixed(3) : "N/A"}</p>
                            </div>
                        );
                    }
                    // 2130-PI-415
                    if (tagName.lvftagname === "DILUTION.PRESSURE") {
                        furnanceValues.push(
                            <div key={i + "EH"} className="dilut_press_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_battery_limit ? this.convertUnit(output_cosy_dict_battery_limit.DILUTION_STEAM_PRESSURE.tag_unit, 'bar g', output_cosy_dict_battery_limit.DILUTION_STEAM_PRESSURE.design) : "N/A"}</p>
                            </div>
                        );
                    }
                    // 2130-PI-618
                    if (tagName.lvftagname === "DILUTION.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "EH"} className="dilut_temp_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_battery_limit ? output_cosy_dict_battery_limit.DILUTION_STEAM_TEMPERATURE.design : "N/A"}</p>
                            </div>
                        );
                    }
                    // FIC-205
                    if (tagName.lvftagname === "FEED.BOX2.FLOW") {
                        furnanceValues.push(
                            <div>
                                <p className={"feed_flow2_tag"}>{this.convertToTag(tagName.tags)}</p>
                                <div key={i} className="feed_flow_eh">
                                    <p className={this.state.designHidden}>{tagName.design}</p>
                                    <p className={this.state.dscHidden}>{tagName.design}</p>
                                    <p className={this.state.lvfHidden + " flow"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE1.FLOW / 2 : "N/A"}</p>
                                </div>
                            </div>
                        );
                    }
                    // 2125-PIC-414
                    if (tagName.lvftagname === "FEED.BOX2.PRESSURE") {
                        furnanceValues.push(
                            <div>
                                <p className={"feed_flow2_pres"}>{this.convertToTag(tagName.tags)}</p>
                                <div key={i + "EH"} className="feed_press_eh">
                                    <p className={this.state.designHidden}>{tagName.design}</p>
                                    <p className={this.state.dscHidden}>{tagName.design}</p>
                                    <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_battery_limit ? this.convertUnit(output_cosy_dict_battery_limit.FEED_PRESSURE.tag_unit, 'bar g', output_cosy_dict_battery_limit.FEED_PRESSURE.design) : "N/A"}</p>
                                </div>
                            </div>
                        );
                    }
                    // 2125-TIC-608
                    if (tagName.lvftagname === "FEED.BOX2.TEMPERATURE") {
                        furnanceValues.push(
                            <div>
                                <p className={"feed_flow2_temp"}>{this.convertToTag(tagName.tags)}</p>
                                <div key={i + "EH"} className="feed_temp_eh">
                                    <p className={this.state.designHidden}>{tagName.design}</p>
                                    <p className={this.state.dscHidden}>{tagName.design}</p>
                                    <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_battery_limit ? output_cosy_dict_battery_limit.FEED_TEMPERATURE.design : "N/A"}</p>
                                </div>
                            </div>
                        );
                    }
                    // TI-800
                    if (tagName.lvftagname === "FLUE_GAS4.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas_temp_eh">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE1.FLUEGAS_TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-601
                    if (Number(furnNumber) < 4 && tagName.lvftagname === "BUNDLE1.TEMPERATURE_FPH2_OUT") {
                        furnanceValues.push(
                            <div key={i} className="fuel2_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE3.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    if (Number(furnNumber) > 3 && tagName.lvftagname === "BUNDLE1.TEMPERATURE_DSS_OUT") {
                        furnanceValues.push(
                            <div key={i} className="fuel2_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE4.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-605
                    if (Number(furnNumber) < 4 && tagName.lvftagname === "BUNDLE2.TEMPERATURE_FPH2_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle2_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE3.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    if (Number(furnNumber) > 3 && tagName.lvftagname === "BUNDLE2.TEMPERATURE_FPH_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle2_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE3.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }

                    if (Number(furnNumber) > 3 && tagName.lvftagname === "BUNDLE1.TEMPERATURE_FPH_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle1_dss_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE3.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    if (Number(furnNumber) > 3 && tagName.lvftagname === "CG_Quench_OIL.FLOW_PRI") {
                        furnanceValues.push(
                            <div key={"quench_flow_pri"} className={`${Number(furnNumber) > 3 ? "quench_flow_pri" : "displ-hide"}`}>
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{"N/A"}</p>
                            </div>
                        );
                    }
                    if (Number(furnNumber) > 3 && tagName.lvftagname === "CG_Quench_OIL.FLOW_SEC") {
                        furnanceValues.push(
                            <div key={"quench_flow_sec"} className="quench_flow_sec">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " flow"}>{"N/A"}</p>
                            </div>
                        );
                    }
                    if (Number(furnNumber) > 3 && tagName.lvftagname === "BUNDLE2.TEMPERATURE_DSS_OUT") {
                        furnanceValues.push(
                            <div key={i} className="bundle2_dss_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE4.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }


                    //11126
                    if (tagName.lvftagname === "FLUE_GAS3.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas3_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE5.FLUEGAS_TEMPERATURE_INLET : "N/A"}</p>
                            </div>
                        );
                    }



                    // 11104
                    if (tagName.lvftagname === "HPS_TEMP_US_DESUPR") {
                        furnanceValues.push(
                            <div key={"i- ti690"} className="bundle3_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE6.TEMPERATURE_OUTLET : "N/A"}</p>
                            </div>
                        );
                    }
                    //11114
                    if (tagName.lvftagname === "HPS_TEMP_DS_DESUPR") {
                        furnanceValues.push(
                            <div key={"i -ti610"} className="bundle4_temp">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE7.TEMPERATURE_INLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // AI-031 Excess_AIR.A-D.O2.CONC
                    if (tagName.lvftagname === "BUNDLE5.BOX1.TEMPERATURE_HTC1_OUT") {
                        furnanceValues.push(
                            <div key={"i-ai031"} className="excess_air_ad_o2">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE8.TEMPERATURE_INLET : "N/A"}</p>
                            </div>
                        );
                    }

                    // FIRED HEAT
                    if (tagName.lvftagname === "COSY_FIRED_HEAT") {
                        furnanceValues.push(
                            <div key={"i - firesh"} className="firedHeat">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " fh"}> {output_cosy_dict_combustion_data ? output_cosy_dict_combustion_data.FIRED_HEAT : "N/A"}</p>
                            </div>
                        );
                    }

                    // AI-035 Excess_AIR.E-H.O2.CONC
                    if (tagName.lvftagname === "BUNDLE5.BOX2.TEMPERATURE_HTC1_OUT") {
                        furnanceValues.push(
                            <div key={"i - ai035"} className="excess_air_eh_o2">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " temp"}>{output_cosy_dict_bundle_data ? output_cosy_dict_bundle_data.BUNDLE8.TEMPERATURE_INLET : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-480 CG_COP2.PRESSURE

                    if ((Number(furnNumber) < 4 && tagName.lvftagname === "CG_SOP_PRESSURE") || (Number(furnNumber) > 3 && tagName.lvftagname === "CG_QUENCH_PRESSURE")) {
                        furnanceValues.push(
                            <div key={"i pi480"} className="cg_cop2_press">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_quenching_data && output_cosy_dict_quenching_data.EXCHANGER2?.CG_PRESSURE_OUTLET ? this.convertUnit('bar a', 'bar g', output_cosy_dict_quenching_data.EXCHANGER2?.CG_PRESSURE_OUTLET) : "N/A"}</p>
                            </div>
                        );
                    }

                    // new 
                    if (tagName.lvftagname === "FLUE_GAS.EXCESS_AIR.O2.CONC") {
                        furnanceValues.push(
                            <div key={"a-11116"} className="flue_gas_excess_air">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden}>{"1.66"}</p>
                            </div>
                        );
                    }
                    if (tagName.lvftagname === "CG_POP.PRESSURE") {
                        furnanceValues.push(
                            <div key={"CG_POP.PRESSURE"} className={` ${Number(furnNumber) > 3 ? "cg_pop_pressure_v2" : "cg_pop_pressure"}`}>
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_quenching_data ? this.convertUnit('bar a', 'bar g', output_cosy_dict_quenching_data.EXCHANGER1.CG_PRESSURE_OUTLET) : "N/A"}</p>
                            </div>
                        );
                    }


                    if (tagName.lvftagname === "FIRED_HEAT") {
                        furnanceValues.push(
                            <div key={"fired_heat"} className="fired_heat">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " fh"}>{output_cosy_dict_combustion_data ? output_cosy_dict_combustion_data.FIRED_HEAT : "N/A"}</p>
                            </div>
                        );
                    }


                    if (tagName.lvftagname === "CIP.BOX1.PRESSURE") {
                        furnanceValues.push(
                            <div key={"CIP.BOX1.PRESSURE"} className="cip_box1_pres">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{currentScenarioCalculation ? currentScenarioCalculation[selRun]?.output_dict?.CIP[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    //p-11121
                    if (tagName.lvftagname === "LAVAL_RATIO.BOX1.VALUE") {
                        furnanceValues.push(
                            <div key={"p-11121"} className="laval_ratio_box1">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_laval_data ? output_cosy_dict_laval_data.LAVAL_RATIO : "N/A"}</p>
                            </div>
                        );
                    }

                    if (tagName.lvftagname === "CIP.BOX2.PRESSURE") {
                        furnanceValues.push(
                            <div key={"CIP.BOX2.PRESSURE"} className="cip_box2_pres">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{currentScenarioCalculation ? currentScenarioCalculation[selRun]?.output_dict?.CIP[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    //11131
                    if (tagName.lvftagname === "LAVAL_RATIO.BOX2.VALUE") {
                        furnanceValues.push(
                            <div key={"p-11131"} className="laval_ratio_box2">
                                <p className={this.state.designHidden}>{tagName.design}</p>
                                <p className={this.state.dscHidden}>{tagName.design}</p>
                                <p className={this.state.lvfHidden + " pres"}>{output_cosy_dict_laval_data ? output_cosy_dict_laval_data.LAVAL_RATIO : "N/A"}</p>
                            </div>
                        );
                    }
                    if (tagName.lvftagname === "FEED.TYPE") {
                        furnanceValues.push(
                            <div key={"feedtype_box1"} className="feed_type_box1">
                                <p>{feedtype_data}</p>
                                <p>{feedtype_tag}</p>
                            </div>
                        );
                    }
                    if (tagName.lvftagname === "FEED.TYPE") {
                        furnanceValues.push(
                            <div key={"feedtype_box2"} className="feed_type_box2">
                                <p>{feedtype_data}</p>
                                <p>{feedtype_tag}</p>
                            </div>
                        );
                    }

                },
                furnanceValues.push(
                    <div key={"tagName"} className="tag_name">
                        11-BL-10{`${furnNumber}`}
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_right_name"} className={`${Number(furnNumber) > 3 ? "bottom_right_name_v2" : "bottom_right_name"}`}>
                        11-VV-10{`${furnNumber}`}
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_right_name_2"} className={`${Number(furnNumber) > 3 ? "displ-hide" : "bottom_right_name_2"}`}>
                        11-EE-10{`${furnNumber}`}
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_left_barell"} className="bottom_left_barell">
                        11-EU-10{`${furnNumber}`}A-D
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_right_barell"} className="bottom_right_barell">
                        11-EU-10{`${furnNumber}`}E-H
                    </div>
                ),
                furnanceValues.push(
                    <div key={"quench"} className={`${Number(furnNumber) > 3 ? "quench" : "displ-hide"}`}>
                        11-WI-10{`${furnNumber}`}
                    </div>
                ),


                furnanceValues.push(
                    <div key={"weather"} className="weather">
                        <p>{this.state.weatherData[0] ? "T : " + this.state.weatherData[0].temperature : "T : N/A"}</p>
                        <p>{this.state.weatherData[0] ? "P : " + this.state.weatherData[0].pressure : "P : N/A"}</p>
                        <p>{this.state.weatherData[0] ? "Humidity : " + this.state.weatherData[0].humidity : "Humidity : N/A"}</p>
                    </div>
                )
            );

        }
        return (
            <div className="overall-dahej" >
                <div className="scenario-simulator-overview">
                    <div className="control-header">
                        <PlantSelector disabled={true} />
                        <FurnaceSelector disabled={false} />
                        <ScenarioRunLengthSelector disabled={false} onScenarioChange={null} />
                        <RunLengthSelector disabled={false} />
                    </div>

                    <div>
                        <div className="furnace-overview-dahej">
                            {/* <SvgLoader path={furnaceImage_1} width="100%">
                                <SvgProxy selector="#F-XX102">{"F-11102"}</SvgProxy>
                            </SvgLoader> */}
                            <div className={`furnace-overview--wrapper furnace-img-${Number(this.props.currentFurnaceId)}`}>
                                <div className="checkbox" id="checkbox">
                                    <br />
                                    <input type="checkbox" name="showDesignData" value="designShowing" checked={this.state.designHidden === "visible"} onChange={this.showDesignData.bind(this)} />
                                    <br />
                                    <input className="noEdit" type="checkbox" name="showDCSData" value="dcsShowing" disabled checked={this.state.dscHidden === "visible"} onChange={this.showDSCData.bind(this)} />
                                    <br />
                                    <input disabled={this.state.lvfCheckboxHidden} type="checkbox" name="showLVFData" value="lvfShowing" checked={this.state.lvfHidden === "visible"} onChange={this.toggleLVFHidden.bind(this)} />
                                </div>
                                {furnanceValues}
                            </div>
                        </div>
                    </div >
                </div>
            </div>
        )
    }
}

export default withAuth(connector(FurnaceOverviewDahej));