import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { BarLoader } from 'react-spinners';
import { IProductPredictorGroup, IProductPredictorScenario } from '../../interfaces/IProductPredictor';
import { SCENARIO_PROGRESS, SCENARIO_STATUS } from '../../interfaces/IScenario';
import { RootState } from '../../reducers/rootReducer';
import AddGroupConfiguration from './addGroupConfiguration';
import ProductPredictorFeedstockConfig from './feedstockConfiguration';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { setPPScenarioStatus, updatePPScenarioCaseId } from '../../slices/productPredictorSlice';
import { stopCalculation } from '../../utilities/productpredictorCalculator';
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface IGroupCardProductPredictor {
    scenario: IProductPredictorScenario,

}
interface OIDCAuth {
    auth?: AuthContextProps
 }
const mapStateToProps = (state: RootState) => {
    return {
        token: state.authState.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPPScenarioStatus: (scenario_id: string, status: SCENARIO_STATUS) => dispatch(setPPScenarioStatus({ scenario_id: scenario_id, status: status })),
        updatePPScenarioCaseId: (scenario_id: string, caseId: string) => dispatch(updatePPScenarioCaseId({ scenario_id: scenario_id, caseId: caseId })),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

type IGroupCardProductPredictorReduxProps = IGroupCardProductPredictor & PropsFromRedux & OIDCAuth;

type IGroupCardProductPredictorState = {
    groupList: IProductPredictorGroup[]
}

class GroupCardProductPredictor extends Component<IGroupCardProductPredictorReduxProps, IGroupCardProductPredictorState> {

    constructor(props) {
        super(props);
        this.state = {
            groupList: this.props.scenario.groups,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scenario !== this.props.scenario) {
            this.setState({
                groupList: this.props.scenario.groups,
            })
        }
    }

    async handleStopCalculation(scenario: IProductPredictorScenario) {
        //set reset calculation progress
        if (scenario.status === SCENARIO_STATUS.RUNNING) {
            this.props.setPPScenarioStatus(scenario.id, SCENARIO_STATUS.STOP_CALCULATION);
            this.props.updatePPScenarioCaseId(scenario.id, "");
        }
        // Stop backend computaion if started
        const accessToken = getToken(this.props.auth);
        stopCalculation(scenario, accessToken)
    }

    getProgressbarVal() {
        return Math.max(this.props.scenario.progress, 0)
    }

    getProgressbarText() {
        return this.props.scenario.scenario_progress === SCENARIO_PROGRESS.Looping
            ? (Number(this.props.scenario.progress) > 0 ? this.props.scenario.progress.toString() + " %" : "Calculating")
            : this.props.scenario.scenario_progress;
    }

    render() {
        return (
            <div>
                {(this.props.scenario.status === SCENARIO_STATUS.RUNNING)
                    ? <Paper className="">
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" >
                            <div className="btnStopScenario"> <button
                                className=" button margin-right margin-top button-stop"
                                onClick={() => this.handleStopCalculation(this.props.scenario)}
                            >
                                Stop Calculation
                            </button></div>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" paddingRight={2}>
                            <div className="circularProgressbar pp-circular-progress">
                                <CircularProgressbar
                                    styles={buildStyles({
                                        // Text size
                                        textSize: '16px'
                                    })} value={this.getProgressbarVal()} text={this.getProgressbarText()} />
                            </div>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="center" paddingRight={2}>
                            <Box display="flex" className="bar-loader">
                                <BarLoader color="#36B0D7" />
                            </Box>
                        </Box>
                    </Paper>
                    : <div />}
                {this.state.groupList.map((element, index) => (
                    <div className={"scenario-runlength-card "}>
                        {(this.props.scenario.status === SCENARIO_STATUS.RUNNING) ? <Paper className="calculating">
                        </Paper> : <div />}
                        <Paper className="paper" elevation={3}>
                            <div className="scenario-runlength-content">
                                <Grid container spacing={2}>
                                    <Grid xs={1} item={true} >
                                        <div className="furnace-item-icon">
                                            <svg viewBox="0 0 370 440" enableBackground="new 0 0 370 440;" x="0px" y="0px">
                                                <polygon
                                                    className="st0"
                                                    fill={
                                                        "rgb(0, 155, 60)"
                                                    }
                                                    points="263.4,215 263.4,9.1 106.1,9.1 106.1,215 8.2,215 8.2,430.9 106.1,430.9 106.1,360 263.4,
                          360 263.4,430.9 361.8,430.9 361.8,215"
                                                />
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid xs={9} item={true}>

                                        <div className="right-column">
                                            <div className="header">
                                                <Grid container >
                                                    <Grid xs={4} item={true}>
                                                        <Typography>{element.grouplabel} Furnace / Max Number {element.maxFurnace_number}</Typography>
                                                    </Grid>
                                                    <Grid xs={5} alignContent={'stretch'}>
                                                        <Typography><b>{'Fresh Feed(s) & Recycles'}</b></Typography>
                                                    </Grid>
                                                    {/* <Grid container xs={3} spacing={0} justifyContent="flex-end" direction="row">
                                                    <Box pr={1}>
                                                        {element.recyclelabels?.map(rl => (
                                                            <Typography>{rl} Recycle</Typography>
                                                        ))}</Box>
                                                </Grid> */}
                                                </Grid>
                                            </div>
                                            <Grid container className={'right-container'}>
                                                <Grid xs={4} item={true}>
                                                    <AddGroupConfiguration grpName={element.name} grpLabel={element.grouplabel!} grpMaxFurnace={element.maxFurnace_number!} />

                                                </Grid>
                                                <Grid xs={6} item={true}>
                                                    <ProductPredictorFeedstockConfig ProductPredictorElement={element} groupIndex={index} />
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid container xs={2} spacing={0} justifyContent="center" direction="column">
                                        <Box pr={2}>
                                            {element.recyclelabels?.map(rl => (
                                                <div className={'lblRecycle'}><Typography>{rl}</Typography></div>
                                            ))}</Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper> </div>
                ))}
            </div>)
    }
}

export default withAuth(connector(GroupCardProductPredictor))