import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { REACT_APP_APIM_URL_METADATA } from "../../utilities/GlobalConstants";
import { loadScenarioRunlengths } from "../../utilities/loadScenarioRunlengths";
import FurnaceSelector from "../common/FurnaceSelector";
import PlantSelector from "../common/PlantSelector";
import { getToken } from "../../utilities/helperFunctions";
import { AuthContextProps, withAuth } from "react-oidc-context";


// const FurnaceOverviewImage = require("../../assets/images/furnace-overview.svg");

export const FURNACE_OVERVIEW_STATIC_ID = "_FURNACE_OVERVIEW";
interface IFurnaceOverviewProps { }

interface OIDCAuth {
    auth?: AuthContextProps
  }

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        currentScenarioRunLengthId: state.scenarioSimulator.currentScenarioRunLengthId,
        furnaceState: state.furnace,
        token: state.authState.token,
        scenariosState: state.scenarioSimulator,
        feedstockState: state.feedstock,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFurnaceOverviewReduxProps = PropsFromRedux & IFurnaceOverviewProps & OIDCAuth;

type IFurnaceOverviewState = {
    designData: any[];
    weatherData: any[];
    dcsData: any[];
    lvfData: any[];
    designHidden: string;
    dscHidden: string;
    lvfHidden: string;
    lvfCheckboxHidden: boolean;
    assetstate: any[];
    assetName: string;
    assetList: any[];
}

class FurnaceOverview extends Component<IFurnaceOverviewReduxProps, IFurnaceOverviewState> {
    constructor(props: any) {
        super(props);
        this.state = {
            designData: [],
            weatherData: [],
            dcsData: [],
            lvfData: [],
            assetstate: [],
            designHidden: this.props.feedstockState.showDesignData,
            dscHidden: "hidden",
            lvfHidden: "hidden",
            lvfCheckboxHidden: false,
            assetName: this.props.furnaceState.current_asset_name,
            assetList: [],
        };
    }

    componentDidMount() {
        const accessToken = getToken(this.props.auth);
        loadScenarioRunlengths(accessToken);
        this.setState({
            assetName: this.props.furnaceState.current_asset_name
        });
        this.getDesignData(this.props.furnaceState.current_asset_name);
        this.getWeatherdata();
        // this.setLVFVisibility(this.props.scenariosState.withConvections[0]);

    }

    toggleLVFCheckboxHidden() {
        this.setState({
            lvfCheckboxHidden: !this.state.lvfCheckboxHidden
        })
    }

    toggleLVFHidden() {
        if (this.state.lvfHidden === "hidden") {
            this.setState({
                lvfHidden: "visible"
            })
        } else {
            this.setState({
                lvfHidden: "hidden"
            })
        }
    }


    setLVFVisibility(visible) {
        if (visible) {
            this.setState({
                lvfHidden: "visible",
                lvfCheckboxHidden: false,
            });
        } else {
            this.setState({
                lvfHidden: "hidden",
                lvfCheckboxHidden: true,
            });
        }
    }

    // checkValidFurnace = (id) => {
    //     // const selectedFurnaceObj = this.props.furnaceState.furnaces.find((f) => f.id === id);
    // };

    /**
     * get detail data from assets 
     */
    getAssetState = async () => {
        const mappedArray: any[] = [];
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL_METADATA}/asset/assetstatus`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    // map object to array
                    for (const furnace of Object.entries(data)) {
                        mappedArray.push(furnace[1]);
                    }

                    this.setState({
                        assetstate: mappedArray,
                    });

                    return mappedArray;
                },
                (error) => console.log("error", error)
            );
    };

    /**
     * get detail design data per asset
     * @param assetname any
     */
    getDesignData = async (assetname) => {
        this.getAssetState();
        const desData: any[] = [];
        const accessToken = getToken(this.props.auth);
        await fetch(`${REACT_APP_APIM_URL_METADATA}/asset/lvfdesigndata/${assetname}`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    desData.push(data);

                    this.setState({
                        designData: desData,
                    });
                    return desData;
                },
                (error) => console.log("error", error)
            );
    };

    getWeatherdata = async () => {
        const weatherData: any[] = [];
        const plantname: String = this.props.currentPlantId;
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL_METADATA}/weather/` + plantname, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    weatherData.push(data);

                    this.setState({
                        weatherData: weatherData,
                    });

                    return weatherData;
                },
                (error) => console.log("error", error)
            );

    };


    /**
     * toggle design data
     */
    showDesignData() {
        let newHideState = "";

        // change state of hide/show data
        this.state.designHidden === "visible" ? (newHideState = "hidden") : (newHideState = "visible");

        //this.props.setDesignShow(newHideState);
        this.setState({
            designHidden: newHideState,
        });
    }

    /**
     * toggle dcs data
     */
    showDSCData() {
        let newHideState = "";

        // change state of hide/show data
        this.state.dscHidden === "visible" ? (newHideState = "hidden") : (newHideState = "visible");

        this.setState({
            dscHidden: newHideState,
        });
    }

    /**
     * select asset by selectbox
     * @param selectedFurnace any
     */
    handleChange(selectedFurnace) {
        // this.checkValidFurnace(selectedFurnace.id);
        this.getDesignData(selectedFurnace.id);
        this.getWeatherdata();
        this.setState({
            assetName: selectedFurnace.DisplayName,
        });
    }

    shouldComponentUpdate(nextProps: any) {
        if (this.props.feedstockState !== nextProps.feedstockState) {
            this.setupData(nextProps);
        }
        return true;
    }

    setupData = async (props: any) => {
        let vis = "hidden";
        if (props.feedstockState.showLFVData === "visible" && props.feedstockState.convection === "true") { vis = "visible" }
        this.setState({
            lvfHidden: vis
        })

    }

    render() {
        var data;
        var dataDes;
        var dataAD;
        var dataADDes;
        var dataEH;
        var dataEHDes;

        if (this.state.assetstate.length > 0) {
            const idx = this.props.furnaceState.furnaces.findIndex((f) => f.id === this.props.furnaceState.current_asset_name);
            var crackingMode = this.state.assetstate[idx]["Cracking Mode"];

            switch (crackingMode) {
                case "C2C3":
                    data = "c3m_data";
                    dataDes = "c3m_des";
                    dataAD = "c3m_data";
                    dataADDes = "c3m_des";
                    dataEH = "c3m_data";
                    dataEHDes = "c3m_des";
                    break;
                //if Tag eh = c3p, then c3m
                case "C2C3/C3C4":
                    data = "c3m_data";
                    dataDes = "c3m_des";
                    dataAD = "c3m_data";
                    dataADDes = "c3m_des";
                    dataEH = "c3p_data";
                    dataEHDes = "c3p_des";
                    break;
                //   if tag = ad = c3p, then c3m
                case "C3C4/C2C3":
                    data = "c3m_data";
                    dataDes = "c3m_des";
                    dataAD = "c3p_data";
                    dataADDes = "c3p_des";
                    dataEH = "c3m_data";
                    dataEHDes = "c3m_des";
                    break;
                case "C3C4":
                    data = "c3p_data";
                    dataDes = "c3p_des";
                    dataAD = "c3p_data";
                    dataADDes = "c3p_des";
                    dataEH = "c3p_data";
                    dataEHDes = "c3p_des";
                    break;
                default:
                    data = "c3m_data";
                    dataDes = "c3m_des";
                    dataAD = "c3m_data";
                    dataADDes = "c3m_des";
                    dataEH = "c3m_data";
                    dataEHDes = "c3m_des";
                    break;
            }
        }

        const furnanceValues: any = [];

        if (this.state.designData && this.state.designData.length > 0 && this.state.assetstate.length > 0 && this.state.designData[0] != null) {
            // get position of cosy data and store values in redux
            //ToDo for release > 1.2: make fields editable and update this values 
            const furnNumber = this.props.furnaceState.current_asset_name.split(".")[2];
            const selectedScenario = 0;
            const selRun = this.props.scenariosState.currentRunLengthId;
            const currentScenarioRunLengthId = this.props.currentScenarioRunLengthId;
            // const IDX_COSY_BFW_TEMPERATURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_BFW_TEMPERATURE");
            // const IDX_COSY_BFW_PRESSURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_BFW_PRESSURE");
            // const IDX_COSY_HPS_TEMPERATURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_HPS_TEMPERATURE");
            // const IDX_COSY_HPS_PRESSURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_HPS_PRESSURE");
            // const IDX_COSY_FD_TEMPERATURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_FD_TEMPERATURE");
            // const IDX_COSY_FD_PRESSURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_FD_PRESSURE");
            // const IDX_COSY_DS_TEMPERATURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_DS_TEMPERATURE");
            // const IDX_COSY_DS_PRESSURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_DS_PRESSURE");
            // const IDX_COSY_FG_TEMPERATURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_FG_TEMPERATURE");
            // const IDX_COSY_FG_PRESSURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_FG_PRESSURE");
            // const IDX_COSY_CG_EX_PRESSURE = this.state.designData[0].findIndex((f) => f.lvftagname === "COSY_CG_EX_PRESSURE");

            const currentScenarionRunlengthIndex = (this.props.scenariosState.scenarioRunlengths.findIndex(x => x.id === currentScenarioRunLengthId));
            let currentScenario;

            currentScenario = this.props.scenariosState.scenarioRunlengths[currentScenarionRunlengthIndex]?.scenarios[selectedScenario];

            const currentScenarioCalculation = currentScenario?.CALCULATIONS;

            // [selectedScenario].CALCULATIONS   
            if (this.state.designData[0].statusCode === 404) window.location.href = "/";

            // map data fit to crackingmode and tagname
            this.state.designData[0].forEach(
                (tagName, i: number) => {
                    // FIC-210
                    if (tagName.lvftagname === "BOILER_FEED_WATER_STEAM_GEN.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_flow">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_ECO && currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_ECO.length ? currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_ECO[0][0] : "N/A"} </p>
                            </div>
                        );
                    }
                    // 2614-PI-407
                    if (tagName.lvftagname === "BOILER_FEED_WATER.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_press">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_BFW_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_BFW_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // 2614-TI-603
                    if (tagName.lvftagname === "BOILER_FEED_WATER.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_BFW_TEMPERATURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_BFW_TEMPERATURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // FIC-201
                    if (tagName.lvftagname === "FEED.A-D.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="feed_flow_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>

                                <p className={this.state.lvfHidden}>{currentScenario?.FLOW_HC ? currentScenario?.FLOW_HC / 2 : "N/A"}</p>
                            </div>
                        );
                    }
                    // 2125-PIC-414
                    if (tagName.lvftagname === "FEED.A-D.PRESSURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="feed_press_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>
                                    {this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // 2125-TIC-608
                    if (tagName.lvftagname === "FEED.A-D.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="feed_temp_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>
                                    {this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_TEMPERATURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_TEMPERATURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // FI-221
                    if (tagName.lvftagname === "DILUTION.A-D.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="dilut_flow_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>{Number(currentScenario?.DILUT * currentScenario?.FLOW_HC / 2).toFixed(2)}</p>
                            </div>
                        );
                    }
                    // 2130-PI-415
                    if (tagName.lvftagname === "DILUTION.PRESSURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="dilut_press_ad">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // 2130-TI-618
                    if (tagName.lvftagname === "DILUTION.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="dilut_temp_ad">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_TEMPERATURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_TEMPERATURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // FI-200
                    if (tagName.lvftagname === "BOILER_FEED_WATER_STEAM_DESUPER.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="boild_feed_water_desuper">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_INJ && currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_INJ.length ? currentScenarioCalculation[selRun].output_dict.COSY_BFW_TO_INJ[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-641-644
                    if (tagName.lvftagname === "CG_POT.A-D.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_pot_temp_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_PQE_TOUT && currentScenarioCalculation[selRun].output_dict.COSY_PQE_TOUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_PQE_TOUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-631-634
                    if (tagName.lvftagname === "CG_COT.A-D.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_cot_temp_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COT && currentScenarioCalculation[selRun].output_dict.COT.length ? currentScenarioCalculation[selRun].output_dict.COT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-401
                    if (tagName.lvftagname === "BUNDLE7.A-D.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="bundle_press_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_LAVAL_UPSTREAM_P && currentScenarioCalculation[selRun].output_dict.COSY_LAVAL_UPSTREAM_P.length ? currentScenarioCalculation[selRun].output_dict.COSY_LAVAL_UPSTREAM_P[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-621
                    if (tagName.lvftagname === "BUNDLE6.A-D.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="bundle_temp_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_XOVER_TEMPERATURE && currentScenarioCalculation[selRun].output_dict.COSY_XOVER_TEMPERATURE.length ? currentScenarioCalculation[selRun].output_dict.COSY_XOVER_TEMPERATURE[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-420 Todo
                    if (tagName.lvftagname === "FLUE_GAS.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas_press">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FG_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FG_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // TI-640
                    if (tagName.lvftagname === "FLUE_GAS1.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas_ad_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_BRIDGEWALL_T && currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_BRIDGEWALL_T.length ? currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_BRIDGEWALL_T[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // FI-261
                    if (tagName.lvftagname === "FUEL.A-D.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="fuel_flow_ad">
                                <p className={this.state.designHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataAD][dataADDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FUELGAS_CONSUMPTION && currentScenarioCalculation[selRun].output_dict.COSY_FUELGAS_CONSUMPTION.length ? Number(currentScenarioCalculation[selRun].output_dict.COSY_FUELGAS_CONSUMPTION[0][0] / 2).toFixed(2) : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-451
                    if (tagName.lvftagname === "FUEL.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="fuel_press_ad">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FG_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FG_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // 2184-TI-601
                    if (tagName.lvftagname === "FUEL.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "AD"} className="fuel_temp_ad">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FG_TEMPERATURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FG_TEMPERATURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // FI-265
                    if (tagName.lvftagname === "FUEL.E-H.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="fuel_flow_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FUELGAS_CONSUMPTION && currentScenarioCalculation[selRun].output_dict.COSY_FUELGAS_CONSUMPTION.length ? Number(currentScenarioCalculation[selRun].output_dict.COSY_FUELGAS_CONSUMPTION[0][0] / 2).toFixed(2) : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-635-638
                    if (tagName.lvftagname === "CG_COT.E-H.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_cot_temp_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COT && currentScenarioCalculation[selRun].output_dict.COT.length ? currentScenarioCalculation[selRun].output_dict.COT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-450
                    if (tagName.lvftagname === "CG_COP1.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_cop_press">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_PQE_POUT && currentScenarioCalculation[selRun].output_dict.COSY_PQE_POUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_PQE_POUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-650
                    if (tagName.lvftagname === "CG_SOT.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_sot_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_SQE_TOUT && currentScenarioCalculation[selRun].output_dict.COSY_SQE_TOUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_SQE_TOUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-645-658
                    if (tagName.lvftagname === "CG_POT.E-H.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="cg_pot_temp_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_PQE_TOUT && currentScenarioCalculation[selRun].output_dict.COSY_PQE_TOUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_PQE_TOUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-401
                    if (tagName.lvftagname === "BUNDLE7.E-H.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="bundle_press_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_LAVAL_UPSTREAM_P && currentScenarioCalculation[selRun].output_dict.COSY_LAVAL_UPSTREAM_P.length ? currentScenarioCalculation[selRun].output_dict.COSY_LAVAL_UPSTREAM_P[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-625
                    if (tagName.lvftagname === "BUNDLE6.E-H.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="bundle_temp_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_XOVER_TEMPERATURE && currentScenarioCalculation[selRun].output_dict.COSY_XOVER_TEMPERATURE.length ? currentScenarioCalculation[selRun].output_dict.COSY_XOVER_TEMPERATURE[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // PI-482
                    if (tagName.lvftagname === "HP_STEAM.PRESSURE") {
                        furnanceValues.push(
                            <div key={i} className="hp_stream_press">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_HPS_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_HPS_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // TI-660
                    if (tagName.lvftagname === "HP_STEAM.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="hp_stream_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_HPS_TEMPERATURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_HPS_TEMPERATURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // FI-225
                    if (tagName.lvftagname === "DILUTION.E-H.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="dilut_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{Number(currentScenario?.DILUT * currentScenario?.FLOW_HC / 2).toFixed(2)}</p>
                            </div>
                        );
                    }
                    // 2130-PI-415
                    if (tagName.lvftagname === "DILUTION.PRESSURE") {
                        furnanceValues.push(
                            <div key={i + "EH"} className="dilut_press_eh">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // 2130-PI-618
                    if (tagName.lvftagname === "DILUTION.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "EH"} className="dilut_temp_eh">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_TEMPERATURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_DS_TEMPERATURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // FIC-205
                    if (tagName.lvftagname === "FEED.E-H.FLOW") {
                        furnanceValues.push(
                            <div key={i} className="feed_flow_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenario?.FLOW_HC ? currentScenario?.FLOW_HC / 2 : "N/A"}</p>
                            </div>
                        );
                    }
                    // 2125-PIC-414
                    if (tagName.lvftagname === "FEED.E-H.PRESSURE") {
                        furnanceValues.push(
                            <div key={i + "EH"} className="feed_press_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // 2125-TIC-608
                    if (tagName.lvftagname === "FEED.E-H.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i + "EH"} className="feed_temp_eh">
                                <p className={this.state.designHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[dataEH][dataEHDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_TEMPERATURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_FD_TEMPERATURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }
                    // TI-800
                    if (tagName.lvftagname === "FLUE_GAS4.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas_temp_eh">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_FNC_TOUT && currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_FNC_TOUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_FNC_TOUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-601
                    if (tagName.lvftagname === "BUNDLE1.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="fuel2_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FPH2_TOUT && currentScenarioCalculation[selRun].output_dict.COSY_FPH2_TOUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_FPH2_TOUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-605
                    if (tagName.lvftagname === "BUNDLE2.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="bundle2_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FPH2_TOUT && currentScenarioCalculation[selRun].output_dict.COSY_FPH2_TOUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_FPH2_TOUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // 21-T-620
                    if (tagName.lvftagname === "FLUE_GAS3.TEMPERATURE") {
                        furnanceValues.push(
                            <div key={i} className="flue_gas3_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_HPSS1_TOUT && currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_HPSS1_TOUT.length ? currentScenarioCalculation[selRun].output_dict.COSY_FLUEGAS_HPSS1_TOUT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // TI-690 || TI-610???
                    if (tagName.lvftagname === "COSY_HPS_TEMP_US_DESUPR") {
                        furnanceValues.push(
                            <div key={"i- ti690"} className="bundle3_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_HPS_TEMP_US_DESUPR && currentScenarioCalculation[selRun].output_dict.COSY_HPS_TEMP_US_DESUPR.length ? currentScenarioCalculation[selRun].output_dict.COSY_HPS_TEMP_US_DESUPR[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    //TI-610 
                    if (tagName.lvftagname === "COSY_HPS_TEMP_DS_DESUPR") {
                        furnanceValues.push(
                            <div key={"i -ti610"} className="bundle4_temp">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_HPS_TEMP_DS_DESUPR && currentScenarioCalculation[selRun].output_dict.COSY_HPS_TEMP_DS_DESUPR.length ? currentScenarioCalculation[selRun].output_dict.COSY_HPS_TEMP_DS_DESUPR[0][0] : "N/A"}</p>
                            </div>
                        );
                    }
                    // AI-031 Excess_AIR.A-D.O2.CONC
                    if (tagName.lvftagname === "EXCESS_AIR.A-D.O2.CONC") {
                        furnanceValues.push(
                            <div key={"i-ai031"} className="excess_air_ad_o2">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{tagName[data][dataDes]}</p>
                            </div>
                        );
                    }
                    // AI-033 Excess_AIR.Min.O2.CONC
                    if (tagName.lvftagname === "EXCESS_AIR.A-D.O2.CONC") {
                        furnanceValues.push(
                            <div key={"i - ai033"} className="excess_air_min_o2">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{tagName[data][dataDes]}</p>
                            </div>
                        );
                    }
                    // FIRED HEAT
                    if (tagName.lvftagname === "COSY_FIRED_HEAT") {
                        furnanceValues.push(
                            <div key={"i - firesh"} className="firedHeat">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}> {currentScenarioCalculation?.length > 0 && currentScenarioCalculation[selRun] && currentScenarioCalculation[selRun].output_dict.COSY_FIRED_HEAT && currentScenarioCalculation[selRun].output_dict.COSY_FIRED_HEAT.length ? currentScenarioCalculation[selRun].output_dict.COSY_FIRED_HEAT[0][0] : "N/A"}</p>
                            </div>
                        );
                    }

                    // AI-035 Excess_AIR.E-H.O2.CONC
                    if (tagName.lvftagname === "EXCESS_AIR.E-H.O2.CONC") {
                        furnanceValues.push(
                            <div key={"i - ai035"} className="excess_air_eh_o2">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{tagName[data][dataDes]}</p>
                            </div>
                        );
                    }
                    // PI-480 CG_COP2.PRESSURE
                    if (tagName.lvftagname === "COSY_CG_EX_PRESSURE") {
                        furnanceValues.push(
                            <div key={"i pi480"} className="cg_cop2_press">
                                <p className={this.state.designHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.dscHidden}>{tagName[data][dataDes]}</p>
                                <p className={this.state.lvfHidden}>{this.props.scenariosState.convectionSections?.length > 0 ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_CG_EX_PRESSURE"] ? this.props.scenariosState.convectionSections[selectedScenario]["COSY_CG_EX_PRESSURE"][data][dataDes] : "-" : "-"}</p>
                            </div>
                        );
                    }

                },
                furnanceValues.push(
                    <div key={"tagName"} className="tag_name">
                        21-BL-{`${furnNumber}01`}
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_mid_name"} className="bottom_mid_name">
                        21-H-{`${furnNumber}01`}
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_right_name"} className="bottom_right_name">
                        21-V-{`${furnNumber}01`}
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_right_name_2"} className="bottom_right_name_2">
                        21-E-{`${furnNumber}02`}
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_left_barell"} className="bottom_left_barell">
                        21-E-{`${furnNumber}01`}A-D
                    </div>
                ),
                furnanceValues.push(
                    <div key={"bottom_right_barell"} className="bottom_right_barell">
                        21-E-{`${furnNumber}01`}E-H
                    </div>
                ),
                furnanceValues.push(
                    <div key={"c2/c3_ad_fraction"} className="c2_c3_ad_fraction">
                        <p>{data === "c3m_data" ? "C2/C3 Fraction" : "C3/C4 Fraction"}</p>
                        <p>{data === "c3m_data" ? "21-E-2503" : "21-E-2504 A/B"}</p>
                    </div>
                ),
                furnanceValues.push(
                    <div key={"c2/c3_eh_fraction"} className="c2_c3_eh_fraction">
                        <p>{data === "c3m_data" ? "C2/C3 Fraction" : "C3/C4 Fraction"}</p>
                        <p>{data === "c3m_data" ? "21-E-2503" : "21-E-2504 A/B"}</p>
                    </div>
                ),
                furnanceValues.push(
                    <div key={"weather"} className="weather">
                        <p>{this.state.weatherData[0] ? "T : " + this.state.weatherData[0].temperature : "T : N/A"}</p>
                        <p>{this.state.weatherData[0] ? "P : " + this.state.weatherData[0].pressure : "P : N/A"}</p>
                        <p>{this.state.weatherData[0] ? "Humidity : " + this.state.weatherData[0].humidity : "Humidity : N/A"}</p>
                    </div>
                )
            );

        }
        return (
            <div className="overall">
                <div className="scenario-simulator-overview">
                    <div className="control-header">
                        <PlantSelector disabled={true} />
                        <FurnaceSelector disabled={true} />
                    </div>
                    {/* <img src={FurnaceOverviewImage.default} alt=""/> */}
                    <div>
                        <div className="furnace-overview">
                            <div className="furnace-overview--wrapper">
                                <div className="checkbox" id="checkbox">
                                    <br />
                                    <input type="checkbox" name="showDesignData" value="designShowing" checked={this.state.designHidden === "visible"} onChange={this.showDesignData.bind(this)} />
                                    <br />
                                    <input className="noEdit" type="checkbox" name="showDCSData" value="dcsShowing" disabled checked={this.state.dscHidden === "visible"} onChange={this.showDSCData.bind(this)} />
                                    <br />
                                    <input disabled={this.state.lvfCheckboxHidden} type="checkbox" name="showLVFData" value="lvfShowing" checked={this.state.lvfHidden === "visible"} onChange={this.toggleLVFHidden.bind(this)} />
                                </div>
                                {furnanceValues}
                            </div>
                        </div>
                    </div >
                </div>
            </div>
        )
    }
}

export default withAuth(connector(FurnaceOverview));