import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import OverviewTable from "../coil/overviewTable";
import SettingsTable from "../coil/settingsTable";
import FurnaceSelector from "../common/FurnaceSelector";
import PlantSelector from "../common/PlantSelector";
import ScenarioRunLengthSelector from "../common/ScenarioRunLengthSelector";
import Timechart from "../timechart";
import { getNumberOfPlants,getTemperatureUnit } from "../../configuration";
import { CoilObjectI, ToFixedI } from "../../interfaces/coil";
import { RootState } from "../../reducers/rootReducer";
import { generateTimechartData } from "../../utilities/coilModelShapers";
import { isRTA, getGraphDCSData, getToken } from "../../utilities/helperFunctions";
import Loader from "react-spinners/ClipLoader";
import FireboxSelector from "../common/FireboxSelector";
import store from "../..";
import { MODE } from "../../interfaces/IScenario";
import { AuthContextProps, withAuth } from "react-oidc-context";

export const COIL_OUTLET_TEMPERATURE_DASHBOARD_ID = "_COIL_OUTLET_TEMPERATURE";

interface ICoilOutletTemperatureProps { }

interface OIDCAuth {
  auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId,
    features: state.features.accessibleFeatures,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICoilOutletTemperatureReduxProps = PropsFromRedux & ICoilOutletTemperatureProps & OIDCAuth;


const CoildOutletTemperature: React.SFC<ICoilOutletTemperatureReduxProps> = ({ scenarios, features, currentScenarioIsRTA, currentScenarioFireboxId, auth }) => {
  const selectedScenario: number = 0;
  const [isScenarioChanged, setIsScenarioChanged] = React.useState(false)
  const [dcsGraphData, setDCSGraphData] = useState([{
    runtime: "0",
    Day: "0",
    TagName: "0",
    DCS: "0",
    Datetime: "0"
  }]);
  const [currentView, setCurrentView] = React.useState('MAX');
  const [data, setData] = useState({
    id: 0,
    name: "",
    data: [],
  } as CoilObjectI);

  const [filtereddata, setFilteredData] = useState([]);
  /**
   * Get new data whenever scenario data ochange or scenario is changed
   */
  const [loader, setLoader] = React.useState(false)
  const setLoading = (val) => {
    setLoader(val);
  }

  useEffect(() => {
    setData(generateTimechartData("COT", scenarios, selectedScenario, scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL, currentScenarioFireboxId, currentView));
  }, [scenarios, selectedScenario, currentView, currentScenarioFireboxId]);

  const dispatch = useDispatch();

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    filterData(data.data, currentView);
    setIsScenarioChanged(true);
  }, [dcsGraphData,currentView,data.data])

  const getGraphDCS = async () => {
    setLoading(true)
    const c_furnaceId = store.getState().furnace.current_furnace_id;
    const curr_firebox = store.getState().scenarioSimulator.currentScenarioFireboxId + 1;
    const accessToken = getToken(auth);
    const result = await getGraphDCSData('CG_COT.BOX' + curr_firebox + '.TEMPERATURE_OUTER', c_furnaceId, accessToken).then((data) => data);
    if (result) {
      setDCSGraphData(result);
    }
  }
  /**
  * Resets reference line on first visit
  */
  useEffect(() => {
    if (data.data.length > 0 && firstLoad) {
      dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: (data.data.length - 1) * (scenarios ? scenarios[selectedScenario].INTERVAL : 1) } });
      dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: data.data.length - 1 } });
      setFirstLoad(false);
    }
    if (currentScenarioIsRTA) {
      getGraphDCS();
    }
  }, [data, dispatch, firstLoad, scenarios, currentView, currentScenarioIsRTA]);

  /**
   * On scenario change reset reference line
   */


  const setDayView = (newDayView) => {
    setCurrentView(newDayView);
    filterData(data.data, newDayView);
  };
  const filterData = async (data, day) => {
    let filter_data = data;
    let days = scenarios && scenarios[0]?.Days;
    let day1Index = days?.lastIndexOf('1') === -1 ? 0 : days && days.lastIndexOf('1') + 1;
    let day5Index = days?.lastIndexOf('5') === -1 ? day1Index : days && days.lastIndexOf('5') + 1;
    if ((day === "1" && !days?.includes('1')) || (day === "5" && !days?.includes('5'))) {
      day = 'MAX'
      setCurrentView('MAX');
    }
    filter_data = day === "1" ? filter_data?.slice(0, day1Index) : (day === "5" ? filter_data?.slice(day1Index, day5Index) : filter_data?.slice(day5Index));
    let newdcsGraphData = dcsGraphData.filter(y => y.Day === day);
    let array = newdcsGraphData?.map(obj => ({ runtime: obj.runtime, DCS: Number(obj.DCS), Datetime: obj.Datetime }))
    filter_data?.forEach(x => {
      const fixedPoint = day === "1" ? 2 : 1;
      const ele = array?.find(y => Number(y.runtime).toFixed(fixedPoint) === x.runtime);
      if (ele)
        x.DCS = ele.DCS
    })
    setFilteredData(filter_data);
    setLoading(false);
  }

  const handleScenarioChange = () => {
    dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: (data.data.length - 1) * (scenarios ? scenarios[selectedScenario].INTERVAL : 1) } });
    dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: data.data.length - 1 } });
  };

  /**
   * Define decimal point for overview table
   * mention key and decimal point to display
   */
  let ToFixedOverViewTable: ToFixedI = {
    "runtime": currentScenarioIsRTA && currentView === "1" ? 2 : 1,
    "COT": 1,
    "DCS": 1
  }

  return (
    <React.Fragment>
      {scenarios && scenarios?.length === 0 && !isRTA ? <div>
        <p>Please run scenario simulaor.</p>
      </div> :
        <>
          {loader ? <div className="loader-grid-parent loader-overlay">
            <div className="loader-grid">
              <Loader />
            </div>
          </div> : null}
          <Box display="flex" className={loader ? "chartGridContainerLoader" : ""}>
            <Box mx={2} flexShrink={2}>
              <Box mb={2} width="100%">
                <PlantSelector disabled={getNumberOfPlants() === 1} />
                <FurnaceSelector disabled={false} setLoading={(val) => setLoading(val)} />
                {currentScenarioIsRTA || (scenarios && scenarios[0].mode === MODE.HYBRID) ? <FireboxSelector setLoading={(val) => setLoading(val)} /> : <></>}
                <ScenarioRunLengthSelector disabled={false} scenarioChanged={isScenarioChanged} onScenarioChange={() => handleScenarioChange()} />
              </Box>
              <Box width="100%">

                <OverviewTable currentView={currentView} data={currentScenarioIsRTA ? filtereddata : data.data} keys={["runtime", "DCS", "COT"]} headers={["Run Length (Days)", "DCS ("+ getTemperatureUnit() +")", "LVF ("+ getTemperatureUnit() +")"]} id="overview-table-full" className="l-table-overview-cot-cd"
                  ToFixed={ToFixedOverViewTable}
                />
              </Box>
            </Box>
            <Box width="100%" flexShrink={1}>

              <Grid item={true} container direction="column" alignItems="stretch">
                <Grid >
                  <Timechart
                    data={currentScenarioIsRTA ? filtereddata : data.data}
                    keys={!currentScenarioIsRTA || (currentScenarioIsRTA && currentView !== "MAX") ? ["COT", "DESIGN", "DCS"] : ["COT", "DESIGN", "DCS", "FORECAST"]}
                    labels={["Run Length (Days)", "COT (" + getTemperatureUnit() + ")"]}
                    key={data.id}
                    scenarioId={data.id}
                    height={525}
                    referenceLine
                    finalRunlength={scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL}
                    limitRange={true}
                    currentView={currentView}
                    dayViewChange={(newVal) => setDayView(newVal)}
                  />
                </Grid>

                <Grid style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16 }}>
                  <Grid container justifyContent="space-between">
                    <Grid xs={4} item={true}>
                    </Grid>
                    <Grid xs={4} item={true}>
                      <SettingsTable />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      }
    </React.Fragment>
  );
};

export default withAuth(connector(CoildOutletTemperature));
