import React, { Component } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from "@material-ui/core";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";

import { deleteScenarioRunlength } from "../../slices/scenarioSimulatorSlice";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { deleteScenarioRunlengthById } from "../../services/scenarioSimulatorServices";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface IDeleteScenarioRunlengthProps {
    scenarioRunlength: IScenarioRunlength;
}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(deleteScenarioRunlength(scenarioRunlength)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDeleteScenarioRunlengthReduxProps = PropsFromRedux & IDeleteScenarioRunlengthProps & OIDCAuth;

type IDeleteScenarioRunlengthState = {
    showDialog: boolean;
    message: string;
    showLoading: boolean;
}
class DeleteScenarioRunlength extends Component<IDeleteScenarioRunlengthReduxProps, IDeleteScenarioRunlengthState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            message: "",
            showLoading: false,
        }
    }

    toggleShowDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog,
        })
    }

    deleteScenario = async () => {
        this.setState({
            showLoading: true,
        })
        const accessToken = getToken(this.props.auth);
        let deleteFromDb = await deleteScenarioRunlengthById(this.props.scenarioRunlength, accessToken);
        if (deleteFromDb.status) {
            this.props.deleteScenarioRunlength(this.props.scenarioRunlength);
            this.setState({
                message: deleteFromDb.message
            })
        }
        setTimeout(() => {
            this.toggleShowDialog()
            setTimeout(() => {
                this.setState({
                    message: "",
                    showLoading: false,
                })
            }, 200);
        }, 1000);
    }

    render() {
        return (
            <>
                <button
                    className="button margin-right"
                    onClick={() => this.toggleShowDialog()}
                    disabled={!this.props.scenarioRunlength.is_saved}
                >
                    Delete
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete Scenario</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this scenario?
                        </DialogContentText>
                        <Typography variant="body1" component="p" color="secondary">
                            {this.state.message}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.deleteScenario()} disabled={this.state.showLoading}>
                            Delete {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

};

export default withAuth(connector(DeleteScenarioRunlength));