import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IGraphSlice = {
    predicted_runlength_start : number
    predicted_runlength_end: number
    totalRunlengthDays: number
}

const initialState: IGraphSlice = {
    predicted_runlength_start : 0,
    predicted_runlength_end : 0,
    totalRunlengthDays: 0
}

export const graph = createSlice({
    name: 'graph',
    initialState: initialState,
    reducers: {
        setPredictedRunlengthStart: (state, action: PayloadAction<number>) => {
            state.predicted_runlength_start = action.payload;
        },
        setPredictedRunlengthEnd: (state, action: PayloadAction<number>) => {
            state.predicted_runlength_end = action.payload;
        },
        setTotalRunlengthDays: (state, action: PayloadAction<number>) => {
            state.totalRunlengthDays = action.payload;
        }
    }
})

export const {
    setPredictedRunlengthStart,
    setPredictedRunlengthEnd,
    setTotalRunlengthDays
} = graph.actions


export default graph.reducer
