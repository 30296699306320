import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { getPlantReferences, getDefaultFuelGasComposition } from '../../configuration'
import { UNIT } from '../../interfaces/IComponent'
import { IConvectionSection } from '../../interfaces/IConvectionSection'
import { IFeedstock } from '../../interfaces/IFeedstock'
import { FUELGAS_STATUS, IFuelGas } from '../../interfaces/IFuelGas'
import { IProductPredictorScenario } from '../../interfaces/IProductPredictor'
import { SCENARIO_STATUS } from '../../interfaces/IScenario'
import { RootState } from '../../reducers/rootReducer'
import { getConvectionSectionById } from '../../services/convectionsectionServices'
import { getFeedstockById } from '../../services/feedstockServices'
import { getFuelGasById } from '../../services/fuelgasServices'
import { loadProductPredictorData } from '../../services/productPredictorServices'
import { loadAllConvectionSectionData, modifyConvectionSection } from '../../slices/convectionSectionSlice'
import { loadAllFeedStockData, modifyFeedstocks } from '../../slices/feedstockSlice'
import { loadAllFuelGasData, modifyFuelGas } from '../../slices/fuelGasSlice'
import { loadAllProductPredictorData } from '../../slices/productPredictorSlice'
import LoadAutocomplete from '../common/LoadAutocomplete'
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface ILoadProductPredictorProps {

}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        productPredictorScenarios: state.productPredictor.productPredictorScenarios,
        fuelGasesData: state.fuelGas.fuelGases,
        feedstocksData: state.feedstock.feedstocks,
        convectionSectionsData: state.convectionsection.convectionsections,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadAllProductPredictorData: (data: any) => dispatch(loadAllProductPredictorData(data)),
        loadAllFuelGasData: (data: any) => dispatch(loadAllFuelGasData(data)),
        loadAllFeedStockData: (data: any) => dispatch(loadAllFeedStockData(data)),
        loadAllConvectionSectionData: (data: any) => dispatch(loadAllConvectionSectionData(data)),
        modifyFuelGas: (newFuelGas: IFuelGas) => dispatch(modifyFuelGas(newFuelGas)),
        modifyFeedstocks: (newFeedstock: IFeedstock) => dispatch(modifyFeedstocks(newFeedstock)),
        modifyConvectionSection: (convectionsectiondata: IConvectionSection) => dispatch(modifyConvectionSection(convectionsectiondata)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

type ILoadProductPredictorReduxProps = ILoadProductPredictorProps & PropsFromRedux & OIDCAuth;

type ILoadProductPredictorState = {
    showDialog: boolean;
    selected: any[];
    allProductPredictorData: any[];
    isAllSelected: boolean;
    showLoading: boolean;
}

class LoadProductPredictor extends Component<ILoadProductPredictorReduxProps, ILoadProductPredictorState> {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            selected: [],
            allProductPredictorData: [],
            isAllSelected: false,
            showLoading: false
        }
    }

    componentDidMount = async () => {
        // await this.loadProductPredictorScenarioDataInit(); TO BE USED IN FUTURE
    }

    toggleShowDialog = async () => {
        await this.setState({
            showDialog: !this.state.showDialog,
        })

        if (this.state.showDialog) {
            await this.loadProductPredictorScenarioDataInit();
        }
    }

    loadProductPredictorScenarioDataInit = async () => {
        this.setState({
            showLoading: true
        })
        try {
            const accessToken = getToken(this.props.auth);
            let setAllProductPredictorData = await loadProductPredictorData(accessToken);

            if (!!setAllProductPredictorData && !!setAllProductPredictorData.data && setAllProductPredictorData.data.length > 0) {
                setAllProductPredictorData.data.forEach((productPredictorValue: IProductPredictorScenario) => {
                    productPredictorValue['is_saved'] = true;
                    productPredictorValue['is_display_leave_popup'] = false;
                })
            }
            if (setAllProductPredictorData.status === true) {
                // TO BE USED IN FUTURE
                // let setAllFuelGasDataNew = [...setAll, ...setAllFuelGasData.data]
                let setAllProductPredictoDataNew = [...setAllProductPredictorData.data]
                this.setState({
                    showLoading: false,
                    allProductPredictorData: setAllProductPredictoDataNew
                })
            } else {
                this.setState({
                    showLoading: false
                })
            }

        } catch (error) {
            this.setState({
                showLoading: false
            })
        }
    }

    loadAllProductPredictorConnectedScenarioData = async (selectedArray) => {
        const accessToken = getToken(this.props.auth);
        for (let selectedValue of selectedArray) {
            // get fuelgas
            if (selectedValue.fuelgas_id) {
                // not default
                if (!selectedValue.fuelgas_id.startsWith("_DEFAULT")) {
                    let checkIsfuelGasAvailable = this.props.fuelGasesData.some(fuelGas => fuelGas.id === selectedValue.fuelgas_id);
                    if (!checkIsfuelGasAvailable) {
                        let getFuelgasFromDb = await getFuelGasById(selectedValue.fuelgas_id, accessToken)
                        if (getFuelgasFromDb.status) {
                            for (let fuelGasValue of getFuelgasFromDb.data as IFuelGas[]) {
                                fuelGasValue['is_saved'] = true;
                                fuelGasValue['is_display_leave_popup'] = false;
                                if (fuelGasValue['is_delete'] === true) {
                                    // when fuelGas deleted and load scenario
                                    selectedValue = { ...selectedValue, status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG }
                                    fuelGasValue['is_delete'] = false;
                                }
                            }


                            let checkIsfuelGasAvailableAgain = this.props.fuelGasesData.some(fuelGas => fuelGas.id === selectedValue.fuelgas_id);
                            if (!checkIsfuelGasAvailableAgain) {
                                await this.props.loadAllFuelGasData(getFuelgasFromDb.data)
                            }
                        }
                    } else {
                        let new_fuelGas = this.props.fuelGasesData.find(fuelGas => fuelGas.id === selectedValue.fuelgas_id) as IFuelGas;
                        let getFuelgasFromDb = await getFuelGasById(selectedValue.fuelgas_id, accessToken)
                        if (getFuelgasFromDb.status) {
                            for (let fuelGasValue of getFuelgasFromDb.data as IFuelGas[]) {
                                fuelGasValue['is_saved'] = true;
                                fuelGasValue['is_display_leave_popup'] = false;
                                if (fuelGasValue['signature'] !== new_fuelGas.signature) {
                                    // load scenario and anything changed in fuelgas
                                    selectedValue = { ...selectedValue, status: SCENARIO_STATUS.IS_CHANGED_IN_FUELGAS }
                                }
                            }
                            let checkIsfuelGasAvailableAgain = this.props.fuelGasesData.some(fuelGas => fuelGas.id === selectedValue.fuelgas_id);
                            if (checkIsfuelGasAvailableAgain) {
                                await this.props.modifyFuelGas(getFuelgasFromDb.data[0])
                            }
                        }
                    }
                }

                // default
                if (selectedValue.fuelgas_id.startsWith("_DEFAULT")) {
                    let checkIsfuelGasAvailable = await this.props.fuelGasesData.some(fuelGas => fuelGas.id === selectedValue.fuelgas_id);
                    if (!checkIsfuelGasAvailable) {
                        const plants = getPlantReferences();
                        for (let plant of plants) {
                            const newFuelGas: IFuelGas = {
                                id: "_DEFAULT_" + plant.name,
                                name: "DEFAULT_" + plant.name,
                                unit: UNIT.MOL,
                                components: getDefaultFuelGasComposition(),
                                status: FUELGAS_STATUS.UNCOMPUTED,
                                error_message: "",
                                O2BridgeWall: -1,
                                Pressure: -1,
                                Temperature: -1,
                                signature: ""
                            };
                            for (let gas of this.props.fuelGasesData) {
                                if (gas.id !== newFuelGas.id) {
                                    await this.props.loadAllFuelGasData([newFuelGas])
                                }
                            }
                            if (this.props.fuelGasesData.length === 0) {
                                await this.props.loadAllFuelGasData([newFuelGas])
                            }
                        };
                    }
                }

            }

            // get groups data
            if (selectedValue.groups.length > 0) {
                for (let selectedValueFromGroups of selectedValue.groups) {
                    // get feedstock
                    if (selectedValueFromGroups.feedstock.length > 0) {

                        for (let selectedValueFromFeedstock of selectedValueFromGroups.feedstock) {
                            if (selectedValueFromFeedstock.feedstock_id) {
                                let checkIsFeedstockAvailable = await this.props.feedstocksData.some(feedstock => feedstock.id === selectedValueFromFeedstock.feedstock_id);
                                if (!checkIsFeedstockAvailable) {
                                    // feed stock not availabe in redux than get from DB
                                    let getFeedStockFromDb = await getFeedstockById(selectedValueFromFeedstock.feedstock_id, accessToken)
                                    if (getFeedStockFromDb.status) {
                                        for (let feedStockValue of getFeedStockFromDb.data as IFeedstock[]) {
                                            feedStockValue['is_saved'] = true;
                                            feedStockValue['is_display_leave_popup'] = false;
                                            if (feedStockValue['is_delete'] === true) {
                                                // when Feed stock deleted and load scenario
                                                selectedValue = { ...selectedValue, status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG }
                                                feedStockValue['is_delete'] = false;
                                            }
                                        }
                                        let checkIsFeedstockAvailableAgain = await this.props.feedstocksData.some(feedstock => feedstock.id === selectedValueFromFeedstock.feedstock_id);
                                        if (!checkIsFeedstockAvailableAgain) {
                                            await this.props.loadAllFeedStockData(getFeedStockFromDb.data)
                                        }
                                    }
                                } else {
                                    // feed stock availabe in redux 
                                    let new_feedstock = this.props.feedstocksData.find(feedstock => feedstock.id === selectedValueFromFeedstock.feedstock_id) as IFeedstock;
                                    let getFeedStockFromDb = await getFeedstockById(selectedValueFromFeedstock.feedstock_id, accessToken)
                                    if (getFeedStockFromDb.status) {
                                        for (let feedStockValue of getFeedStockFromDb.data as IFeedstock[]) {
                                            feedStockValue['is_saved'] = true;
                                            feedStockValue['is_display_leave_popup'] = false;
                                            if (feedStockValue['signature'] !== new_feedstock.signature) {
                                                // load scenario and anything changed in feedStock
                                                selectedValue = { ...selectedValue, status: SCENARIO_STATUS.IS_CHANGED_IN_FEEDSTOCK }
                                            }
                                        }
                                        let checkIsFeedstockAvailableAgain = await this.props.feedstocksData.some(feedstock => feedstock.id === selectedValueFromFeedstock.feedstock_id);
                                        if (checkIsFeedstockAvailableAgain) {
                                            await this.props.modifyFeedstocks(getFeedStockFromDb.data[0])
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // get convectionsection
                    if (selectedValueFromGroups.convectionsection_id) {
                        let checkIsConvectionSectionAvailable = await this.props.convectionSectionsData.some(convectionsections => convectionsections.id === selectedValueFromGroups.convectionsection_id);
                        if (!checkIsConvectionSectionAvailable) {
                            // Convection Section not availabe in redux than get from DB
                            let getConvectionSectionFromDb = await getConvectionSectionById(selectedValueFromGroups.convectionsection_id, accessToken)
                            if (getConvectionSectionFromDb.status) {
                                for (let convectionSectionValue of getConvectionSectionFromDb.data as IConvectionSection[]) {
                                    convectionSectionValue['is_saved'] = true;
                                    convectionSectionValue['is_display_leave_popup'] = false;
                                    if (convectionSectionValue['is_delete'] === true) {
                                        // when convectionSection deleted and load scenario
                                        selectedValue = { ...selectedValue, status: SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION }
                                        convectionSectionValue['is_delete'] = false;
                                    }
                                }
                                let checkIsConvectionSectionAvailableAgain = await this.props.convectionSectionsData.some(convectionsections => convectionsections.id === selectedValueFromGroups.convectionsection_id);
                                if (!checkIsConvectionSectionAvailableAgain) {
                                    await this.props.loadAllConvectionSectionData(getConvectionSectionFromDb.data)
                                }

                            }
                        } else {
                            // Convection Section availabe in redux 
                            let new_convectionSections = this.props.convectionSectionsData.find(convectionsections => convectionsections.id === selectedValueFromGroups.convectionsection_id) as IConvectionSection;
                            let getConvectionSectionFromDb = await getConvectionSectionById(selectedValueFromGroups.convectionsection_id, accessToken)
                            if (getConvectionSectionFromDb.status) {
                                for (let convectionSectionValue of getConvectionSectionFromDb.data as IConvectionSection[]) {
                                    convectionSectionValue['is_saved'] = true;
                                    convectionSectionValue['is_display_leave_popup'] = false;
                                    if (convectionSectionValue['signature'] !== new_convectionSections.signature) {
                                        // load scenario and anything changed in convectionSection
                                        selectedValue = { ...selectedValue, status: SCENARIO_STATUS.IS_CHANGED_IN_CONVECTION_SECTION }
                                    }
                                }
                                let checkIsConvectionSectionAvailableAgain = await this.props.convectionSectionsData.some(convectionsections => convectionsections.id === selectedValueFromGroups.convectionsection_id);
                                if (checkIsConvectionSectionAvailableAgain) {
                                    await this.props.modifyConvectionSection(getConvectionSectionFromDb.data[0])
                                }

                            }
                        }
                    }

                }
            }
            await this.props.loadAllProductPredictorData([selectedValue])

        }

    }


    loadProductPredictor = async () => {
        this.setState({
            showLoading: true
        })
        let isAllSelected = this.state.selected.some(el => el.name.toLowerCase() === "all");

        if (isAllSelected) {
            let removeAllFromArray = this.state.allProductPredictorData.filter(productPredictor => productPredictor.name.toLowerCase() !== "all");
            let filterData = removeAllFromArray.filter(productPredictor => {
                return this.props.productPredictorScenarios.filter(el => {
                    return el.id === productPredictor.id;
                }).length === 0
            });
            this.props.loadAllProductPredictorData(filterData)
            this.setState({
                selected: [],
                showLoading: false
            });
        } else {
            await this.loadAllProductPredictorConnectedScenarioData(this.state.selected)
            this.setState({
                selected: [],
                showLoading: false
            });
        }
        this.toggleShowDialog()
    }

    selectedDataSet = (valueData) => {
        this.setState({
            selected: valueData,
        })
    }

    handleSelection(event) {

    }
    render() {
        return (

            <>
                <LoadAutocomplete
                    showDialog={this.state.showDialog}
                    selected={this.state.selected}
                    allData={this.state.allProductPredictorData}
                    isAllSelected={this.state.isAllSelected}
                    showLoading={this.state.showLoading}
                    componentName={'Product Predictor'}
                    disableData={this.props.productPredictorScenarios}
                    toggleShowDialog={this.toggleShowDialog}
                    loadFeedstock={this.loadProductPredictor}
                    selectedDataSet={this.selectedDataSet}

                ></LoadAutocomplete>
            </>
        )
    }
}

export default withAuth(connector(LoadProductPredictor))