import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { REACT_APP_APIM_URL } from "../../utilities/GlobalConstants";
import { Link } from "react-router-dom";
import { Box, Paper, Grid } from "@material-ui/core";
import { setFurnaceNameForCameraStatus, setCameraNameForCameraStatus, setAllCameraStatus } from '../../slices/cameraStatusSlice'
import { getFurnaceByFurnaceId, getCameraByCameraId, getTemperatureUnit } from "../../configuration";
import Loader from "react-spinners/ClipLoader";
import Timechart from "../timechart";
import { getFormattedDateTime, getToken } from "../../utilities/helperFunctions";
import { AuthContextProps, withAuth } from "react-oidc-context";

export const CAMERA_STATUS_DASHBOARD = "_CAMERA_STATUS";

interface OIDCAuth {
    auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
    return {
        token: state.authState.token,
        cameraStatus: state.cameraStatus.cameraAllStatus,
        furnaceName: state.cameraStatus.furnaceName,
        cameraName: state.cameraStatus.cameraName,
        furnaces: state.furnace,
        cameras:state.camera
    };
};

// write to redux
const mapDispatchToProps = (dispatch) => {
    return {
        setFurnaceNameForCameraStatus: (furnace_name: string) => dispatch(setFurnaceNameForCameraStatus(furnace_name)),
        setCameraNameForCameraStatus: (camera_name: string) => dispatch(setCameraNameForCameraStatus(camera_name)),
        setAllCameraStatus: (camera_status: {}) => dispatch(setAllCameraStatus(camera_status)),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ICameraStatusReduxProps = PropsFromRedux & OIDCAuth;

class CameraStatus extends Component<ICameraStatusReduxProps, any> {


    constructor(props) {
        super(props);
        this.state = { loading:false }
        this.state = { graphLoader:false }
        this.state = { GraphData:([]) }
        this.state = { GraphYMax:0 }
        this.state = { GraphYMin:0 }
    }

    async componentDidMount() {
        const furnaceId = this.props.furnaces.current_furnace_id
        const cameraId = this.props.cameras.name
        let furnaceName = getFurnaceByFurnaceId(parseInt(furnaceId));
        this.props.setFurnaceNameForCameraStatus(furnaceName);
        let cameraName = getCameraByCameraId((cameraId));
        this.props.setCameraNameForCameraStatus(cameraName);
        this.fetchCameraStatus(cameraId)
        this.getGraphData(cameraId);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.cameraName !== this.props.cameraName){
            this.fetchCameraStatus(this.props.cameraName)
            this.getGraphData(this.props.cameraName);
        }
    }

    getMsgWithLocalTime = (msg) => { 
        if(msg) {
            try{
                let msgParts =msg.split(/\[|\]/);
                let getDateTime = new Date(msgParts[1]);
                getDateTime = new Date(getDateTime.getTime() - getDateTime.getTimezoneOffset()*60*1000);    // UTC to Local Time
                let localDateTime = getFormattedDateTime(getDateTime); 
                msgParts[1] = "["+localDateTime+"]";
                msgParts[3] = "["+localDateTime+"]";
                return msgParts.join("");
            }catch(e){
                console.log("Error in getMsgWithLocalTime", e);
                return "";
            }         
        }
        else
            return msg;
    }

    getGraphData = (cameraParam) => {
        this.setState({graphLoader:true})
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL}/thermalimage/getCameraStatusGraph/${cameraParam}`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((res) => res.json())
            .then(
                (resp) => {
                    let maxTMT = Math.max.apply(null,
                    resp.map(function (o) { return o.camera_temperature; }));
                    this.setState({GraphYMax:maxTMT+10})
                    let minTMT = Math.min.apply(null,
                    resp.map(function (o) { return o.camera_temperature; }));
                    this.setState({GraphYMin:minTMT-10})
                    if (resp.length > 0) {
                        let res: { 'runtime': number, 'CUSTOM_OULET': number, 'CAMERA_TEMPERATURE'?: number, }[] = [];
                        for(var u = 0; u < resp.length;u++)
                        {
                          let obj = { 'runtime': resp[u]["runLength"], 'CUSTOM_OULET' : Number(maxTMT), ["CAMERA_TEMPERATURE"]: resp[u]["camera_temperature"]}
                          res.push(obj)
                        }
                        this.setState({GraphData:res});
                        this.setState({graphLoader:false})
                    }
                },
                (error) => 
                {
                    this.setState({graphLoader:false})
                    console.log("error", error)
                }
            )
    }

    fetchCameraStatus = (cameraId) => {
        this.setState({loading:true})
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL}/thermalimage/getCameraStatus`, {
            method: "POST",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: JSON.stringify(cameraId),
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    this.props.setAllCameraStatus(data);
                    this.setState({loading:false})
                },
                (error) => {
                    console.log("error", error)
                    this.setState({loading:false})
                }
            )
    }

    render() {
        return (
            <>
                <Paper elevation={3} className='camera-status-dashboard-header'>
                    <Box ml={4}>
                        <span><b>Furnace :</b> {this.props.furnaceName}</span>
                        <span className='camera-status-dashboard-header-selected-camera'><b>Camera :</b> {this.props.cameraName}</span>
                        <Link className="camera-health-back-button" to={'/camera-health'}>Back To Camera Health</Link>
                    </Box>
                </Paper>
                {this.state.loading ?
                    <div className="camera-status-grid-parent">
                    <div className="loader-grid">
                      <Loader />
                      <p> Loading...</p>
                    </div>
                    </div> : 
                    <div>
                        <div className="camera-status-dashboard">
                            <Paper className="camera-health-box">
                                <p><span>Status of Connection</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].ConnectionStatus}</p>
                                <p><span>Camera Temperature</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].CameraTemperature}</p>
                                {/* <p><span>Alarmstatus Thermocouple</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].ThermocoupleAlarmStatus}</p> */}
                                <p><span>Last error messages</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.getMsgWithLocalTime(this.props.cameraStatus[0].LastErrorMessages)}</p>
                            </Paper>
                            <Paper className="camera-health-box">
                                    <p><span>Device</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].DeviceType}</p>
                                    <p><span>IP Address</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].IpAddress.length > 0 && "**.**.**"+this.props.cameraStatus[0].IpAddress.substring(9, 13)}</p>
                                    <p><span>Serial Number</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].SerialNumber}</p>
                                    <p><span>Firmware version</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].FirmwareVersion}</p>
                                    <p><span>Last calibration</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].LastCalibration}</p>
                                </Paper>
                                <Paper className="camera-health-box">
                                    <p><span>Frame Width</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].FrameWidth}</p>
                                    <p><span>Frame Height</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].FrameHeight}</p>
                                    <p><span>Minimum Target Temperature</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].MinimumTargetTemperature}</p>
                                    <p><span>Maximum Target Temperature</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].MaximumTargetTemperature}</p>
                                    <p><span>Emissivity</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].Emissivity}</p>
                                </Paper>
                                <Paper className="camera-health-box">
                                    <p><span>Frame Rate</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].FrameRate}</p>
                                    <p><span>Frame Counter</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].FrameCounter}</p>
                                    {/* <p><span>Bandwidth used for frames</span> : {this.props.cameraStatus && this.props.cameraStatus[0] && this.props.cameraStatus[0].FrameBandwidth}</p> */}
                                </Paper>
                            
                        </div>
                        <div className="camera-status-dashboard">                                                       
                                <Paper className="camera-health-box box-2">
                                    <Grid>
                                    <Timechart
                                        data={this.state.GraphData}
                                        keys={["CUSTOM_OULET", "CAMERA_TEMPERATURE"]}
                                        labels={["Time (Hours)", "CAMERA TEMPERATURE (" +getTemperatureUnit() + ")"]}
                                        scenarioId={0}
                                        yMax={this.state.GraphYMax}
                                        yMin={this.state.GraphYMin}
                                        height={375}
                                        limitRange={true}
                                        isCameraStatus = {true}
                                        />
                                    </Grid>
                                </Paper>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default withAuth(connector(CameraStatus));