import React, { Component } from 'react'
import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControlLabel, MenuItem, TextField } from '@material-ui/core'
import { setCurrentProductPredictorScenarioId, updateSignature, AddProductPredictorScenario } from '../../slices/productPredictorSlice';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { getDefaultProductPredictorGroups, IProductPredictorOutput, IProductPredictorScenario } from '../../interfaces/IProductPredictor';
import { v4 as uuidv4 } from 'uuid';
import { CALCULATION_STATUS, SCENARIO_PROGRESS, SCENARIO_STATUS, TERMINATION_REASON } from '../../interfaces/IScenario';
import { checkProductPredictorName } from '../../services/productPredictorServices';
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../utilities/helperFunctions";
interface ICloneProductPredictorProps {
    currentProductPredictor?: IProductPredictorScenario
}

interface OIDCAuth {
    auth?: AuthContextProps
 }

const mapStateToProps = (state: RootState) => {
    return {
        fuelGases: state.fuelGas.fuelGases,
        ProductPredictorScenario: state.productPredictor.productPredictorScenarios,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        add: (obj: IProductPredictorScenario) => dispatch(AddProductPredictorScenario(obj)),
        setCurrentProductPredictorScenarioId: (id: string) => dispatch(setCurrentProductPredictorScenarioId(id)),
        updateSignature: (obj: IProductPredictorScenario) => dispatch(updateSignature(obj)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICloneProductPredictorReduxProps = PropsFromRedux & ICloneProductPredictorProps & OIDCAuth;

type ICloneProductPredictorState = {
    name: string,
    fuelgas_id: string,
    pressure: number,
    prediction_time: number,
    showDialog: boolean;
    disabledAddBtn: boolean;
    buttonText: string;
    is_public: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
    isFuelGasAvailable: boolean;
    fuelGasErrorMessage: string;
    isPressureAvailable: boolean;
    pressureErrorMessage: string;
    isPrediction_timeAvailable: boolean;
    prediction_timeErrorMessage: string;
}
const initState = {
    name: "",
    fuelgas_id: "",
    pressure: -1,
    prediction_time: -1,
    showDialog: false,
    disabledAddBtn: true,
    buttonText: "Add",
    is_public: true,
    isNameAvailable: false,
    nameErrorMessage: "",
    showLoading: false,
    isFuelGasAvailable: false,
    fuelGasErrorMessage: "",
    isPressureAvailable: false,
    pressureErrorMessage: "",
    isPrediction_timeAvailable: false,
    prediction_timeErrorMessage: "",
}

class cloneProductPredictor extends Component<ICloneProductPredictorReduxProps, ICloneProductPredictorState> {

    constructor(props) {
        super(props);
        if (this.props.currentProductPredictor) {
            this.state = {
                name: this.props.currentProductPredictor.name,
                fuelgas_id: this.props.currentProductPredictor.fuelgas_id,
                pressure: this.props.currentProductPredictor.pressure,
                prediction_time: this.props.currentProductPredictor.prediction_time,
                showDialog: false,
                disabledAddBtn: false,
                buttonText: "Clone",
                is_public: this.props.currentProductPredictor.is_public ? this.props.currentProductPredictor.is_public : false,
                isNameAvailable: false,
                nameErrorMessage: "",
                showLoading: false,
                isFuelGasAvailable: false,
                fuelGasErrorMessage: "",
                isPressureAvailable: false,
                pressureErrorMessage: "",
                isPrediction_timeAvailable: false,
                prediction_timeErrorMessage: "",
            }
        }
        else {
            this.state = initState;
        }
    }
    addProductPredictorScenarioPopup() {
        if (!this.props.currentProductPredictor)
            this.setState(initState);
        else {
            this.setState({
                name: this.props.currentProductPredictor.name,
                fuelgas_id: this.props.currentProductPredictor.fuelgas_id,
                pressure: this.props.currentProductPredictor.pressure,
                showDialog: false,
                disabledAddBtn: false,
                buttonText: "Clone"
            })
        }
        this.toggleShowDialog();
    }
    addProductPredictorScenario = async () => {
        this.setState({
            showLoading: true,
        })

        if (this.state.fuelgas_id === "") {
            this.setState({
                isFuelGasAvailable: true,
                showLoading: false,
                fuelGasErrorMessage: "Please select fuelgas"
            })
            return false
        }

        if (this.state.pressure === -1) {
            this.setState({
                isPressureAvailable: true,
                showLoading: false,
                pressureErrorMessage: "Please add pressure value"
            })
            return false
        }

        if (this.state.prediction_time === -1) {
            this.setState({
                isPrediction_timeAvailable: true,
                showLoading: false,
                prediction_timeErrorMessage: "Please add prediction time"
            })
            return false
        }

        if (!!this.state.name?.trim()) {
            let setName = this.state.is_public === true ? this.state.name.trim() + "(PUBLIC)" : this.state.name.trim();
            const productoutput: IProductPredictorOutput = { products: [] }
            let newObj: IProductPredictorScenario;

            if (this.props.currentProductPredictor) {
                newObj = {
                    id: uuidv4(),
                    name: setName,
                    fuelgas_id: this.state.fuelgas_id,
                    pressure: this.state.pressure,
                    prediction_time: this.state.prediction_time,
                    case_id: this.props.currentProductPredictor.case_id,
                    calculation_status: this.props.currentProductPredictor.calculation_status,
                    status: SCENARIO_STATUS.UNCOMPUTED,
                    groups: this.props.currentProductPredictor.groups,
                    output: productoutput,
                    scenario_progress: this.props.currentProductPredictor.scenario_progress,
                    termination_reason: this.props.currentProductPredictor.termination_reason,
                    progress: 0,
                    signature: this.props.currentProductPredictor.signature,
                    is_public: this.state.is_public,
                    is_display_leave_popup: this.props.currentProductPredictor.is_display_leave_popup,
                    is_saved: false,
                }
            } else {
                newObj = {
                    id: uuidv4(),
                    name: setName,
                    fuelgas_id: this.state.fuelgas_id,
                    pressure: this.state.pressure,
                    prediction_time: this.state.prediction_time,
                    case_id: '',
                    calculation_status: CALCULATION_STATUS.IDLE,
                    status: SCENARIO_STATUS.UNCOMPUTED,
                    groups: getDefaultProductPredictorGroups(),
                    output: productoutput,
                    scenario_progress: SCENARIO_PROGRESS.VALIDATION,
                    termination_reason: TERMINATION_REASON.IDLE,
                    progress: 0,
                    signature: "",
                    is_public: this.state.is_public,
                    is_display_leave_popup: false,
                    is_saved: false,
                }
            }

            try {
                let isNameInRedux = this.props.ProductPredictorScenario.some(productPredictor => productPredictor.name === newObj.name);
                if (isNameInRedux) {
                    this.setState({
                        isNameAvailable: true,
                        nameErrorMessage: "Name already exist!",
                        showLoading: false,
                    })
                } else {
                    const accessToken = getToken(this.props.auth);
                    let isNameAvailable = await checkProductPredictorName(newObj, accessToken);
                    if (isNameAvailable.status) {
                        this.setState({
                            isNameAvailable: true,
                            nameErrorMessage: !!isNameAvailable.message ? isNameAvailable.message : "Name already exist!",
                            showLoading: false,
                        })
                    } else {
                        this.props.add(newObj);
                        this.props.setCurrentProductPredictorScenarioId(newObj.id);
                        this.props.updateSignature(newObj)
                        this.toggleShowDialog();
                        this.setState({
                            showLoading: false,
                        })
                    }
                }

            } catch (error) {
                // Api error hendaling 
                this.setState({
                    isNameAvailable: true,
                    showLoading: false,
                    nameErrorMessage: "Oops somthing went to wrong!"
                })
            }


        } else {
            this.setState({
                isNameAvailable: true,
                nameErrorMessage: "Please enter a name!",
                showLoading: false,
            })
        }
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            isNameAvailable: false,
            nameErrorMessage: "",
            isFuelGasAvailable: false,
            fuelGasErrorMessage: "",
            isPressureAvailable: false,
            pressureErrorMessage: "",
            isPrediction_timeAvailable: false,
            prediction_timeErrorMessage: "",
        })

        if (!!this.props.currentProductPredictor && this.state.buttonText === "Clone") {
            let getName = this.props.currentProductPredictor.is_public === true ? this.props.currentProductPredictor.name.replace('(PUBLIC)', '') : this.props.currentProductPredictor.name;
            this.setState({
                name: getName + "-COPY",
                is_public: this.props.currentProductPredictor.is_public ? this.props.currentProductPredictor.is_public : false,
            })
        } else {
            this.setState({
                name: ""
            })
        }
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value.toUpperCase(),
            disabledAddBtn: event.target.value.length > 0 ? false : true
        })
    }
    handlePressureInput(event) {
        this.setState({
            pressure: event.target.value,
        })
    }
    handlePredictionTimeInput(event) {
        this.setState({
            prediction_time: event.target.value,
        })
    }
    handleFuelGasChange(event) {
        this.setState({
            fuelgas_id: event.target.value,
        })
    }
    handleChangeIsPublic(event) {
        this.setState({
            is_public: !this.state.is_public
        })
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    disabled={this.props.currentProductPredictor?.status === SCENARIO_STATUS.RUNNING ? true : false}
                    onClick={() => this.addProductPredictorScenarioPopup()}
                >
                    {this.state.buttonText}
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title" >
                    <DialogTitle id="form-dialog-title">Product Predictor Configurator</DialogTitle>
                    <DialogContent>
                        {this.props.fuelGases.length > 0 && (
                            <>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    value={this.state.name}
                                    // disabled={this.state.buttonText === "Clone" ? true : false}
                                    onChange={(event) => this.handleNameInput(event)}
                                    helperText={this.state.nameErrorMessage}
                                    error={this.state.isNameAvailable}
                                />
                                <Box display="flex" justifyContent="flex-start" >

                                    <TextField
                                        id={"fuelGas"}
                                        select
                                        margin="dense"
                                        style={{ 'width': '100%' }}
                                        SelectProps={{ autoWidth: true }}
                                        label={"FuelGas"}
                                        defaultValue={this.state.fuelgas_id}
                                        onChange={(event) => this.handleFuelGasChange(event)}
                                        helperText={this.state.fuelGasErrorMessage}
                                        error={this.state.isFuelGasAvailable}
                                    >
                                        {this.props.fuelGases.map((fuelGas) => (
                                            <MenuItem key={fuelGas.id} value={fuelGas.id}>
                                                {fuelGas.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                </Box>
                                <TextField
                                    margin="dense"
                                    id="pressure"
                                    label="CGC Suction Pressure (bar g)"
                                    type="text"
                                    fullWidth
                                    value={this.state.pressure >= 0 ? this.state.pressure : ''}
                                    onChange={(event) => this.handlePressureInput(event)}
                                    helperText={this.state.pressureErrorMessage}
                                    error={this.state.isPressureAvailable}
                                />
                                <TextField
                                    margin="dense"
                                    id="_NAME"
                                    label="Prediction Time (Days)"
                                    type="text"
                                    fullWidth
                                    value={this.state.prediction_time >= 0 ? this.state.prediction_time : ''}
                                    onChange={(event) => this.handlePredictionTimeInput(event)}
                                    helperText={this.state.prediction_timeErrorMessage}
                                    error={this.state.isPrediction_timeAvailable}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.is_public}
                                            onChange={this.handleChangeIsPublic.bind(this)}
                                            name="isPublic"
                                            value={this.state.is_public}
                                            color="primary"
                                        />
                                    }
                                    label="Is Public?"
                                />
                            </>
                        )}
                        {this.props.fuelGases.length === 0 && (
                            <>
                                <DialogContentText style={{ color: "red" }}>
                                    Please add / load atleast one fuelgas for {this.state.buttonText.toLowerCase()} product predictor
                                </DialogContentText>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {this.props.fuelGases.length > 0 && (
                            <>
                                <button className="button" onClick={() => this.toggleShowDialog()} >
                                    Cancel
                                </button>
                                <button className="button" disabled={this.state.disabledAddBtn || this.state.showLoading} onClick={() => this.addProductPredictorScenario()}>
                                    {this.state.buttonText} {this.state.showLoading === true && (
                                        <Fade
                                            in={this.state.showLoading}
                                            style={{
                                                transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress size={11} />
                                        </Fade>
                                    )}
                                </button>
                            </>
                        )}
                        {this.props.fuelGases.length === 0 && (
                            <>
                                <button className="button" onClick={() => this.toggleShowDialog()} >
                                    Ok
                                </button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withAuth(connector(cloneProductPredictor))



